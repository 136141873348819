/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import PrimaryButton from "../common/PrimaryButton";
import Copyright from "../common/Copyright";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";
import { useHistory } from "react-router-dom";
import CircularProgressBar from "../common/CircularProgressBar";
import questorTitle from "../../assets/images/questor-title.png";
import { useSelector } from "react-redux";
import homeBanner from "./../../assets/images/home-banner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  titleImage: {
    marginBottom: "2rem",
  },
  caption: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 500,
    letterSpacing: "0px",
    color: "#FFFFFF",
    margin: "0.5rem 0 2rem 0",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    // padding: "1rem 3rem 1rem",
    width: "100%",
    ["@media (max-width:650px)"]: {
      padding: "3rem 2rem 3rem",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
}));

export default function Welcome(props) {
  const classes = useStyles();
  const [loader, showLoader] = useState(false);
  const AuthReducer = useSelector((state) => state.authReducer);
  const history = useHistory();
  const userInfo = AuthReducer.userInfo.data;

  const onClickAssesment = () => {
    history.push("/test");
  };

  const onClickViewReport = () => {
    history.push("/report");
  };

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null || AuthReducer.isLoggedIn) {
      history.push("/welcome");
    } else {
      history.push("/login");
    }
    return () => {
      console.log("clean up");
    };
  }, [AuthReducer.isLoggedIn]);

  return (
    <div>
      <Grid
        container
        component="main"
        className={`${classes.root} iphoneView `}
      >
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <div className="custom-infoGraphic-image">
              <img src={homeBanner} alt="" className="home-banner" />
            </div>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.caption}
                >
                  Welcome to
                </Typography>
                <img
                  src={questorTitle}
                  alt="questorTitle"
                  className={classes.titleImage}
                />
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    {userInfo ? (
                      <React.Fragment>
                        {userInfo.showReport ? (
                          <React.Fragment>
                            <PrimaryButton
                              buttonText="View Report"
                              handleClick={onClickViewReport}
                            ></PrimaryButton>
                          </React.Fragment>
                        ) : (
                          <PrimaryButton
                            buttonText="Begin Learning"
                            handleClick={onClickAssesment}
                          ></PrimaryButton>
                        )}
                      </React.Fragment>
                    ) : null}

                    <span style={{ marginLeft: "0.6rem" }}>
                      {loader ? <CircularProgressBar /> : null}
                    </span>
                  </Grid>
                </Grid>
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <Copyright></Copyright>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
