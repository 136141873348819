import React, { Component } from "react";

export class PageNotFound extends Component {
  render() {
    return (
      <div className="pnf-container">
        <h1>404</h1>
        <h3>We are sorry but the page you are looking for does not exist.</h3>
      </div>
    );
  }
}

export default PageNotFound;
