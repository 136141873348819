import { makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles({
    copyRight: {
        fontSize: "12px",
        lineHeight: "24px",
        fontFamily: "PoppinsMedium",
        fontWeight: 600,
        color: '#626262',
        letterSpacing: '0px'
    }
});
export default function CopyRight() {

    const classes = useStyles();
    return (
        <React.Fragment >
            <p className={classes.copyRight} >
                © ETQ Global. All Rights Reserved.
                {/* Copyrights @ETQ<sup>&#174; </sup> {new Date().getFullYear()}.  */}
            </p>
        </React.Fragment>
    )
}