/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import riskAversion from "./../../assets/images/report/pp-b-9.svg";
import comfortDiscomfort from "./../../assets/images/report/pp-b-10.svg";
import riskMitigation from "./../../assets/images/report/pp-b-11.svg";
import riskTaking from "./../../assets/images/report/pp-b-12.svg";
import thumbsUp from "./../../assets/images/report/thumbsup.svg";
import improvement from "./../../assets/images/report/improvement.svg";
import notApplicable from "./../../assets/images/report/not-applicable.svg";
import AnimatedDrawer from "../common/AnimationDrawer";
import tipArrowIcon from "./../../assets/images/report/tip-arrow-icon.svg";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    textAlign: "center",
  },
  rightSideContainer: {
    marginTop: "100px",
    "@media (max-width:1050px)": {
      marginTop: "0",
    },
    "@media (min-width:1052px) and (max-width:1282px)": {
      marginTop: "16px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      marginTop: "24px",
    },
    "@media (min-width:1443px) and (max-width:1512px)": {},
    ["@media (min-width:1443px)"]: {
      margin: "0",
    },
  },
  sectionTitle: {
    color: "#e8b100",
    font: "normal normal 500 36px/42px PoppinsSemiBold",
    margin: "36px 0 18px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 300 30px/36px PoppinsSemiBold",
      margin: "24px 0 12px",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 26px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1582px)"]: {
      font: "normal normal 300 36px/42px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 36px/42px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 600 14px/20px PoppinsSemiBold",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 21px/30px PoppinsSemiBold",
    },
  },
  sectionSubTitleBorder: {
    borderTop: "1px solid #BE2431",
    padding: "10px 0",
    marginBottom: 0,
  },
  sectionBottomBorder: {
    borderBottom: "1px solid #BE2431",
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    ["@media (max-width:1025px)"]: {
      font: "normal normal 400 12px/18px PoppinsMedium",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 400 14px/22px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/22px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
      padding: "0 14px 0 0px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  alignFlexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  tableCaption: {
    color: "#e8b100",
    marginBottom: 0,
    font: "normal normal 500 12px/20px PoppinsSemiBold",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 14px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
  },
  thumbnailImg: {
    width: "26px",
    height: "26px",
  },
  thumbnailText: {
    font: "normal normal 500 10px/14px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    marginLeft: "10px",
    textAlign: "left",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1366px) and (max-width:1442px)"]: {
      font: "normal normal 500 10px/14px PoppinsMedium",
      padding: "0 14px 0 0px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 14px/20px PoppinsMedium",
    },
  },

  tipContainer: {
    position: "absolute",
    top: "0",
    right: "25px",
    color: "#6FBC44",
    width: "100px",
    height: "auto",
    font: "normal normal 500 12px/16px PoppinsSemiBold",
    zIndex: 999,
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "100px",
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "10px",
      width: "125px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "30px",
      width: "125px",
      right: "50px",
    },
  },
  tipArrowIcon: {
    ["@media (min-width:1443px)"]: {
      width: "50px",
    },
  },
  keyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default function Screen8(props) {
  const classes = useStyles();

  const [ppNumber, setPpNumber] = useState(0);
  const [ppType, setPpType] = useState(0);

  const openSelectedOutcomeDescription = (type, ppNumber) => (event) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "block";
    document.getElementById("header").style.position = "static";
    document.getElementById("tipContainer").style.display = "none";
    document.getElementById("progressBar").style.display = "none";
    setPpNumber(ppNumber);
    setPpType(type);
  };

  const closeSelectedOutcomeDescription = (type) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
    document.getElementById("header").style.position = "relative";
    document.getElementById("tipContainer").style.display = "block";
  };

  const getIconTitle = (data) => {
    if (data === "improvement") {
      return "Scope for Improvement";
    } else if (data === "thumbsup") {
      return "Well Done";
    } else {
      return "Biases & Motivational factors were not tested here";
    }
  };

  return (
    <div className="pos-rel">
      <div id="tipContainer" className={classes.tipContainer}>
        <p className="greenColor">Tip: click on each icon to learn more</p>
        <img
          className={classes.tipArrowIcon}
          src={tipArrowIcon}
          alt="tipArrowIcon"
        />
      </div>
      <Grid
        container
        component="main"
        className={`${classes.reportContentContainer} ${classes.verticalHorizontalCenter}`}
      >
        <Grid item xs={12} sm={3} md={3}>
          <h3 className={classes.sectionTitle}>Risk Thinking</h3>

          <p className={classes.paragraph}>
            {props.data.category_id === 1 ? (
              <React.Fragment>
                {" "}
                Risk Thinking is one’s mindset about risk taking and decision
                making relevant in a start-up and self- employment scenario.
              </React.Fragment>
            ) : props.data.category_id === 4 ? (
              <React.Fragment>
                Risk Thinking is one’s mindset about risk taking and decision
                making relevant for an employability in a commercial career.
              </React.Fragment>
            ) : (
              <React.Fragment>
                Risk Thinking is one’s mindset about risk taking and decision
                making relevant in a commercial role.
              </React.Fragment>
            )}
          </p>
          <p className={classes.paragraph}>
            {props.data.category_id === 1 ? (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for risk taking and decision making.
              </React.Fragment>
            ) : props.data.category_id === 4 ? (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for risk taking and decision making.
              </React.Fragment>
            ) : (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for risk taking and decision making.
              </React.Fragment>
            )}
          </p>

          {/* {props.data.category_id === 1 ? (
            <React.Fragment>
              <p className={classes.paragraph}>
                Risk Thinking is one’s mindset about risk taking and decision
                making relevant in a start-up and self- employment scenario.
              </p>
              <p className={classes.paragraph}>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for risk taking and decision making.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className={classes.paragraph}>
                Risk Thinking is one’s mindset about risk taking and decision
                making relevant in a commercial role.
              </p>
              <p className={classes.paragraph}>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for risk taking and decision making.
              </p>
            </React.Fragment>
          )} */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          component={Paper}
          elevation={9}
          className={classes.rightSideContentContainer}
        >
          <div>
            <Grid container className={classes.rightSideContainer}>
              <Grid item xs={1}></Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={riskAversion}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("rt", 9)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Risk <br /> Aversion
                    </span>
                  ) : (
                    <span>
                      Risk <br /> Aversion
                    </span>
                  )}{" "}
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={comfortDiscomfort}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("rt", 10)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Handling <br /> Uncertainty
                    </span>
                  ) : (
                    <span>
                      Handling <br /> Uncertainty
                    </span>
                  )}{" "}
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={riskMitigation}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("rt", 11)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Risk <br /> Mitigation
                    </span>
                  ) : (
                    <span>
                      Risk <br /> Mitigation
                    </span>
                  )}{" "}
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={riskTaking}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("rt", 12)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Decision <br /> Making
                    </span>
                  ) : (
                    <span>
                      Decision <br /> Making
                    </span>
                  )}{" "}
                </h4>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #FFCD05" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MINDSET</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMindsetRiskAversion}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingMindsetRiskAversion
                  }
                  title={getIconTitle(
                    props.data.riskThinkingMindsetRiskAversion
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMindsetComfortDiscomfort}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingMindsetComfortDiscomfort
                  }
                  title={getIconTitle(
                    props.data.riskThinkingMindsetComfortDiscomfort
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMindsetRiskMitigation}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingMindsetRiskMitigation
                  }
                  title={getIconTitle(
                    props.data.riskThinkingMindsetRiskMitigation
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMindsetRiskTaking}.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-" + props.data.riskThinkingMindsetRiskTaking}
                  title={getIconTitle(props.data.riskThinkingMindsetRiskTaking)}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #FFCD05" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>BIASES</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingBiasesRiskAversion}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingBiasesRiskAversion
                  }
                  title={getIconTitle(
                    props.data.riskThinkingBiasesRiskAversion
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingBiasesRiskMitigation}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingBiasesRiskMitigation
                  }
                  title={getIconTitle(
                    props.data.riskThinkingBiasesRiskMitigation
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingBiasesRiskTaking}.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-" + props.data.riskThinkingBiasesRiskTaking}
                  title={getIconTitle(props.data.riskThinkingBiasesRiskTaking)}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #FFCD05" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MOTIVATION</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMotivationRiskAversion}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.riskThinkingMotivationRiskAversion
                  }
                  title={getIconTitle(
                    props.data.riskThinkingMotivationRiskAversion
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.riskThinkingMotivationComfort}.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-" + props.data.riskThinkingMotivationComfort}
                  title={getIconTitle(props.data.riskThinkingMotivationComfort)}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container>
              <Grid item xs={1}>
                <p style={{ color: "rgb(190, 36, 49)" }}></p>
              </Grid>
              <Grid item xs className={classes.keyContainer}>
                <p className={classes.thumbnailText} style={{ float: "right" }}>
                  KEY:
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={notApplicable}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Biases & Motivational <br /> factors were not tested here
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={improvement}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Scope <br /> for Improvement
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img className={classes.thumbnailImg} src={thumbsUp} alt="" />
                <p className={classes.thumbnailText}>
                  Well <br /> Done
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <div class="animated-drawer-container">
          {props.data.pointsToPonder &&
            props.data.pointsToPonder.map((score) => {
              return ppNumber === score.id ? (
                <AnimatedDrawer
                  animatedDrawerType={closeSelectedOutcomeDescription}
                  ppType={ppNumber}
                  data={score}
                  type={ppType}
                ></AnimatedDrawer>
              ) : null;
            })}
        </div>
      </Grid>
    </div>
  );
}
