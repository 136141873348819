import React from "react";
import PrimaryButton from "./PrimaryButton";

const OTPINPUTS = [
  {
    id: 1,
    name: "otp1",
  },
  {
    id: 2,
    name: "otp2",
  },
  {
    id: 3,
    name: "otp3",
  },
  {
    id: 4,
    name: "otp4",
  },
  {
    id: 5,
    name: "otp5",
  },
  {
    id: 6,
    name: "otp6",
  }
];

class Otpinput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp1: props.otp1,
      otp2: props.otp2,
      otp3: props.otp3,
      otp4: props.otp4,
      otp5: props.otp5,
      otp6: props.otp6,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value });
    setTimeout(() => {
      this.props.verifyCode(this.state);
    }, 2000);
  }

  handleSubmit() {
    this.props.verifyCode(this.state);
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  render() {
    return (
      <form>
        <div className="otpContainer">
          {OTPINPUTS.map((input) => (
            <input
              name={input.name}
              type="text"
              autoComplete="off"
              className="otpInput"
              // value={this.props[input.name]}
              value={this.state[input.name]}
              onKeyPress={this.keyPressed}
              onChange={(e) => this.handleChange(input.name, e)}
              tabIndex={input.id}
              maxLength="1"
              onKeyUp={(e) => this.inputfocus(e)}
            />
          ))}
        </div>
        {/* <PrimaryButton
          buttonText={"Verify"}
          handleClick={this.handleSubmit}
        ></PrimaryButton> */}
      </form>
    );
  }
}

export default Otpinput;
