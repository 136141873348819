import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { Types } from "./../Types";
import { getAllQuestions, postSelectedAnswer, getReportDetails, postFeedback } from '../../../utils/services/test-service';

const response = {
    data: {
        message: "Network error occured, please try again later."
    }
};

function* getAllQuestionsAsync(action) {
    try {
        yield put({ type: Types.GET_ALL_QUESTIONS_ASYNC });
        const response = yield call(getAllQuestions);
        if (response && response.status === 200) {
            yield put({ type: Types.GET_ALL_QUESTIONS_RESPONSE, response })
        } else {
            yield put({ type: Types.GET_ALL_QUESTIONS_ERROR, response })
            yield delay(2000);
            yield put({ type: 'REMOVE_ERROR_MESSAGE' });
        }
    } catch (error) {
        console.log("error", error);
        yield put({ type: Types.GET_ALL_QUESTIONS_ERROR, response });
    }
}

function* postAnswerAsync(action) {
    try {
        yield put({ type: Types.POST_ANSWER_ASYNC });
        const response = yield call(postSelectedAnswer, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.POST_ANSWER_RESPONSE, response })
        } else {
            yield put({ type: Types.POST_ANSWER_ERROR, response })
            yield delay(2000);
            yield put({ type: 'REMOVE_ERROR_MESSAGE' });
        }
    } catch (error) {
        yield put({ type: Types.POST_ANSWER_ERROR, response });
        console.log("error", error);
    }
}

function* OpenDrawerAsync(action) {
    try {
        yield put({ type: Types.OPEN_DRAWER_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* closePopupAsync(action) {
    try {
        yield put({ type: Types.CLOSE_MODAL_POPUP_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* testProceedAsync(action) {
    try {
        yield put({ type: Types.SET_TEST_PROCEED_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* closeTestProceedAsync(action) {
    try {
        yield put({ type: Types.CLOSE_SET_TEST_PROCEED_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* CloseQuestionPageAsync(action) {
    try {
        yield put({ type: Types.RESET_QUESTION_PAGE_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* testStartedAsync(action) {
    try {
        yield put({ type: Types.IS_TEST_STARTED_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}
function* setBgImageAsync(action) {
    let selectedTheme = action.payload.selectedTheme;
    try {
        yield put({ type: Types.SET_BG_IMAGE_ASYNC, selectedTheme });
    } catch (error) {
        console.log("error", error);
    }
}

function* setThemeAsync(action) {
    let selectedTheme = action.payload;
    try {
        yield put({ type: Types.SET_THEME_ASYNC, selectedTheme });
    } catch (error) {
        console.log("error", error);
    }
}

export function* watchGetAllQuestions(data) {
    yield takeLatest(Types.GET_ALL_QUESTIONS, getAllQuestionsAsync);
}

export function* watchPostAnswer(data) {
    yield takeLatest(Types.POST_ANSWER, postAnswerAsync);
}

export function* watchOpenDrawer(data) {
    yield takeLatest(Types.OPEN_DRAWER, OpenDrawerAsync);
}

export function* watchClosePopup(data) {
    yield takeLatest(Types.CLOSE_MODAL_POPUP, closePopupAsync);
}

export function* watchTestProceed(data) {
    yield takeLatest(Types.SET_TEST_PROCEED, testProceedAsync);
}

export function* watchCloseTestProceed(data) {
    yield takeLatest(Types.CLOSE_SET_TEST_PROCEED, closeTestProceedAsync);
}

export function* watchCloseQuestionPage(data) {
    yield takeLatest(Types.RESET_QUESTION_PAGE, CloseQuestionPageAsync);
}

export function* watchTestStarted(data) {
    yield takeLatest(Types.IS_TEST_STARTED, testStartedAsync);
}
export function* watchSetBgImage(data) {
    yield takeLatest(Types.SET_BG_IMAGE, setBgImageAsync);
}

export function* watchSetTheme(data) {
    yield takeLatest(Types.SET_THEME, setThemeAsync);
}

function* getGetReportDetailsAsync(action) {
    try {
        yield put({ type: Types.GET_REPORT_DETAILS_ASYNC });
        const response = yield call(getReportDetails);
        if (response && response.status === 200) {
            yield put({ type: Types.GET_REPORT_DETAILS_RESPONSE, response })
        } else {
            yield put({ type: Types.GET_REPORT_DETAILS_ERROR, response })
            yield delay(2000);
        }
    } catch (error) {
        console.log("Error", error);
        yield put({ type: Types.GET_REPORT_DETAILS_ERROR, error });
    }
}

function* postPostFeedbackAsync(action) {
    try {
        yield put({ type: Types.POST_FEEDBACK_ASYNC });
        const response = yield call(postFeedback, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.POST_FEEDBACK_RESPONSE, response })
        } else {
            yield put({ type: Types.POST_FEEDBACK_ERROR, response })
            yield delay(2000);
        }
    } catch (error) {
        yield put({ type: Types.POST_FEEDBACK_ERROR, response });
        console.log("error", error);
    }
}

export function* watchGetReportDetails(data) {
    yield takeLatest(Types.GET_REPORT_DETAILS, getGetReportDetailsAsync);
}

export function* watchPostFeedback(data) {
    yield takeLatest(Types.POST_FEEDBACK, postPostFeedbackAsync);
}