/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import PrimaryButton from "../common/PrimaryButton";
import Copyright from "../common/Copyright";
import CircularProgressBar from "../common/CircularProgressBar";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";
import { MyTimer } from "../test/countdownTimer";
import { Types } from "../store/Types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Constants from "../../utils/constants/constants";
import homeBanner from "./../../assets/images/home-banner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  inputField: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: 40,
  },
  textField: {
    background: "#626262 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    opacity: 1,
    height: "40px",
    width: "100%",
    color: "#fff",
    margin: "0 0 1rem",
    paddingLeft: 12,
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
  },
  loginForm: {
    // width: "70%",
    // ["@media (max-width:680px)"]: {
    //   width: "100%",
    // },
  },
  link: {
    color: "#ffcd05",
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    cursor: "pointer",
  },
  title: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    letterSpacing: "0px",
    color: "#FFFFFF",
    margin: "0.5rem 0 1rem 0",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "16%",
      width: 6,
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    width: "100%",
    ["@media (max-width:650px)"]: {
      padding: "3rem 2rem 3rem",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
  loginBtnXsContainer: {
    ["@media (max-width:480px)"]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  loginXsBtn: {
    ["@media (max-width:480px)"]: {
      margin: "0.5rem 0 1.5rem",
    },
  },
}));

export default function VerifyOTP(props) {
  const classes = useStyles();
  const [OTPSent, isOTPSent] = useState(true);
  const [otp, setOTP] = useState("");
  const [loader, showLoader] = useState(false);
  const [resendOTPAttempt, setResendOTPAttempt] = useState(1);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const history = useHistory();
  const [otpErrorMsg, setOtpErrorMsg] = useState(false);
  const [otpMaxTryErrorMsg, setOtpMaxTryErrorMsg] = useState(false);
  const [enableLink, setEnableLink] = useState(true);
  const [otpInputs, setotpInputs] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);

  const handleResendOTP = () => {
    authReducer["showOTPSentSuccessMsg"] = false;
    setotpInputs((otpinput) => {
      return {
        ...otpinput,
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
      };
    });
    setOtpMaxTryErrorMsg(false);
    let otpAttempt = resendOTPAttempt + 1;
    setResendOTPAttempt(otpAttempt);
    setEnableLink(false);
    if (otpAttempt === 2) {
      time.setSeconds(time.getSeconds() + 480);
      let formData = { email: sessionStorage.getItem("useremail") };
      dispatch({
        type: "USER_LOGIN_OTP_REQUEST",
        payload: formData,
      });
    } else {
      setOtpMaxTryErrorMsg(true);
      setTimeout(() => {
        setOtpMaxTryErrorMsg(false);
      }, 10000);
    }
  };

  const handleEditEmail = () => {
    history.push("/login");
  };

  useEffect(() => {
    showLoader(authReducer.isLoading);
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoading]);

  useEffect(() => {
    authReducer["isOTPSentSuccessfully"] = false;
    return () => {
      console.log("clean up");
    };
  }, []);

  useEffect(() => {
    if (authReducer.isOTPVerifiedSuccessfully) {
      if (authReducer.isNewUser === 1) {
        history.push("/forceResetPassword");
      } else {
        history.push("/welcome");
      }
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isOTPVerifiedSuccessfully]);

  useEffect(() => {
    if (authReducer.isLoggedIn) {
      if (authReducer.isNewUser === 1) {
        history.push("/forceResetPassword");
      } else {
        history.push("/welcome");
      }
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoggedIn]);

  const handleVerifyCode = (code) => {
    let reg_code = "";
    for (const key in code) {
      if (Object.hasOwnProperty.call(code, key)) {
        reg_code += code[key];
      }
    }
    setOTP(reg_code);
  };

  const handleVerifyOTP = () => {
    if (otp.toString().length === 6) {
      setOtpErrorMsg(false);
      dispatch({
        type: Types.VERIFY_LOGIN_OTP_REQUEST,
        payload: {
          email: sessionStorage.getItem("useremail"),
          otp: Number(otp),
        },
      });
    } else {
      setOtpErrorMsg(true);
    }
  };

  const handleOTPExpired = (OTPExpired) => {
    isOTPSent(false);
    setEnableLink(true);
  };

  useEffect(() => {
    if (authReducer.isOTPSentSuccessfully) {
      isOTPSent(true);
      setEnableLink(false);
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isOTPSentSuccessfully]);

  useEffect(() => {
    if (authReducer.showOTPSentSuccessMsg) {
      toast.success("OTP has been sent to your email");
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.showOTPSentSuccessMsg]);

  const handleChange = (value1, event) => {
    setotpInputs((otpinput) => {
      return {
        ...otpinput,
        [value1]: event.target.value,
      };
    });
  };

  useEffect(() => {
    handleVerifyCode(otpInputs);
  }, [otpInputs]);

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <div>
      <Grid
        container
        component="main"
        className={`${classes.root} iphoneView `}
      >
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <div className="custom-infoGraphic-image">
              <img src={homeBanner} alt="" className="home-banner" />
            </div>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Verify OTP
                </Typography>
                <Typography className={classes.caption}>
                  A 6 digit code has been sent to you via email
                </Typography>
                <form
                  className={classes.loginForm}
                  noValidate
                  autoComplete="off"
                >
                  <div className="otpContainer">
                    {Constants.OTPINPUTSDATA.map((input) => (
                      <input
                        name={input.name}
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otpInputs[input.name]}
                        onChange={(e) => handleChange(input.name, e)}
                        tabIndex={input.id}
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                      />
                    ))}
                  </div>
                  <Grid
                    container
                    className={`${classes.flexContainer} ${classes.loginBtnXsContainer}`}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      {OTPSent ? (
                        <p
                          className={classes.link}
                          variant="body2"
                          style={{
                            color: "#fff",
                            textDecoration: "none",
                            cursor: "default",
                          }}
                        >
                          Resend OTP again in{" "}
                          <span>
                            <MyTimer
                              expiryTimestamp={time}
                              OTPExpired={handleOTPExpired}
                            />{" "}
                          </span>{" "}
                          secs
                        </p>
                      ) : (
                        <p
                          className={classes.link}
                          style={{
                            color: "#fff",
                            textDecoration: "none",
                            cursor: "auto",
                          }}
                        >
                          Haven't Received Yet?{" "}
                          <Link
                            to="/forgotPassword"
                            variant="body2"
                            onClick={handleResendOTP}
                            className={
                              "primary-link" +
                              (enableLink ? " enableOTPBtn " : " disableOTPBtn")
                            }
                          >
                            Resend OTP
                          </Link>
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <PrimaryButton
                        buttonText="Verify OTP"
                        handleClick={handleVerifyOTP}
                      ></PrimaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <PrimaryButton
                        buttonText="Edit Email"
                        handleClick={handleEditEmail}
                      ></PrimaryButton>
                    </Grid>
                    <span style={{ marginLeft: "0.6rem" }}>
                      {loader ? <CircularProgressBar /> : null}
                    </span>
                  </Grid>
                </form>
                <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
                {otpErrorMsg ? (
                  <p style={{ color: "red" }}>Please enter a valid OTP</p>
                ) : null}
                {otpMaxTryErrorMsg ? (
                  <p style={{ color: "red" }}>
                    You've reached maximum no of limits, please try after
                    sometime.
                  </p>
                ) : null}
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <Copyright></Copyright>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <div className={`${classes.copyRight} hide-iphone`}>
              <Copyright></Copyright>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>
      <ToastContainer />
    </div>
  );
}
