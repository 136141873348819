import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "../store/Types";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import CircularProgressBar from "../common/CircularProgressBar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#fff",
    backgroundColor: "#F6AC29",
    width: "14px",
    height: "14px",
    "&:hover, &:focus": {
      backgroundColor: "#F6AC29",
    },
  },
  modelTitle: {
    font: "normal normal bold 24px/28px RalewayBold",
    letterSpacing: 0,
    color: "#1D1D1B",
  },
  subText: {
    font: "normal normal medium 20px/28px RalewayMedium",
  },
});

const EmailVerification = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const authReducer = useSelector((state) => state.authReducer);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    sessionStorage.removeItem("token");
    let token = props.location.search.substring(7);
    if (token) {
      dispatch({
        type: Types.EMAIL_VERIFY_REQUEST,
        payload: { activation_code: token },
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (authReducer.emailVerifyMessage) {
      setOpen(true);
    } else {
    }
    return () => {
      console.log("Email verification password cleanup");
    };
  }, [authReducer.emailVerifyMessage]);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const handleClose = () => {
    history.push("/");
  };

  return (
    <div>
      {authReducer.emailVerifyMessage ? (
        <React.Fragment>
          <Dialog
            onClose={handleClose}
            aria-labelledby="email-verify-dialog"
            open={open}
            maxWidth="xs"
          >
            <DialogTitle
              id="email-verify-dialog-title"
              onClose={handleClose}
            ></DialogTitle>
            <DialogContent dividers id="email-verify-dialog-content">
              <p>{authReducer.emailVerifyMessage}</p>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      ) : null}

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: "calc(100vh - 60px)"}}>
        {authReducer.isLoading ? <CircularProgressBar /> : null}
      </div>
    </div>
  );
};

export default EmailVerification;
