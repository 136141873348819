/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DialogTitle, Grid, Hidden, Typography } from "@material-ui/core";
import PrimaryButton from "../common/PrimaryButton";
import Constants from "../../utils/constants/constants";
import AccordionComp from "./../common/AccordionComp";
import Paper from "@material-ui/core/Paper";
import Copyright from "../common/Copyright";
import faqTitle from "./../../assets/images/faq-title.png";
import Bright from "./../../assets/images/bright.png";
import Otpinput from "../common/otpInput";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "../store/Types";
import CircularProgressBar from "../common/CircularProgressBar";
import { Dialog, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  infoGraphicImageContainer: {
    textAlign: "left",
    padding: "2rem 24px",
    ["@media (max-width:480px)"]: {
      padding: "1rem 24px",
      position: "relative",
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    padding: "1rem 3rem 1rem",
    width: "100%",
    ["@media (max-width:480px)"]: {
      padding: "3rem 2rem 1rem",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "14%",
      width: 6,
    },
  },
  textField: {
    background: "#121210 0% 0% no-repeat padding-box",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: "40px",
    width: "100%",
    color: "#fff",
    margin: "0 0 0.5rem",
    paddingLeft: 12,
    fontSize: "1rem",
  },
  formContainer: {
    padding: "0 1rem",
  },
  registerCodeForm: {
    // padding: "3rem 0 2rem",
  },
  faqContainer: {
    paddingBottom: "3rem",
  },
  submitBtn: {
    background: "#F6AC29 0% 0% no-repeat",
    borderRadius: 23,
    opacity: 1,
    color: "#1D1D1B",
    "&:hover, &:focus": {
      background: "#F6AC29 0% 0% no-repeat padding-box",
    },
    font: "normal normal bold 16px/19px RalewayBold",
    textTransform: "capitalize",
    padding: "12px 23px",
  },
  faqTitleImage: {
    width: 80,
    marginBottom: "1.5rem",
  },
  accordionContainer: {
    maxHeight: "calc(100vh - 160px)",
    overflow: "auto",
  },
  title: {
    font: "normal normal bold 28px/36px RalewayBold",
    letterSpacing: "0",
    ["@media (max-width:480px)"]: {
      font: "normal normal bold 18px/28px RalewayBold",
    },
  },
  caption: {
    font: "normal normal 500 14px/30px RalewayMedium",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 0.5,
    ["@media (max-width:480px)"]: {
      font: "normal normal 500 12px/30px RalewayMedium",
    },
  },
  registerForm: {
    margin: "1rem 0",
    width: "70%",
    ["@media (max-width:480px)"]: {
      width: "90%",
    },
  },
  copyRight: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "0 3rem 1rem",
    ["@media (max-width:480px)"]: {
      padding: "0 1rem 1rem",
    },
  },
  brightImg: {
    maxWidth: "100px",
    ["@media (max-width:480px)"]: {
      maxWidth: "60px",
    },
  },
});

const Register = () => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [registerForm, setRegisterForm] = useState(false);
  const [ButtonText, setButtonText] = useState("Verify");
  const [registrationCode, setRegistrationCode] = useState("");
  const authReducer = useSelector((state) => state.authReducer);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    pass: "",
    cpass: "",
  });

  const handleRegister = () => {
    console.log("handleRegister", userData);
    if (handleValidation()) {
      let request = {
        authorization_code: registrationCode,
        email: userData.email,
        firstName: userData.fname,
        lastName: userData.lname,
        password: userData.pass,
        contact: userData.phone,
        category_id: 4,
        address: "Bangalore",
        ip: "157.45.189.148",
      };
      dispatch({
        type: Types.USER_REGISTER_REQUEST,
        payload: request,
      });
    }
  };

  const handleVerifyCode = (code) => {
    let reg_code = "";
    for (const key in code) {
      if (Object.hasOwnProperty.call(code, key)) {
        reg_code += code[key];
      }
    }

    setRegistrationCode(reg_code); //Save Registration code for registration
    dispatch({
      type: Types.REG_CODE_VERIFY_REQUEST,
      payload: { authorization_code: reg_code },
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    setUserData((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleValidation = () => {
    let formValid = false;
    const { fname, lname, email, phone, pass, cpass } = userData;
    // Name
    if (fname === "") {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["fname"]: Constants.ERROR_MESSAGES.required,
      }));
    } else if (!fname.match(/^[a-zA-Z\s]+$/)) {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["fname"]: Constants.ERROR_MESSAGES.inValidName,
      }));
    } else {
      formValid = true;
      setErrors((errors) => ({
        ...errors,
        ["fname"]: "",
      }));
    }

    //Email
    if (email === "") {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["email"]: Constants.ERROR_MESSAGES.required,
      }));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["email"]: Constants.ERROR_MESSAGES.invalidEmail,
      }));
    } else {
      formValid = true;
      setErrors((errors) => ({
        ...errors,
        ["email"]: "",
      }));
    }

    if (phone !== "") {
      if (!phone.match(/^[0-9]+$/)) {
        formValid = false;
        setErrors((errors) => ({
          ...errors,
          ["phone"]: Constants.ERROR_MESSAGES.numberVerify,
        }));
      } else if (phone.length < 8 || phone.length > 15) {
        formValid = false;
        setErrors((errors) => ({
          ...errors,
          ["phone"]: Constants.ERROR_MESSAGES.numberlength,
        }));
      } else {
        formValid = true;
        setErrors((errors) => ({
          ...errors,
          ["phone"]: "",
        }));
      }
    } else {
      formValid = true;
      setErrors((errors) => ({
        ...errors,
        ["phone"]: "",
      }));
    }

    if (pass === "") {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["pass"]: Constants.ERROR_MESSAGES.required,
      }));
    } else if (pass.length < 8) {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["pass"]: Constants.ERROR_MESSAGES.passLength,
      }));
    } else {
      formValid = true;
      setErrors((errors) => ({
        ...errors,
        ["pass"]: "",
      }));
    }

    if (cpass === "") {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["cpass"]: Constants.ERROR_MESSAGES.required,
      }));
    } else if (cpass.length < 8) {
      formValid = false;
      setErrors((errors) => ({
        ...errors,
        ["cpass"]: Constants.ERROR_MESSAGES.passLength,
      }));
    } else {
      formValid = true;
      setErrors((errors) => ({
        ...errors,
        ["cpass"]: "",
      }));
    }

    if (pass !== "" && pass.length > 7 && pass !== "" && cpass.length > 7) {
      if (pass !== cpass) {
        formValid = false;
        setErrors((errors) => ({
          ...errors,
          ["cpass"]: Constants.ERROR_MESSAGES.passverify,
        }));
      } else {
        formValid = true;
        setErrors((errors) => ({
          ...errors,
          ["cpass"]: "",
        }));
      }
    }

    return formValid;
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#fff",
      backgroundColor: "#F6AC29",
      width: "14px",
      height: "14px",
      "&:hover, &:focus": {
        backgroundColor: "#F6AC29",
      },
    },
    modelTitle: {
      font: "normal normal bold 24px/28px RalewayBold",
      letterSpacing: 0,
      color: "#1D1D1B",
    },
    subText: {
      font: "normal normal medium 20px/28px RalewayMedium",
    },
  });

  useEffect(() => {
    if (authReducer.regCodeVerifedMsg) {
      setButtonText("Register");
      setRegisterForm(true);
    } else if (authReducer.errorMessage !== "") {
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
    return () => {
      console.log("reset password cleanup");
    };
  }, [authReducer.regCodeVerifedMsg]);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const handleClose = () => {
    setOpen(false);
    history.push("/");
  };

  useEffect(() => {
    if (authReducer.registered) {
      setOpen(true);
    } else {
    }
    return () => {
      console.log("Registration cleanup");
    };
  }, [authReducer.registered]);

  return (
    <React.Fragment>
      <Grid container component="main" className={classes.root}>
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <div className={classes.faqContainer}>
              <img src={faqTitle} alt="" className={classes.faqTitleImage} />
              <div className={classes.accordionContainer}>
                <AccordionComp />
              </div>
            </div>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
          className={classes.rightSideContentContainer}
        >
          <div className={classes.rightSideContainer}>
            <div className={classes.thickRightBorder}></div>

            <Hidden only={["sm", "lg", "md", "xl"]}>
              <img src={Bright} alt="bright" className={classes.brightImg} />
            </Hidden>
            {registerForm ? (
              <React.Fragment>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Register
                </Typography>
                <Typography className={classes.caption}>
                  Please fill the details below to register
                </Typography>
                <form className={classes.registerForm} autoComplete="off">
                  {Constants.FORM_INPUTS.map((input) => (
                    <React.Fragment key={input.id}>
                      <input
                        type={input.type}
                        name={input.name}
                        placeholder={input.placeholder}
                        required={input.required}
                        className={classes.textField}
                        onChange={handleInputChange}
                      />
                      <div className="error">{errors[input.name]}</div>
                    </React.Fragment>
                  ))}
                  <Grid container className={classes.flexContainer}>
                    <Grid item xs style={{ display: "inline-flex" }}>
                      <PrimaryButton
                        buttonText={"Register"}
                        handleClick={handleRegister}
                      ></PrimaryButton>
                      <span style={{ marginLeft: "0.6rem" }}>
                        {authReducer.isLoading ? <CircularProgressBar /> : null}
                      </span>
                    </Grid>
                  </Grid>
                </form>
                <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form className={classes.registerCodeForm}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                  >
                    Verify Account
                  </Typography>
                  <Typography className={classes.caption}>
                    Enter registration code below to verify your account
                  </Typography>
                  <Otpinput verifyCode={handleVerifyCode} />
                  <span style={{ marginLeft: "0.6rem" }}>
                    {authReducer.isLoading ? <CircularProgressBar /> : null}
                  </span>
                  <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
                </form>
              </React.Fragment>
            )}
            <Hidden only={["xs"]}>
              <div className={classes.copyRight}>
                <Copyright></Copyright>
              </div>
            </Hidden>
          </div>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <div className={classes.faqContainer}>
              <img src={faqTitle} alt="" className={classes.faqTitleImage} />
              <AccordionComp />
            </div>
            <div className={`${classes.copyRight} hide-iphone `}>
              <Copyright></Copyright>
            </div>
          </Grid>
        </Hidden>
      </Grid>
      {authReducer.registered ? (
        <React.Fragment>
          <Dialog
            onClose={handleClose}
            aria-labelledby="email-verify-dialog"
            open={open}
            className={classes.modalContainer}
            maxWidth="xs"
          >
            <DialogTitle
              id="email-verify-dialog-title"
              onClose={handleClose}
            ></DialogTitle>
            <DialogContent dividers id="email-verify-dialog-content">
              <p>{authReducer.registrationSucccessMsg}</p>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default Register;
