/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import closePageIcon from "./../../assets/images/report/close-page-icon.svg";

const useStyles = makeStyles((theme) => ({
  AnimatedDrawerContainer: {
    padding: "0 140px",
    height: "calc(100vh - 120px)",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "#151515",
    font: "normal normal 500 24px/30px PoppinsSemiBold",
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      font: "normal normal 500 28px/34px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 32px/38px PoppinsSemiBold",
    },
  },
  desc: {
    color: "#626262",
    paddingRight: "16px",
    font: "normal normal 500 18px/24px PoppinsSemiBold",
    ["@media (max-width:1366px)"]: {
      font: "normal normal 400 16px/24px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      paddingRight: "22px",
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 400 26px / 38px PoppinsMedium",
      paddingRight: "22px",
    },
  },
  desc2: {
    top: "56%",
    left: "15%",
    paddingRight: "100px",
    font: "normal normal 300 20px/28px PoppinsSemiBold !important",
    ["@media (max-width:1366px)"]: {
      top: "58%",
      left: "13%",
      font: "normal normal 300 14px/20px PoppinsMedium !important",
    },
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      top: "56%",
      left: "13%",
      font: "normal normal 300 16px/20px PoppinsMedium !important",
    },
  },
  desc3: {
    top: "70%",
    left: "15%",
    paddingRight: "100px",
    font: "normal normal 300 20px/28px PoppinsSemiBold !important",
    ["@media (max-width:1366px)"]: {
      top: "72%",
      left: "13%",
      font: "normal normal 300 14px/20px PoppinsMedium !important",
    },
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      top: "70%",
      left: "13%",
      font: "normal normal 300 16px/20px PoppinsMedium !important",
    },
  },
  closeBtn: {
    top: "10%",
    right: "10%",
    color: "#707070",
    opacity: 1,
    position: "absolute",
    cursor: "pointer",
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "16%",
    },
    ["@media (min-width:1443px)"]: {
      top: "16%",
    },
  },
  closeBtnImage: {
    width: "30px",
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "30px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      width: "50px",
    },
    ["@media (min-width:1443px)"]: {
      width: "50px",
    },
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.outerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function getBackgroundImage(props, width) {
  if (width <= 1024) {
    return `url(https://etqglobal.solutions/images/pp-${props.data["type"]}-${props.ppType}-bg-xs.svg)`;
  } else if (width > 1025 && width <= 1280) {
    return `url(https://etqglobal.solutions/images/pp-${props.data["type"]}-${props.ppType}-bg-s.svg)`;
  } else if (width > 1281 && width <= 1366) {
    return `url(https://etqglobal.solutions/images/pp-${props.data["type"]}-${props.ppType}-bg-m.svg)`;
  } else if (width > 1367 && width <= 1440) {
    return `url(https://etqglobal.solutions/images/pp-${props.data["type"]}-${props.ppType}-bg-l.svg)`;
  } else if (width > 1441) {
    return `url(https://etqglobal.solutions/images/pp-${props.data["type"]}-${props.ppType}-bg-xl.svg)`;
  }
}

function getImageSource(props) {
  return `https://etqglobal.solutions/images/pp-b-${props["ppType"]}.svg`;
}

const AnimatedDrawer = (props) => {
  const classes = useStyles();

  const closeAnimatedDrawer = (event) => {
    props.animatedDrawerType(event);
  };

  const getScoreTitle = (type) => {
    if (type === "ht") {
      return "Human Thinking: ";
    } else if (type === "it") {
      return "Innovation Thinking: ";
    } else {
      return "Risk Thinking: ";
    }
  };

  return (
    <React.Fragment>
      <div className={classes.AnimatedDrawerContainer}>
        <TransitionGroup>
          <CSSTransition
            classNames={{
              enterActive: "animate__animated animate__lightSpeedInLeft",
              exitActive: "animate__animated animate__lightSpeedOutLeft",
            }}
            timeout={5000}
          >
            <Grid container style={{ padding: "0" }}>
              <p
                onClick={() => closeAnimatedDrawer(false)}
                className={classes.closeBtn}
              >
                <img
                  className={classes.closeBtnImage}
                  src={closePageIcon}
                  alt="close-page-icon"
                />
              </p>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                style={{ display: "grid", justifyContent: "center" }}
              >
                <img src={getImageSource(props)} alt="" class="ht-icon" />
              </Grid>
              <Grid item xs={10} sm={10} md={10}>
                <div class="">
                  <h2 className={classes.title}>
                    <span className={`${props.data["type"]}TextColor pr-36`}>
                      {getScoreTitle(props.data["type"])}{" "}
                    </span>
                    {props.data["title"]}
                  </h2>
                  <Grid container>
                    <Grid xs={12} sm={6} md={6}>
                      {props.data["desc"].split(":").map((item, index) => {
                        return (
                          <React.Fragment>
                            <p key={index} className={classes.desc}>
                              {item}
                            </p>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                    <Grid xs={12} sm={6} md={6}>
                      {props.data["desc2"].split(":").map((item, index) => {
                        return (
                          <React.Fragment>
                            <p key={index} className={classes.desc}>
                              {item}
                            </p>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </React.Fragment>
  );
};

export default AnimatedDrawer;
