/* eslint-disable default-case */
import { Types } from "../Types";

const defaultState = {
    isLoggedIn: false,
    isLoading: false,
    isTestStarted: false,
    isOpenLoginScreen: false,
    isOpenForgotPasswordScreen: false,
    counter: 0,
    userInfo: {},
    isPasswordResetted: false,
    resetPasswordSuccessMsg: "",
    isPasswordChanged: false,
    changePasswordSuccessMsg: "",
    redirectToLoginScreen: false,
    showDownArrow: true,
    darkTheme: false,
    isEmailVerified: false,
    registered: false,
    regCodeVerifedMsg: "",
    registrationSucccessMsg: "",
    isNewUser: "",
    isForcePasswordChanged: false,
    forceChangePasswordSuccessMsg: "",
    isOTPSentSuccessfully: false,
    isOTPVerifiedSuccessfully: false,
    showEnableButton: false,
    showOTPSentSuccessMsg: false,
}

const AuthReducer = (state = defaultState, action) => {
    const newState = { ...state };

    switch (action.type) {

        case Types.THEME_SELECTION_ASYNC:
            {
                return {
                    ...newState,
                    darkTheme: action.selectedTheme
                }
            }

        case Types.OPEN_LOGIN_SCREEN_ASYNC:
            {
                return {
                    ...newState,
                    isOpenLoginScreen: true,
                }
            }

        case Types.USER_LOGIN_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.USER_LOGIN_RESPONSE:
            {
                sessionStorage.setItem('token', action.response.data.token);
                return {
                    ...newState,
                    isTestPage: true,
                    isNewUser: action.response.data.is_new_user,
                    isLoggedIn: true,
                    isLoading: false,
                    isClearTextFields: true,
                    isOpenLoginScreen: false,
                    errorMessage: "",
                    isForcePasswordChanged: false,
                    forceChangePasswordSuccessMsg: ""
                };
            }

        case Types.USER_LOGIN_ERROR:
            {
                return {
                    ...newState,
                    errorMessage: action.response.message,
                    isLoading: false
                };
            }

        case Types.OPEN_RESET_PASSWORD_SCREEN_ASYNC:
            {
                return {
                    ...newState,
                    isOpenForgotPasswordScreen: true,
                };
            }

        case Types.RESET_PASSWORD_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.RESET_PASSWORD_RESPONSE:
            {
                return {
                    ...newState,
                    isPasswordResetted: true,
                    isLoading: false,
                    resetPasswordSuccessMsg: action.response.data.message,
                };
            }

        case Types.RESET_PASSWORD_ERROR:
            {
                return {
                    ...newState,
                    isPasswordResetted: true,
                    errorMessage: action.response.data.message,
                    isLoading: false
                };
            }

        case Types.GET_USER_INFO_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.GET_USER_INFO_RESPONSE:
            {
                return {
                    ...newState,
                    userInfo: action.response.data,
                };
            }

        case Types.GET_USER_INFO_ERROR:
            {
                return {
                    ...newState,
                    errorMessage: action.response.message,
                    isLoading: false
                };
            }

        case Types.GO_TO_LOGIN_SCREEN_ASYNC:
            {
                return {
                    ...newState,
                    redirectToLoginScreen: true
                };
            }

        case Types.USER_LOGOUT_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoggedIn: false,
                    isOTPVerifiedSuccessfully: false,
                    isOpenLoginScreen: true,
                    isTestPage: false,
                    isLoading: false,
                };
            }

        case Types.USER_EXIST_ASYNC:
            {
                return {
                    ...newState,
                    isLoggedIn: true,
                };
            }

        case Types.MOUSE_ENTER_EVENT_ASYNC:
            {
                return {
                    ...newState,
                    showDownArrow: false,
                };
            }

        case Types.MOUSE_LEAVE_EVENT_ASYNC:
            {
                return {
                    ...newState,
                    showDownArrow: true,
                };
            }

        case Types.CHANGE_PASSWORD_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.CHANGE_PASSWORD_RESPONSE:
            {
                return {
                    ...newState,
                    isPasswordChanged: true,
                    isLoading: false,
                    changePasswordSuccessMsg: action.response.data.message,
                };
            }

        case Types.CHANGE_PASSWORD_ERROR:
            {
                return {
                    ...newState,
                    errorMessage: action.response.message,
                    isLoading: false
                };
            }

        case Types.EMAIL_CONFIRM_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.EMAIL_CONFIRM_RESPONSE:
            {
                return {
                    ...newState,
                    isEmailVerified: true,
                };
            }

        case Types.EMAIL_CONFIRM_ERROR:
            {
                return {
                    ...newState,
                    errorMessage: action.response.message,
                    isLoading: false
                };
            }

        case Types.REG_CODE_VERIFY_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.REG_CODE_VERIFY_RESPONSE:
            {
                return {
                    ...newState,
                    isLoading: false,
                    regCodeVerifedMsg: action.response.data.message,
                };
            }

        case Types.REG_CODE_VERIFY_ERROR:
            {
                return {
                    ...newState,
                    isLoading: false,
                    errorMessage: action.response.data.message,
                };
            }

        case Types.USER_REGISTER_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.USER_REGISTER_RESPONSE:
            {
                return {
                    ...newState,
                    isLoading: false,
                    errorMessage: "",
                    registered: true,
                    registrationSucccessMsg: action.response.message,
                };
            }

        case Types.USER_REGISTER_ERROR:
            {
                return {
                    ...newState,
                    errorMessage: action.response.message,
                    isLoading: false
                };
            }

        case Types.EMAIL_VERIFY_REQUEST_ASYNC:
            {
                return {
                    ...newState,
                    isLoading: true,
                };
            }

        case Types.EMAIL_VERIFY_RESPONSE:
            {
                return {
                    ...newState,
                    isLoading: false,
                    errorMessage: "",
                    emailVerifyMessage: "Your email has been verified. Please login to begin learning",
                };
            }

        case Types.EMAIL_VERIFY_ERROR:
            {
                return {
                    ...newState,
                    emailVerifyMessage: action.response.data.message,
                    isLoading: false
                };
            }

            case Types.FORCE_RESET_PASSWORD_ASYNC:
                {
                    return {
                        ...newState,
                        isLoading: true,
                    };
                }
    
            case Types.FORCE_RESET_PASSWORD_RESPONSE:
                {
                    return {
                        ...newState,
                        isForcePasswordChanged: true,
                        isLoading: false,
                        forceChangePasswordSuccessMsg: action.response.data.message,
                        isLoggedIn: false
                    };
                }
    
            case Types.FORCE_RESET_PASSWORD_ERROR:
                {
                    return {
                        ...newState,
                        errorMessage: action.response.message,
                        isLoading: false
                    };
                }

            case Types.USER_REGISTER_OTP_REQUEST_ASYNC:
                {
                    return {
                        ...newState,
                        isLoading: true,
                    };
                }
    
            case Types.USER_REGISTER_OTP_RESPONSE:
                {
                    return {
                        ...newState,
                        isLoading: false,
                        errorMessage: "",
                        registered: true,
                        registrationSucccessMsg: action.response.message,
                    };
                }
    
            case Types.USER_REGISTER_OTP_ERROR:
                {
                    return {
                        ...newState,
                        errorMessage: action.response.message,
                        isLoading: false
                    };
                }

                case Types.USER_LOGIN_OTP_REQUEST_ASYNC:
                    {
                        return {
                            ...newState,
                            isLoading: true,
                            showEnableButton: false,
                        };
                    }
        
                case Types.USER_LOGIN_OTP_RESPONSE:
                    {
                        return {
                            ...newState,
                            isLoading: false,
                            errorMessage: "",
                            isOTPSentSuccessfully: true,
                            showEnableButton: false,
                            showOTPSentSuccessMsg: true
                        };
                    }
        
                case Types.USER_LOGIN_OTP_ERROR:
                    {
                        return {
                            ...newState,
                            errorMessage: action.response.data.message,
                            isLoading: false,
                            showEnableButton: true,
                        };
                    }

                case Types.VERIFY_LOGIN_OTP_REQUEST_ASYNC:
                    {
                        return {
                            ...newState,
                            isLoading: true,
                        };
                    }
        
                case Types.VERIFY_LOGIN_OTP_RESPONSE:
                    {            
                        sessionStorage.setItem('token', action.response.data.data.token);
                        return {
                            ...newState,
                            isLoading: false,
                            errorMessage: "",
                            isOTPVerifiedSuccessfully: true,
                            isNewUser: action.response.data.data.is_new_user,
                            isLoggedIn: true,
                        };
                    }
        
                case Types.VERIFY_LOGIN_OTP_ERROR:
                    {
                        return {
                            ...newState,
                            errorMessage: action.response.data.message,
                            isLoading: false
                        };
                    }

        default:
            return newState;
    }
};

export default AuthReducer;