import { all, fork } from "redux-saga/effects";
import * as authSagas from './AuthSagas';
import * as testSagas from "./TestSagas";

function* rootSaga() {
    yield all(
        [...Object.values(authSagas), ...Object.values(testSagas)].map(fork)
    );
}

export default rootSaga;