/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import PrimaryButton from "../common/PrimaryButton";
import CopyRight from "../common/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "../store/Types";
import { Paper } from "@material-ui/core";

import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";
import { useHistory } from "react-router-dom";
import CircularProgressBar from "../common/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    ["@media (max-width:480px)"]: {
      font: "normal normal bold 18px/28px PoppinsBold",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  inputField: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: 40,
  },
  textField: {
    background: "#626262 0% 0% no-repeat padding-box",
    border: "1px solid #000",
    opacity: 1,
    height: "40px",
    width: "100%",
    color: "#fff",
    margin: "0 0 1rem",
    paddingLeft: 12,
    fontSize: "1rem",
  },
  loginForm: {
    margin: "1rem 0",
    // width: "70%",
    ["@media (max-width:480px)"]: {
      width: "90%",
    },
  },
  link: {
    color: "#ffcd05",
    textDecoration: "underline",
  },
  caption: {
    font: "normal normal medium 14px/30px PoppinsRegular",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 0.5,
    ["@media (max-width:480px)"]: {
      font: "normal normal 500 12px/30px PoppinsMedium",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
    margin: "10px 0",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "1rem 0 2.5rem",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "24%",
      width: 6,
    },
  },
  rightSideContentContainer: {
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    // padding: "3rem 3rem 1rem",
    width: "100%",
    ["@media (max-width:480px)"]: {
      padding: "4rem 2rem 2rem",
    },
  },
  title: {
    font: "normal normal 600 21px/30px PoppinsSemiBold",
    letterSpacing: "0",
    ["@media (max-width:480px)"]: {
      font: "normal normal 600 21px/30px PoppinsSemiBold",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      padding: "0 1rem 1rem",
    },
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const [ButtonText, setButtonText] = useState("Change Password");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [textInputdisabled, setTextInputdisabled] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);

  const handleUserInput = (e) => {
    if (e.target.name === "password") {
      if (e.target.value === "" || e.target.value === null) {
        setEmailErrorMessage("Password field can not be empty.");
        setpassword("");
      } else {
        setpassword(e.target.value);
        setEmailErrorMessage("");
      }
    }
    if (e.target.name === "cpassword") {
      if (e.target.value === "" || e.target.value == null) {
        setEmailErrorMessage("Confirm password field can not be empty.");
        setcpassword("");
      } else {
        setcpassword(e.target.value);
        setEmailErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (
      authReducer.isPasswordChanged &&
      authReducer.changePasswordSuccessMsg !== ""
    ) {
      setSuccessMsg(authReducer.changePasswordSuccessMsg);
      setpassword("");
      setcpassword("");
      setTimeout(() => {
        setSuccessMsg("");
        setTextInputdisabled(false);
        history.push("/login");
      }, 3000);
    } else if (
      authReducer.isPasswordChanged &&
      authReducer.errorMessage !== ""
    ) {
      setErrorMsg(authReducer.errorMessage);
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
    return () => {
      console.log("change password cleanup");
    };
  }, [authReducer.isPasswordChanged]);

  const onClickAssesment = () => {
    if (
      password === "" ||
      password === null ||
      cpassword === "" ||
      cpassword === null
    ) {
      setEmailErrorMessage("Please fill all the fields");
    }

    if (password === cpassword) {
      setTextInputdisabled(true);
      const formData = {
        new_password: password,
        token: props.location.search.substring(7),
      };
      dispatch({
        type: Types.CHANGE_PASSWORD_REQUEST,
        payload: formData,
      });
    } else {
      setEmailErrorMessage("Password and confirm password does not match");
    }
  };

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Change Password
                </Typography>
                <Typography className={classes.caption}></Typography>
                <form
                  className={classes.loginForm}
                  noValidate
                  autoComplete="off"
                >
                  <input
                    type="password"
                    name="password"
                    value={password}
                    placeholder="Password"
                    className={`${classes.textField} customTextField`}
                    disabled={textInputdisabled ? "disabled" : ""}
                    onChange={(event) => handleUserInput(event)}
                    required
                  />{" "}
                  <br />
                  <input
                    type="password"
                    name="cpassword"
                    value={cpassword}
                    placeholder="Confirm password"
                    className={`${classes.textField} customTextField`}
                    disabled={textInputdisabled ? "disabled" : ""}
                    onChange={(event) => handleUserInput(event)}
                    required
                  />
                  <div className={classes.errorMessage}>
                    {" "}
                    {emailErrorMessage}
                  </div>
                  <Grid container className={classes.flexContainer}>
                    <Grid item xs style={{ display: "inline-flex" }}>
                      <PrimaryButton
                        buttonText={ButtonText}
                        handleClick={onClickAssesment}
                      ></PrimaryButton>
                      <span style={{ marginLeft: "0.6rem" }}>
                        {authReducer.isLoading ? <CircularProgressBar /> : null}
                      </span>
                    </Grid>
                  </Grid>
                </form>
                {successMsg ? <p>{successMsg}</p> : null}
                <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <CopyRight></CopyRight>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <div className={classes.copyRight}>
              <CopyRight></CopyRight>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default ChangePassword;
