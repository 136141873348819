/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import PrimaryButton from "../common/PrimaryButton";
import Copyright from "../common/Copyright";
import CircularProgressBar from "../common/CircularProgressBar";
import validator from "validator";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  inputField: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: 40,
  },
  textField: {
    background: "#626262 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    opacity: 1,
    height: "40px",
    width: "100%",
    color: "#fff",
    margin: "0 0 1rem",
    paddingLeft: 12,
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
  },
  loginForm: {
    // width: "70%",
    // ["@media (max-width:680px)"]: {
    //   width: "100%",
    // },
  },
  link: {
    color: "#ffcd05",
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    cursor: "pointer",
  },
  title: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    letterSpacing: "0px",
    color: "#FFFFFF",
    margin: "0.5rem 0 1rem 0",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "16%",
      width: 6,
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    width: "100%",
    ["@media (max-width:650px)"]: {
      padding: "3rem 2rem 3rem",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
  loginBtnXsContainer: {
    ["@media (max-width:480px)"]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  loginXsBtn: {
    ["@media (max-width:480px)"]: {
      margin: "0.5rem 0 1.5rem",
    },
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [loader, showLoader] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passworderrorMessage, setPassworderrorMessage] = useState("");
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const history = useHistory();

  const onClickAssesment = () => {
    if (userEmail === "" || userEmail === null) {
      setEmailErrorMessage("Email field can not be empty.");
    }

    if (userPass === "" || userPass === null) {
      setPassworderrorMessage("Password field can not be empty.");
    }
    if (userEmail !== "" && userPass !== "") {
      if (validator.isEmail(userEmail)) {
        setEmailErrorMessage("");
        let formData = { email: userEmail, password: userPass };
        dispatch({
          type: "USER_LOGIN_REQUEST",
          payload: formData,
        });
      } else {
        setEmailErrorMessage("Enter a valid email!");
      }
    }
  };

  const handleUserInput = (e) => {
    if (e.target.name === "email") {
      if (e.target.value === "" || e.target.value == null) {
        setEmailErrorMessage("Email can not be empty.");
      } else {
        setUserEmail(e.target.value);
        setEmailErrorMessage("");
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        setPassworderrorMessage("Password can not be empty.");
      } else {
        setUserPass(e.target.value);
        setPassworderrorMessage("");
      }
    }
  };

  const handleForgotPassword = () => {
    history.push("/forgotPassword");
  };

  useEffect(() => {
    showLoader(authReducer.isLoading);
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoading]);

  useEffect(() => {
    if (authReducer.isLoggedIn) {
      if (authReducer.isNewUser === 1) {
        history.push("/forceResetPassword");
      } else {
        history.push("/welcome");
      }
      setUserEmail("");
      setUserPass("");
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoggedIn]);

  return (
    <div>
      <Grid
        container
        component="main"
        className={`${classes.root} iphoneView `}
      >
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Login
                </Typography>
                <Typography className={classes.caption}>
                  Login with your registered email id
                </Typography>
                <form
                  className={classes.loginForm}
                  noValidate
                  autoComplete="off"
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email id"
                    className={`${classes.textField} customTextField`}
                    onChange={(event) => handleUserInput(event)}
                    required
                  />
                  <div className={classes.errorMessage}>
                    {" "}
                    {emailErrorMessage}
                  </div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className={`${classes.textField} customTextField`}
                    onChange={(event) => handleUserInput(event)}
                    required
                  />
                  <div className={classes.errorMessage}>
                    {" "}
                    {passworderrorMessage}
                  </div>

                  <Grid
                    container
                    className={`${classes.flexContainer} ${classes.loginBtnXsContainer}`}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Link
                        to="/forgotPassword"
                        className={classes.link}
                        variant="body2"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password
                      </Link>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <PrimaryButton
                        buttonText="Login"
                        handleClick={onClickAssesment}
                      ></PrimaryButton>
                      <span style={{ marginLeft: "0.6rem" }}>
                        {loader ? <CircularProgressBar /> : null}
                      </span>
                    </Grid>
                  </Grid>
                </form>
                <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <Copyright></Copyright>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <div className={`${classes.copyRight} hide-iphone`}>
              <Copyright></Copyright>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
