/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SvgScoringSystemSM from "../common/SvgScoringSystemSM";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    textAlign: "left",
    overflowY: "auto",
    height: "calc(100vh - 150px)",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  sectionTitle: {
    color: "#214697",
    // font: "normal normal 300 44px/120px PoppinsSemiBold",
    font: "normal normal 500 44px/52px PoppinsSemiBold",
    margin: "26px 0",
    ["@media (max-width:1024px)"]: {
      font: "normal normal 500 24px/30px PoppinsSemiBold",
      margin: "16px 0",
    },
    ["@media (min-width:1025px) and (max-width:1282px)"]: {
      font: "normal normal 500 30px/48px PoppinsSemiBold",
      margin: "24px 0 16px 0",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 24px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      font: "normal normal 500 36px/48px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    font: "normal normal 400 16px/22px PoppinsMedium",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 400 16px/22px PoppinsMedium",
    },
    ["@media (min-width:1383px) and (max-width:1600px)"]: {
      font: "normal normal 400 22px/28px PoppinsMedium",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 400 24px/32px PoppinsMedium",
    },
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    "@media (max-width:1282px)": {
      font: "normal normal 400 12px/18px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  scroingContainer: {
    width: "220px",
    height: "220px",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "250px",
      height: "250px",
    },
    "@media (min-width:1367px) and (max-width:1442px)": {
      width: "320px",
      height: "320px",
    },
    "@media (min-width:1443px) and (max-width:1600px)": {
      width: "320px",
      height: "320px",
    },
    ["@media (min-width:1601px)"]: {
      width: "450px",
      height: "450px",
    },
  },
  scoreTitle: {
    font: "normal normal 500 24px/26px PoppinsSemiBold",
    position: "absolute",
    top: 0,
    left: "0",
    width: "100px",
    margin: 0,
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 22px/24px PoppinsSemiBold",
    },
    "@media (min-width:1367px) and (max-width:1442px)": {
      font: "normal normal 500 30px/34px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 34px/38px PoppinsSemiBold",
    },
  },
  
  scoreRangeText: {
    font: "normal normal 500 14px/16px PoppinsSemiBold",
    position: "absolute",
    top: 130,
    left: "0",
    width: "130px",
    margin: 0,
    "@media (max-width:1282px)": {
      top: 110,
      width: "130px",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      top: 110,
      width: "130px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      top: 140,
      width: "200px",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      top: 160,
      width: "200px",
    },
    ["@media (min-width:1601px)"]: {
      top: 160,
      width: "200px",
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
  },
  scroingContentContainer: {
    // margin: "36px 0",
    // borderRight: "1px solid #214697",
  },
  reportLeftSideContainer: {
    borderRight: "1px solid #214697",
    margin: "14px 0",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      margin: "10px 0",
    },
    "@media (min-width:1367px) and (max-width:1442px)": {
      margin: "36px 0",
    },
  },
  feedbackText: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#000",
    paddingRight: "16px",
    "@media (max-width:1282px)": {
      font: "normal normal 400 16px/22px PoppinsMedium",
      margin: "10px 0",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
      marginTop: "10px",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
      marginTop: "10px",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
      marginTop: "10px",
    },
  },
  feedbackTextIndex: {
    color: "#626262",
    font: "normal normal 400 18px/24px PoppinsMedium",
    margin: "10px 0",
    paddingRight: "10px",
    textAlign: "right",
    "@media (max-width:1282px)": {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      margin: "16px 0",
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
      paddingRight: "10px",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
      paddingRight: "20px",
    },
  },
}));

export default function Screen7(props) {
  const classes = useStyles();

  const getScoreRange = (score) => {
    if (score > 0 && score <= 1) {
      return "0-1";
    } else if (score > 1 && score <= 2) {
      return "1-2";
    } else if (score > 2 && score <= 3) {
      return "2-3";
    } else if (score > 3 && score <= 4) {
      return "3-4";
    } else {
      return "4-5";
    }
  };

  return (
    <Grid
      container
      component="main"
      className={`${classes.reportContentContainer}`}
    >
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        className={`${classes.verticalHorizontalCenter} ${classes.reportLeftSideContainer}`}
      >
        <div
          className={`${classes.verticalHorizontalCenter} ${classes.scroingContentContainer} pos-rel`}
        >
          <h3 className={`${classes.scoreTitle} itTextColor`}>
            Your Innovation Thinking Score
          </h3>
          <h3 className={classes.scoreRangeText}>
            On a scale of 0-5, <br /> you are in{" "}
            {getScoreRange(props.reportData.itAvgScore)} range.
          </h3>
          <div className={classes.scroingContainer}>
            <SvgScoringSystemSM data={props.data}></SvgScoringSystemSM>
          </div>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item md={1} sm={1}></Grid>
            <Grid item md={10} sm={10}>
              <p className={`${classes.sectionSubTitle} itTextColor`}>
                {props.data.itLineCaption}
              </p>
              {props.data.itRecommendation1.map((item, index) => {
                return (
                  <p key={index} className={classes.paragraph}>
                    {item}
                  </p>
                );
              })}
            </Grid>
            <Grid item md={1} sm={1}></Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={7}
        component={Paper}
        elevation={9}
        className={classes.rightSideContentContainer}
      >
        <div>
          <Grid container>
            <Grid container>
              <Grid item xs={12} style={{ paddingLeft: "27px" }}>
                <h3 className={classes.sectionTitle}>
                  Specific Actions You Can Consider
                </h3>
              </Grid>
            </Grid>
            {props.data.itRecommendation.map((item, index) => {
              return (
                <Grid container>
                  <Grid item xs={1}>
                    <p className={classes.feedbackTextIndex}>0{index + 1}</p>
                  </Grid>
                  <Grid item xs={11}>
                    <p className={classes.feedbackText}>{item}</p>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
