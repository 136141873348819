/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "calc(100vh - 120px)",
    backgroundColor: "#fff",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    position: "relative",
    padding: "0 140px",
    "@media (max-width:1050px)": {
      padding: "0 104px",
    },
    "@media (min-width:1052px) and (max-width:1282px)": {
      padding: "0 108px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      padding: "0 104px",
    },
    "@media (min-width:1443px) and (max-width:1512px)": {
      padding: "0 104px",
    },
  },
  sectionTitle: {
    color: "#1D1D1B",
    font: "normal normal 500 44px/120px PoppinsSemiBold",
    margin: "16px 0 36px 0",
    "@media (max-width:1382px)": {
      font: "normal normal 500 26px/32px PoppinsSemiBold",
    },
    "@media (min-width:1383px) and (max-width:1600px)": {
      font: "normal normal 500 36px/64px PoppinsSemiBold",
    },
    ["@media (min-width:1602px)"]: {
      margin: "0",
    },
  },
  sectionSubTitle: {
    color: "#1D1D1B",
    font: "normal normal 500 24px/30px PoppinsSemiBold",
    marginBottom: 0,
    "@media (max-width:1282px)": {
      font: "normal normal 500 24px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 26px/36px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    "@media (max-width:1282px)": {},
    "@media (min-width:1283px) and (max-width:1442px)": {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1602px)"]: {
      font: "normal normal 400 26px/36px PoppinsMedium",
    },
  },
}));

export default function Screen2(props) {
  console.log("props", props.data.category_id);
  const classes = useStyles();

  return (
    <div className={`${classes.contentContainer}`}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <h4 className={classes.sectionSubTitle}>
            ETQ’s 3 Entrepreneurial Thinking (mindset) frameworks are:
          </h4>
          <h3 className={classes.sectionTitle}>
            <span class="htTextColor">Human Thinking</span>,{" "}
            <span class="itTextColor">Innovation Thinking</span> and{" "}
            <span class="rtTextColor">Risk Thinking</span>
          </h3>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <p className={classes.paragraph}>
              {props.data.category_id === 4 ? (
                <React.Fragment>
                  When you take the actions listed in this report and
                  consistently practice them you start to develop the right
                  mindset for the right outcomes – sustainable employability in
                  a commercial career.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  When you take the actions listed in this report and
                  consistently practice them you start to develop the right
                  mindset for the right outcomes – sustainable start-up
                  foundations; a sustainable self-employed revenue
                  and sustainable intra-preneur initiatives.
                </React.Fragment>
              )}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <p className={classes.paragraph}>
              {props.data.category_id === 4 ? (
                <React.Fragment>
                  Over time your brain triggers you towards the right thinking
                  and decision making to succeed in your search for the right
                  commercial career.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Over time your brain triggers you towards the right thinking
                  and decision making to run your start-up or new initiatives.
                </React.Fragment>
              )}
            </p>
            <p
              className={classes.paragraph}
              style={{ color: "#151515", fontFamily: "PoppinsSemiBold" }}
            >
              Learn how you can achieve this through this report.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
