import axios from 'axios';
import Constants from "./../constants/constants";

const getAllQuestions = async () => {
    try {
        const response = await axios.get(`${Constants.API_END_POINT}/get-first-question`, {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
        return response.data;
    } catch (error) {
        console.log("Error", error);
    }
}
const postSelectedAnswer = async (data) => {
    try {
        return await axios.post(`${Constants.API_END_POINT}/answer-submit`, data, {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
    } catch (error) {
        console.log("Error", error);
        if (error.response) {
            if (error.response.status === 401) {
                sessionStorage.setItem('isNotAuthorized', true);
            } else {
                console.log("network error");
            }
        }
    }
}

const getReportDetails = async () => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/get-report-details`, "null",  {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
        return response.data;
    } catch (error) {
        console.log("inside test service Error", error);
        if (error.response) {
            return error.response.data;
        }
    }
}

const postFeedback = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/report-feedback`, data,  {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
        return response.data;
    } catch (error) {
        console.log("Error", error);
    }
}

export { getAllQuestions, postSelectedAnswer, getReportDetails, postFeedback };