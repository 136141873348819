/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Warning from "./../../assets/images/warning-2x.png";
import info from "../../assets/images/info.svg";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "calc(100vh - 120px)",
    backgroundColor: "#fff",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    position: "relative",
    padding: "0 140px",
    "@media (max-width:1050px)": {
      padding: "0 104px",
    },
    "@media (min-width:1052px) and (max-width:1282px)": {
      padding: "0 108px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      padding: "0 104px",
    },
    "@media (min-width:1443px) and (max-width:1512px)": {
      padding: "0 104px",
    },
  },
  sectionTitle: {
    color: "#1D1D1B",
    font: "normal normal 800 49px/53px PoppinsExtraBold",
    margin: "16px 0 0px 0",
    "@media (max-width:1382px)": {
      font: "normal normal 800 26px/32px PoppinsExtraBold",
    },
    "@media (min-width:1383px) and (max-width:1600px)": {
      font: "normal normal 800 36px/64px PoppinsExtraBold",
    },
    ["@media (min-width:1602px)"]: {
      // margin: "0",
    },
  },
  // sectionSubTitle: {
  //   color: "#1D1D1B",
  //   font: "normal normal 500 24px/30px PoppinsSemiBold",
  //   marginBottom: 0,
  //   "@media (max-width:1282px)": {
  //     font: "normal normal 500 24px/30px PoppinsSemiBold",
  //   },
  //   ["@media (min-width:1443px)"]: {
  //     font: "normal normal 500 26px/36px PoppinsSemiBold",
  //   },
  // },

  sectionSubTitle: {
    color: "#000",
    font: "normal normal 600 16px/25px PoppinsSemiBold",
    margin: "10px 0",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/20px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 400 28px/46px PoppinsMedium",
    color: "#000",
    padding: "0 14px 0 0px",
    "@media (max-width:1282px)": {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1602px)"]: {
      font: "normal normal 400 28px/46px PoppinsMedium",
    },
  },
  warningIcon: {
    width: "130px",
    height: "130px",
    "@media (max-width:1282px)": {
      width: "60px",
      height: "60px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      width: "80px",
      height: "80px",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      width: "80px",
      height: "80px",
    },
    ["@media (min-width:1602px)"]: {
      width: "130px",
      height: "130px",
    },
  },

  infoLink: {
    backgroundColor: "#71BC40",
    color: "#fff",
    borderRadius: "7px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    width: "fit-content",
  },
  infoIcon: {
    width: "auto",
    height: "auto",
    marginRight: "6px",
  },
}));

export default function Screen2Pre(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.contentContainer}`}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <img
            src={Warning}
            alt="warning icon"
            className={classes.warningIcon}
          />
          <h3 className={classes.sectionTitle}>
            <span style={{ color: "#BF2430" }}>
              BE PATIENT. READ AND UNDERSTAND{" "}
            </span>
            <span style={{ fontFamily: "PoppinsMedium" }}>
              THE REPORT CAREFULLY.
            </span>
          </h3>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <p className={classes.paragraph}>
              Everything is well explained in the report. Take the time to read
              it thoroughly and focus on understanding it. This will provide a
              much better understanding of your score and list of actions.
            </p>
            <a
              href="https://etqglobal.solutions/images/questor_briefing_document.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.infoLink} elementToFadeInAndOut`}
            >
              <img src={info} alt="infoIcon" className={classes.infoIcon} />
              {/* <img
                    src={questorTitle}
                    alt="questorTitle_icon"
                    className={classes.questorTitle}
                  /> */}
              <p
                className={`${classes.sectionSubTitle} infoText`}
                style={{ display: "inline", color: "#fff" }}
              >
                Read this before going into the Report
              </p>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
