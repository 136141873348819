/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import questorScoringDemo from "./../../assets/images/report/questor-scoring-demo.mp4";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    display: "flex",
    textAlign: "left",
  },
  questorTitle: {
    marginLeft: "10px",
  },
  sectionTitle: {
    color: "#151515",
    font: "normal normal 500 21px/30px PoppinsSemiBold",
    margin: "36px 0 18px 0",
    textAlign: "left",

    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 18px/26px PoppinsSemiBold",
      margin: "12px 0",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 18px/26px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1600px)"]: {
      font: "normal normal 500 24px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1602px)"]: {
      font: "normal normal 500 26px/34px PoppinsSemiBold",
      margin: 0,
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 600 14px/20px PoppinsSemiBold",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/20px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#000",
    padding: "0 14px 0 0px",
    textAlign: "left",
    "@media (max-width:1282px)": {
      font: "normal normal 400 14px/22px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1602px)"]: {
      font: "normal normal 500 22px/30px PoppinsSemiBold",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  feedbackText: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#000",
    "@media (max-width:1282px)": {
      font: "normal normal 400 16px/22px PoppinsMedium",
      margin: "10px 0",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1602px)"]: {
      font: "normal normal 400 22px/30px PoppinsMedium",
    },
  },
  video: {
    width: "800px",
    height: "800px",
    "@media (max-width:1282px)": {
      height: "450px",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      height: "500px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      height: "600px",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      height: "650px",
    },
  },
}));

export default function Screen3(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      component="main"
      className={`${classes.reportContentContainer}`}
    >
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        className={classes.verticalHorizontalCenter}
      >
        <div className={classes.verticalHorizontalCenter}>
          <video
            loop
            src={questorScoringDemo}
            className={classes.video}
            autoplay="true"
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={7}
        component={Paper}
        elevation={9}
        className={classes.rightSideContentContainer}
      >
        <Grid container className={classes.verticalHorizontalCenter}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h3 className={classes.sectionTitle}>
              Assess Your Proximity to Your <br /> Entrepreneurial Equilibrium:
            </h3>
            <p className={classes.paragraph}>
              The better you perform on <span class="htTextColor"> Human</span>,{" "}
              <span class="itTextColor"> Innovation </span> and{" "}
              <span class="rtTextColor"> Risk </span> Thinking, the closer you
              will be to achieving Entrepreneurial Equilibrium. This is visually
              shown as the central cube in the diagram where all three axes
              intersect.
            </p>
            <h3 className={classes.sectionTitle}>Defining Your Potential</h3>
            {props.data.category_id === 1 ? (
              <React.Fragment>
                <p className={classes.feedbackText}>
                  0 to &lt;2 - Pre-Incubation Stage
                </p>
                <p className={classes.feedbackText}>
                  2 to &lt;3 - Incubation Required{" "}
                </p>
                <p className={classes.feedbackText}>
                  3 to &lt;4 - Early Entrepreneurial Potential (Training
                  Required)
                </p>
                <p className={classes.feedbackText}>
                  4 to &lt;5 - High Entrepreneurial Potential
                </p>
              </React.Fragment>
            ) : props.data.category_id === 4 ? (
              <React.Fragment>
                <p className={classes.feedbackText}>
                  0 to &lt;2 - Mindset change required
                </p>
                <p className={classes.feedbackText}>
                  2 to &lt;3 - Training required{" "}
                </p>
                <p className={classes.feedbackText}>
                  3 to &lt;4 - Early potential for commercial role
                </p>
                <p className={classes.feedbackText}>
                  4 to &lt;5 - Ready for commercial role
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className={classes.feedbackText}>
                  0 to &lt;2 - Mindset change required
                </p>
                <p className={classes.feedbackText}>
                  2 to &lt;3 - Training required{" "}
                </p>
                <p className={classes.feedbackText}>
                  3 to &lt;4 - Early potential for commercial role
                </p>
                <p className={classes.feedbackText}>
                  4 to &lt;5 - Ready for high performance commercial role
                </p>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
