/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import Otpinput from "../common/otpInput";
import Copyright from "../common/Copyright";
import CircularProgressBar from "../common/CircularProgressBar";
import validator from "validator";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  inputField: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: 40,
  },
  textField: {
    background: "#626262 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    opacity: 1,
    height: "40px",
    width: "100%",
    color: "#fff",
    margin: "0 0 1rem",
    paddingLeft: 12,
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
  },
  loginForm: {
    // width: "70%",
    // ["@media (max-width:680px)"]: {
    //   width: "100%",
    // },
  },
  link: {
    color: "#ffcd05",
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "28px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    cursor: "pointer",
  },
  title: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "24px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    letterSpacing: "0px",
    color: "#FFFFFF",
    margin: "0.5rem 0 1rem 0",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "16%",
      width: 6,
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    width: "100%",
    ["@media (max-width:650px)"]: {
      padding: "3rem 2rem 3rem",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
  loginBtnXsContainer: {
    ["@media (max-width:480px)"]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  loginXsBtn: {
    ["@media (max-width:480px)"]: {
      margin: "0.5rem 0 1.5rem",
    },
  },
  primaryButton: {
    background: "#ffcd05 0% 0% no-repeat",
    borderRadius: 23,
    opacity: 1,
    color: "#1D1D1B",
    "&:hover, &:focus": {
      background: "#ffcd05 0% 0% no-repeat padding-box",
    },
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "12px 23px",
  },
}));

export default function RegisterOTP(props) {
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [loader, showLoader] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const history = useHistory();

  const onClickAssesment = () => {
    if (userMobile === "" || userMobile === null) {
      setMobileErrorMessage("Mobile number field can not be empty.");
    }

    if (validator.isMobilePhone(userMobile)) {
      setMobileErrorMessage("");
      let formData = {
        countryCode: "+91",
        phoneNumber: "6361424158",
        callbackData: "Hi Mahesh",
        type: "Template",
        template: {
          name: "browse_catalog_on_whatsapp",
          languageCode: "en",
          bodyValues: ["11"],
        },
      };
      dispatch({
        type: "USER_REGISTER_OTP_REQUEST",
        payload: formData,
      });
    } else {
      setMobileErrorMessage("Enter a valid mobile number!");
    }
  };

  const handleUserInput = (e) => {
    if (e.target.name === "phone") {
      if (e.target.value === "" || e.target.value == null) {
        setMobileErrorMessage("Mobile number can not be empty.");
      } else {
        setUserMobile(e.target.value);
        setMobileErrorMessage("");
        if (userMobile.length >= 7) {
          setEnableButton(true);
        } else {
          setEnableButton(false);
        }
      }
    }

    if (e.target.name === "countrycodes") {
      setCountryCode(e.target.value);
    }
  };

  useEffect(() => {
    showLoader(authReducer.isLoading);
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoading]);

  const handleVerifyCode = (code) => {
    let reg_code = "";
    for (const key in code) {
      if (Object.hasOwnProperty.call(code, key)) {
        reg_code += code[key];
      }
    }

    // setRegistrationCode(reg_code); //Save Registration code for registration
    // dispatch({
    //   type: Types.REG_CODE_VERIFY_REQUEST,
    //   payload: { authorization_code: reg_code },
    // });
  };

  return (
    <div>
      <Grid
        container
        component="main"
        className={`${classes.root} iphoneView `}
      >
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Register
                </Typography>
                <Typography className={classes.caption}>
                  Via OTP Verification
                </Typography>
                <form
                  className={classes.loginForm}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={3} md={3}>
                      <select
                        id="countrycodes"
                        name="countrycodes"
                        className={`${classes.textField} customTextField`}
                        style={{
                          backgroundColor: "#252525",
                          border: "1px solid #000",
                        }}
                        onChange={(event) => handleUserInput(event)}
                      >
                        <option value="+91">+91</option>
                        <option value="+65">+65</option>
                        <option value="+84">+84</option>
                      </select>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter your mobile number"
                        className={`${classes.textField} customTextField OTPMobileNumber`}
                        onChange={(event) => handleUserInput(event)}
                        required
                      />
                    </Grid>
                    <div className={classes.errorMessage}>
                      {" "}
                      {mobileErrorMessage}
                    </div>
                  </Grid>
                  <br />

                  {/* <Otpinput verifyCode={handleVerifyCode} /> */}
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <Button
                        color="inherit"
                        className={
                          "primaryButton" +
                          (enableButton ? " enableOTPBtn " : " disableOTPBtn")
                        }
                        onClick={onClickAssesment}
                      >
                        Get OTP
                      </Button>
                      <span style={{ marginLeft: "0.6rem" }}>
                        {loader ? <CircularProgressBar /> : null}
                      </span>
                    </Grid>
                  </Grid>
                </form>
                <p style={{ color: "red" }}>{authReducer.errorMessage}</p>
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <Copyright></Copyright>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <div className={`${classes.copyRight} hide-iphone`}>
              <Copyright></Copyright>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
