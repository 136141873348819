/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button } from "@material-ui/core";

import logo from "../../assets/images/report/etq-logo.png";
import info from "../../assets/images/info.svg";
import questorTitle from "../../assets/images/questor-title-dark.png";
import CircularProgressBar from "../common/CircularProgressBar";

import nextArrowBlack from "../../assets/images/next-arrow-black.svg";
import prevArrowBlack from "../../assets/images/prev-arrow-gray.svg";

import { Types } from "../store/Types";
import CustomizedTooltips from "../common/Tooltip";
import Screen2 from "./Screen2";
import Screen5 from "./Screen5";
import Screen4 from "./Screen4";
import Screen10 from "./Screen10";
import Screen3 from "./Screen3";
import Screen1 from "./Screen1";
import Screen6 from "./Screen6";
import Screen7 from "./Screen7";
import Screen9 from "./Screen9";
import Screen8 from "./Screen8";
import Screen11 from "./Screen11";
import Screen13 from "./Screen13";
import Screen2Pre from "./Screen2-pre";

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    position: "relative",
    height: "99.7vh",
  },
  headerContainer: {
    height: "60px",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  footerContainer: {
    display: "block",
    height: "60px",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  title: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0",
    flex: "auto",
  },
  logo: {
    maxWidth: "79px",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    height: "60px",
    backgroundColor: "#fff",
    boxShadow: "0px 5px 12px #00000017",
    ["@media (max-width:480px)"]: {
      height: "48px",
    },
    position: "relative",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "60px",
    backgroundColor: "#F5F5F5",
    boxShadow: "0px 5px 12px #00000017",
    ["@media (max-width:480px)"]: {
      height: "48px",
    },
  },
  footerToolbar: {
    paddingRight: 0,
  },
  darkHeader: {
    backgroundColor: "#2B2C2E",
    boxShadow: "0px 5px 12px #fffcfc17",
  },
  userName: {
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    marginRight: 30,
    display: "inline-grid",
    color: "#000",
    textAlign: "right",
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 400 18px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 500 22px/22px PoppinsMedium",
    },
  },
  darkUserName: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    marginRight: 30,
    display: "inline-grid",
    textAlign: "right",
  },
  questorTitle: {
    marginLeft: "10px",
  },
  lightThemeProfileText: {
    color: "#626262",
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "PoppinsMedium",
    fontWeight: 400,
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 500 14px/18px PoppinsMedium",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 500 14px/18px PoppinsMedium",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 500 16px/20px PoppinsMedium",
    },
  },
  sectionTitle: {
    color: "#BE2431",
    font: "normal normal 300 44px/120px PoppinsSemiBold",
    margin: "36px 0",
    ["@media (max-width:1382px)"]: {
      font: "normal normal 300 30px/48px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 300 36px/48px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 600 16px/25px PoppinsSemiBold",
    margin: "10px 0",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/20px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 500 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
    },
  },
  progressBar: {
    opacity: 1,
    height: "10px",
    width: "100%",
    position: "absolute",
    bottom: "0",
    display: "block",
    zIndex: 999,
    accentColor: "#6FBC44",
  },
  infoLink: {
    backgroundColor: "#71BC40",
    color: "#fff",
    borderRadius: "7px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  infoIcon: {
    width: "auto",
    height: "auto",
    marginRight: "6px",
  },
}));

export default function Report(props) {
  const classes = useStyles();
  const [loader, showLoader] = useState(false);
  const [screenCount, setScreenCount] = useState(1);
  const [showFeedbackForm, setshowFeedbackForm] = useState(false);
  const [progressBarCount, setprogressBarCount] = useState(9);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const AuthReducer = useSelector((state) => state.authReducer);
  const TestReducer = useSelector((state) => state.TestReducer);
  const history = useHistory();
  const userInfo = AuthReducer.userInfo.data;
  const ReportDetailsContent = useSelector(
    (state) => state.TestReducer.reportDetails.data
  );

  const handleNextClick = () => {
    if (screenCount === 11) {
      setshowFeedbackForm(true);
      document.getElementsByClassName(
        "animated-drawer-container"
      )[0].style.display = "block";
      document.getElementById("progressBar").style.display = "none";
      document.getElementById("header").style.position = "static";
    } else {
      setScreenCount(screenCount + 1);
      setprogressBarCount(progressBarCount + 8);
    }
  };

  const handlePrevClick = () => {
    setScreenCount(screenCount - 1);
    setprogressBarCount(progressBarCount - 8);
  };

  const handleExitClick = () => {
    dispatch({
      type: Types.GET_USER_INFO,
    });
    history.push("/welcome");
  };

  const getNextBtnClass = () => {
    return TestReducer.selectedTheme === "default"
      ? "primaryQuestionBtn"
      : "nextQuestionBtn";
  };

  useEffect(() => {
    showLoader(authReducer.isLoading);
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoading]);

  useEffect(() => {
    if (TestReducer.errorMessage.status === 401) {
      history.push("/login");
    }
    return () => {
      console.log("clean up");
    };
  }, [TestReducer.errorMessage]);

  const goToHome = () => {
    history.push("/welcome");
  };

  const handleLogout = () => {
    dispatch({
      type: Types.USER_LOGOUT_REQUEST,
    });
    dispatch({
      type: Types.SET_THEME,
      payload: false,
    });
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("useremail");
    history.push("/login");
  };

  useEffect(() => {
    dispatch({
      type: Types.GET_REPORT_DETAILS,
    });
    return () => {
      console.log("returned");
    };
  }, []);

  const HTReportDetailsContent = {
    ...ReportDetailsContent,
    itAvgScore: 0,
    rtAvgScore: 0,
  };
  const ITReportDetailsContent = {
    ...ReportDetailsContent,
    htAvgScore: 0,
    rtAvgScore: 0,
  };
  const RTReportDetailsContent = {
    ...ReportDetailsContent,
    htAvgScore: 0,
    itAvgScore: 0,
  };

  const closeSelectedOutcomeDescription = (type) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
    document.getElementById("progressBar").style.display = "block";
    document.getElementById("header").style.position = "relative";
    setScreenCount(screenCount + 1);
    setprogressBarCount(progressBarCount + 9);
  };

  const handleFeebackSubmit = (feedback) => {
    dispatch({
      type: Types.POST_FEEDBACK,
      payload: feedback,
    });
    setScreenCount(screenCount + 1);
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
    setprogressBarCount(progressBarCount + 9);

    document.getElementById("progressBar").style.display = "block";
    document.getElementById("header").style.position = "relative";
  };

  const getAnimationClassName = (count) => {
    if (count === 1 || count === 2) {
      return "elementToFadeInAndOut";
    } else if(count === 12) {
      return "hideInfoButton";
   
    } else {
      return "elementToFadeIn";
    }
  };

  return (
    <div>
      {ReportDetailsContent ? (
        <div className={classes.reportContainer}>
          <div className={classes.headerContainer}>
            <AppBar
              position="static"
              className={`${classes.header} ${classes.header}`}
              id="header"
            >
              <Toolbar
                className={
                  TestReducer.selectedTheme === "default"
                    ? "defaultHeader"
                    : "headerShadow"
                }
              >
                <img
                  src={logo}
                  alt="logo"
                  className={classes.logo}
                  onClick={goToHome}
                />

                <Typography variant="h6" className={classes.title}></Typography>
                {AuthReducer.isLoggedIn ? (
                  <React.Fragment>
                    {userInfo ? (
                      <React.Fragment>
                        <span className={classes.userName}>
                          {userInfo.firstName} {userInfo.lastName} <br />
                          <a
                            href="javascript:void(0)"
                            style={{ color: "#626262" }}
                            onClick={handleLogout}
                          >
                            <span className={classes.lightThemeProfileText}>
                              Logout
                            </span>
                          </a>
                        </span>
                        <CustomizedTooltips userInfo={userInfo} />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
              </Toolbar>
            </AppBar>
          </div>

          <div className="pos-rel">
            {screenCount === 1 ? <Screen1 /> : null}

            {screenCount === 2 ? <Screen2Pre /> : null}

            {screenCount === 3 ? <Screen2 data={ReportDetailsContent}  /> : null}

            {screenCount === 4 ? <Screen3 data={ReportDetailsContent} /> : null}

            {screenCount === 5 ? <Screen4 data={ReportDetailsContent} /> : null}

            {screenCount === 6 ? (
              <Screen5
                data={HTReportDetailsContent}
                reportData={ReportDetailsContent}
              />
            ) : null}

            {screenCount === 7 ? <Screen6 data={ReportDetailsContent} /> : null}

            {screenCount === 8 ? (
              <Screen7
                data={ITReportDetailsContent}
                reportData={ReportDetailsContent}
              />
            ) : null}

            {screenCount === 9 ? <Screen8 data={ReportDetailsContent} /> : null}

            {screenCount === 10 ? (
              <Screen9
                data={RTReportDetailsContent}
                reportData={ReportDetailsContent}
              />
            ) : null}

            {screenCount === 11 ? (
              <Screen10 ReportDetailsContent={ReportDetailsContent} />
            ) : null}

            {screenCount === 12 ? <Screen11 /> : null}

            <div className={classes.progressBarContainer}>
              <progress
                className={classes.progressBar}
                id="progressBar"
                value={progressBarCount}
                max="100"
              >
                32%
              </progress>
            </div>
          </div>
          <div className={classes.footerContainer}>
            <AppBar position="static" className={`${classes.footer}`}>
              <Toolbar className={`${classes.footerToolbar}`}>
                {screenCount === 2 ? null : (
                  <a
                    href="https://etqglobal.solutions/images/questor_briefing_document.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    // className={`${classes.infoLink}  elementToFadeInAndOut`}
                    className={`${classes.infoLink} ${getAnimationClassName(
                      screenCount
                    )}`}
                  >
                    <img
                      src={info}
                      alt="infoIcon"
                      className={classes.infoIcon}
                    />
                    <p
                      className={`${classes.sectionSubTitle} infoText`}
                      style={{ display: "inline", color: "#fff" }}
                    >
                      Read this before going into the Report
                    </p>
                  </a>
                )}

                <Typography variant="h6" className={classes.title}></Typography>

                <div>
                  {screenCount > 1 ? (
                    <Button
                      className="prevScreenbutton"
                      onClick={handlePrevClick}
                    >
                      <span style={{ display: "inline-flex" }}>
                        <img
                          style={{
                            marginRight: "5px",
                            height: "12px",
                          }}
                          src={prevArrowBlack}
                          alt="prevArrowBlack"
                        />
                      </span>
                      Back
                    </Button>
                  ) : null}
                </div>
                <div className={classes.prevButton}>
                  {screenCount > 11 ? (
                    <Button
                      className={getNextBtnClass()}
                      onClick={handleExitClick}
                    >
                      Exit{" "}
                      <span style={{ display: "flex" }}>
                        <img
                          style={{
                            marginLeft: "5px",
                            height: "12px",
                          }}
                          src={nextArrowBlack}
                          alt="arrowNextBlack"
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className={getNextBtnClass()}
                      onClick={handleNextClick}
                    >
                      Next{" "}
                      <span style={{ display: "flex" }}>
                        <img
                          style={{
                            marginLeft: "5px",
                            height: "12px",
                          }}
                          src={nextArrowBlack}
                          alt="arrowNextBlack"
                        />
                      </span>
                    </Button>
                  )}
                </div>
              </Toolbar>
            </AppBar>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgressBar />
        </div>
      )}

      <div class="animated-drawer-container">
        {showFeedbackForm === true ? (
          <Screen13
            animatedDrawerType={closeSelectedOutcomeDescription}
            feedbackSubmit={handleFeebackSubmit}
            data={ReportDetailsContent}
          ></Screen13>
        ) : null}
      </div>
    </div>
  );
}
