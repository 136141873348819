import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { Types } from "./../Types";
import { userLogin, userRegister, resetPassword, changePassword, userInfo, emailConfirm, registerCodeVerify, emailVerify, forceResetPassword, userRegisterOTP, userLoginOTP, userVerifyOTP } from '../../../utils/services/auth-service';

const response = {
    data: {
        message: "Network error occured, please try again later."
    }
};

function* userLoginAsync(action) {
    try {
        yield put({ type: Types.USER_LOGIN_REQUEST_ASYNC });
        const response = yield call(userLogin, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.USER_LOGIN_RESPONSE, response });
        } else {
            yield put({ type: Types.USER_LOGIN_ERROR, response })
        }
    } catch (error) {
        yield put({ type: Types.USER_LOGIN_ERROR, response });
        console.log("error", error);
    }
}

function* userRegisterAsync(action) {
    try {
        yield put({ type: Types.USER_REGISTER_REQUEST_ASYNC });
        const response = yield call(userRegister, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.USER_REGISTER_RESPONSE, response });
        } else {
            yield put({ type: Types.USER_REGISTER_ERROR, response });
            throw response;
        }
    } catch (e) {
        yield put({ type: Types.USER_REGISTER_ERROR, response });
        console.log("Error", e)
    }
}

function* emailVefiryAsync(action) {
    try {
        yield put({ type: Types.EMAIL_VERIFY_REQUEST_ASYNC });
        const response = yield call(emailVerify, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.EMAIL_VERIFY_RESPONSE, response });
        } else {
            yield put({ type: Types.EMAIL_VERIFY_ERROR, response });
            throw response;
        }
    } catch (e) {
        yield put({ type: Types.EMAIL_VERIFY_ERROR, response });
        console.log("Error", e);
    }
}

function* regCodeVerifyAsync(action) {
    try {
        yield put({ type: Types.REG_CODE_VERIFY_REQUEST_ASYNC });
        const response = yield call(registerCodeVerify, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.REG_CODE_VERIFY_RESPONSE, response });
        } else {
            yield put({ type: Types.REG_CODE_VERIFY_ERROR, response });
            yield delay(2000);
            yield put({ type: 'REMOVE_ERROR_MESSAGE' });
        }
    } catch (e) {
        yield put({ type: Types.REG_CODE_VERIFY_ERROR, response });
        console.log("Error", e)
    }
}

function* watchForgotPasswordAsync(action) {
    try {
        yield put({ type: Types.RESET_PASSWORD_REQUEST_ASYNC });
        const response = yield call(resetPassword, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.RESET_PASSWORD_RESPONSE, response })
        } else {
            yield put({ type: Types.RESET_PASSWORD_ERROR, response })
        }
    } catch (error) {
        yield put({ type: Types.RESET_PASSWORD_ERROR, response });
        console.log("error", error);
    }
}

function* watchChangePasswordAsync(action) {
    try {
        yield put({ type: Types.CHANGE_PASSWORD_REQUEST_ASYNC });
        const response = yield call(changePassword, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.CHANGE_PASSWORD_RESPONSE, response })
        } else {
            yield put({ type: Types.CHANGE_PASSWORD_ERROR, response })
        }
    } catch (error) {
        yield put({ type: Types.CHANGE_PASSWORD_ERROR, response });
        console.log("error", error);
    }
}

function* watchEmailConfirmationAsync(action) {
    try {
        yield put({ type: Types.EMAIL_CONFIRM_REQUEST_ASYNC });
        const response = yield call(emailConfirm, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.EMAIL_CONFIRM_RESPONSE, response })
        } else {
            yield put({ type: Types.EMAIL_CONFIRM_ERROR, response })
        }
    } catch (error) {
        yield put({ type: Types.EMAIL_CONFIRM_ERROR, response });
        console.log("error", error);
    }
}

function* openLoginScreenAsync(action) {
    try {
        yield put({ type: Types.OPEN_LOGIN_SCREEN_ASYNC });
    } catch (error) {
        console.log("error", error);
    }
}

function* getUserInformationAsync(action) {
    try {
        yield put({ type: Types.GET_USER_INFO_ASYNC });
        const response = yield call(userInfo);
        if (response && response.status === 200) {
            yield put({ type: Types.GET_USER_INFO_RESPONSE, response });
        } else {
            yield put({ type: Types.GET_USER_INFO_ERROR, response });
        }
    } catch (error) {
        yield put({ type: Types.GET_USER_INFO_ERROR, response });
    }
}

function* goToLoginScreenAsync(action) {
    try {
        yield put({ type: Types.GO_TO_LOGIN_SCREEN_ASYNC });

    } catch (error) {
        console.log("error", error);
    }
}

function* userLogoutAsync(action) {
    try {
        yield put({ type: Types.USER_LOGOUT_REQUEST_ASYNC });

    } catch (error) {
        console.log("error", error);
    }
}

function* userExistsAsync(action) {
    try {
        yield put({ type: Types.USER_EXIST_ASYNC });

    } catch (error) {
        console.log("error", error);
    }
}

function* mouseEnterEventAsync(action) {
    try {
        yield put({ type: Types.MOUSE_ENTER_EVENT_ASYNC });

    } catch (error) {
        console.log("error", error);
    }
}

function* mouseLeaveEventAsync(action) {
    try {
        yield put({ type: Types.MOUSE_LEAVE_EVENT_ASYNC });

    } catch (error) {
        console.log("error", error);
    }
}

function* themeSelectionAsync(action) {
    let selectedTheme = action.payload;
    try {
        yield put({ type: Types.THEME_SELECTION_ASYNC, selectedTheme });

    } catch (error) {
        console.log("error", error);
    }
}

function* watchForceResetPasswordAsync(action) {
    try {
        yield put({ type: Types.FORCE_RESET_PASSWORD_REQUEST_ASYNC });
        const response = yield call(forceResetPassword, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.FORCE_RESET_PASSWORD_RESPONSE, response })
        } else {
            yield put({ type: Types.FORCE_RESET_PASSWORD_ERROR, response })
        }
    } catch (error) {
        yield put({ type: Types.FORCE_RESET_PASSWORD_ERROR, response });
    }
}

function* watchRegisterOTPAsync(action) {
    try {
        yield put({ type: Types.USER_REGISTER_OTP_REQUEST_ASYNC });
        const response = yield call(userRegisterOTP, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.USER_REGISTER_OTP_RESPONSE, response });
        } else {
            yield put({ type: Types.USER_REGISTER_OTP_ERROR, response });
            throw response;
        }
    } catch (e) {
        yield put({ type: Types.USER_REGISTER_OTP_ERROR, response });
    }
}

function* watchLoginOTPAsync(action) {
    try {
        yield put({ type: Types.USER_LOGIN_OTP_REQUEST_ASYNC });
        const response = yield call(userLoginOTP, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.USER_LOGIN_OTP_RESPONSE, response });
        } else {
            yield put({ type: Types.USER_LOGIN_OTP_ERROR, response });
            throw response;
        }
    } catch (e) {
        yield put({ type: Types.USER_LOGIN_OTP_ERROR, response });
    }
}

function* watchVerifyLoginOTPAsync(action) {
    try {
        yield put({ type: Types.VERIFY_LOGIN_OTP_REQUEST_ASYNC });
        const response = yield call(userVerifyOTP, action.payload);
        if (response && response.status === 200) {
            yield put({ type: Types.VERIFY_LOGIN_OTP_RESPONSE, response });
        } else {
            yield put({ type: Types.VERIFY_LOGIN_OTP_ERROR, response });
            throw response;
        }
    } catch (e) {
        // yield put({ type: Types.VERIFY_LOGIN_OTP_ERROR, response });
        console.log(e);
    }
}

export function* watchUserLogin(data) {
    yield takeLatest(Types.USER_LOGIN_REQUEST, userLoginAsync);
}

export function* watchOpenLoginScreen(data) {
    yield takeLatest(Types.OPEN_LOGIN_SCREEN, openLoginScreenAsync);
}

export function* watchUserLogout(data) {
    yield takeLatest(Types.USER_LOGOUT_REQUEST, userLogoutAsync);
}

export function* watchForgotPassword(data) {
    yield takeLatest(Types.RESET_PASSWORD_REQUEST, watchForgotPasswordAsync);
}

export function* watchChangePassword(data) {
    yield takeLatest(Types.CHANGE_PASSWORD_REQUEST, watchChangePasswordAsync);
}

export function* watchEmailConfirmation(data) {
    yield takeLatest(Types.EMAIL_CONFIRM_REQUEST, watchEmailConfirmationAsync);
}

export function* watchGetUserInformation(data) {
    yield takeLatest(Types.GET_USER_INFO, getUserInformationAsync);
}

export function* watchGoToLoginScreen(data) {
    yield takeLatest(Types.GO_TO_LOGIN_SCREEN, goToLoginScreenAsync);
}

export function* watchUserExists(data) {
    yield takeLatest(Types.USER_EXIST, userExistsAsync);
}

export function* watchMouseEnterEvent(data) {
    yield takeLatest(Types.MOUSE_ENTER_EVENT, mouseEnterEventAsync);
}

export function* watchMouseLeaveEvent(data) {
    yield takeLatest(Types.MOUSE_LEAVE_EVENT, mouseLeaveEventAsync);
}

export function* watchThemeSelection(data) {
    yield takeLatest(Types.THEME_SELECTION, themeSelectionAsync);
}

export function* watchRegCodeVerify(data) {
    yield takeLatest(Types.REG_CODE_VERIFY_REQUEST, regCodeVerifyAsync);
}

export function* watchUserRegister(data) {
    yield takeLatest(Types.USER_REGISTER_REQUEST, userRegisterAsync);
}

export function* watchEmailVefiry(data) {
    yield takeLatest(Types.EMAIL_VERIFY_REQUEST, emailVefiryAsync);
}

export function* watchForceResetPassword(data) {
    yield takeLatest(Types.FORCE_RESET_PASSWORD_REQUEST, watchForceResetPasswordAsync);
}

export function* watchRegisterOTP(data) {
    yield takeLatest(Types.USER_REGISTER_OTP_REQUEST, watchRegisterOTPAsync);
}

export function* watchLoginOTP(data) {
    yield takeLatest(Types.USER_LOGIN_OTP_REQUEST, watchLoginOTPAsync);
}

export function* watchVerifyLoginOTP(data) {
    yield takeLatest(Types.VERIFY_LOGIN_OTP_REQUEST, watchVerifyLoginOTPAsync);
}