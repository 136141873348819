/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SvgScoringSystem from "../common/SvgScoringSystem";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  scoreContainer: {
    width: "350px",
    height: "auto",
    margin: "0 auto",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "380px",
      height: "380px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      width: "520px",
      height: "520px",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      width: "520px",
      height: "520px",
    },
    ["@media (min-width:1601px)"]: {
      width: "700px",
      height: "610px",
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    textAlign: "center",

    ["@media (max-width:1050px) and (min-height: 700px) and (max-height: 800px)"]:
      {
        alignItems: "center",
        display: "flex",
      },
  },
  sectionTitle: {
    font: "normal normal 500 26px/30px PoppinsSemiBold",
    margin: "36px 0",
    ["@media (max-width:1024px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
      margin: "10px 0",
    },
    ["@media (min-width:1025px) and (max-width:1282px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
      margin: "16px 0",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1582px)"]: {
      font: "normal normal 500 26px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 32px/38px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 500 14px/20px PoppinsSemiBold",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 14px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 16px/28px PoppinsSemiBold",
    },
    ["@media (min-width:1366px) and (max-width:1442px)"]: {
      font: "normal normal 500 21px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 21px/30px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 400 18px/30px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    "@media (max-width:1282px)": {
      font: "normal normal 500 16px/24px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/20px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  innovationTitle: {
    // width: "100px",
    textAlign: "left",
    font: "normal normal 500 20px/24px PoppinsSemiBold",
    color: "#214697",
    ["@media (max-width:1024px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width: 1025px) and (max-width:1282px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1337px) and (max-width:1442px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
      marginLeft: "10px",
    },
  },
  humanTitle: {
    font: "normal normal 500 20px/24px PoppinsSemiBold",
    color: "#BE2431",
    textAlign: "left",
    width: "110px",
    ["@media (max-width:1024px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
      width: "80px",
    },
    ["@media (min-width: 1025px) and (max-width:1337px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1338px) and (max-width:1442px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
      width: "110px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
      width: "140px",
      paddingLeft: "6px",
    },
  },
  riskTitle: {
    // width: "100px",
    font: "normal normal 500 20px/24px PoppinsSemiBold",
    color: "#FFCD05",
    ["@media (max-width:1024px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width: 1025px) and (max-width:1282px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1337px) and (max-width:1442px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 20px/24px PoppinsSemiBold",
    },
  },

  reportLeftSideContainer: {
    borderRight: "1px solid rgb(111, 188, 68)",
    margin: "14px 0",
    display: "flex",
    "@media (min-width:1283px) and (max-width:1442px)": {
      margin: "36px 0",
    },
    ["@media (min-width:1443px)"]: {
      margin: "48px 0",
    },
  },
}));

export default function Screen10(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      component="main"
      className={`${classes.reportContentContainer}`}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        className={classes.reportLeftSideContainer}
      >
        <div className={classes.verticalHorizontalCenter}>
          <Grid container>
            <Grid item md={10} sm={10}>
              <h3 className={`${classes.sectionTitle} greenColor`}>Your ETQ</h3>
              <p className={`${classes.paragraph} greenColor`}>
                You’re almost done!
              </p>
              <p className={`${classes.paragraph}`}>
                Here is an overview of your performance. This combined score
                shows how close you are to your entrepreneurial equilibrium
                (central cube).
              </p>
              <h3 className={classes.sectionTitle}>The Next Step</h3>
              <p className={classes.paragraph}>
                Repeat <b>questor learning</b> in 3 months to track your progress across
                our Human, Innovation and Risk Thinking frameworks.
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        component={Paper}
        elevation={9}
        className={classes.rightSideContentContainer}
      >
        <div>
          <Grid container className={classes.reportCustomContainer}>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <div>
                <p className={classes.humanTitle}>
                  {props.ReportDetailsContent &&
                    props.ReportDetailsContent.htLineCaption}
                  {/* Early entrepreneurial potential - training required */}
                </p>
              </div>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className="pos-rel">
              <div>
                <p
                  className={classes.riskTitle}
                  style={{ textAlign: "center" }}
                >
                  {props.ReportDetailsContent &&
                    props.ReportDetailsContent.rtLineCaption}
                </p>
              </div>
              <div className={classes.scoreContainer}>
                <SvgScoringSystem
                  data={props.ReportDetailsContent}
                ></SvgScoringSystem>
              </div>

              <div class="resultLineContainer">
                <div class="vl"></div>
                <p
                  className={classes.sectionSubTitle}
                  style={{ margin: "0 0 0 -50px" }}
                >
                  Entrepreneurial <br /> Equilibrium
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0",
                margin: "0",
              }}
            >
              <div>
                <p className={classes.innovationTitle}>
                  {props.ReportDetailsContent &&
                    props.ReportDetailsContent.itLineCaption}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
