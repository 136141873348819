/* eslint-disable indent */
const APP_NAME = "ETQ<sup>&#174;</sup>";

// const API_END_POINT = 'https://dev.iorbit-tech.com:3000';
// const API_END_POINT = 'https://dev.etq-global.com:3010';
const API_END_POINT = 'https://etqglobal.solutions:3001';
// const API_END_POINT = 'https://etq-global.com:3010';
const IMAGE_END_POINT = 'https://etqglobal.solutions';

const FORM_INPUTS = [{
    id: 1,
    name: "fname",
    type: "text",
    placeholder: "First Name *",
    required: true,
},
{
    id: 2,
    name: "lname",
    type: "text",
    placeholder: "Last Name",
    required: false,
},
{
    id: 3,
    name: "email",
    type: "email",
    placeholder: "Email *",
    required: true,
},
{
    id: 4,
    name: "phone",
    type: "text",
    placeholder: "Phone",
    required: false,
},
{
    id: 5,
    name: "pass",
    type: "password",
    placeholder: "Password *",
    required: true,
},
{
    id: 6,
    name: "cpass",
    type: "password",
    placeholder: "Confirm Password *",
    required: true,
},
];


const FAQ = [{
    "id": 1,
    "name": "What is ETQ?",
    "desc": "ETQ stands for Entrepreneurial Thinking Quotient."
},
{
    "id": 2,
    "name": "What does the tool do?",
    "desc": "It is an assessment tool to help you create awareness about your entrepreneurial potential(how entrepreneurial you are).It also provides guidance on improvement areas to develop your entrepreneurial potential further."
},
{
    "id": 3,
    "name": "Will I get a report?",
    "desc": "Yes. The report will also help you understand what are the areas you need to focus in order to develop your entrepreneurial potential."
},
{
    "id": 4,
    "name": "What is entrepreneurial potential?",
    "desc": "It is about your mindset (entrepreneurial mindset) for being entrepreneurial. Being entrepreneurial doesn't mean you necessarily have to be an entrepreneur. It is a mindset which you can develop and use in any profession, whatever your career goals or passion is. It is a way of looking at things in a new perspective."
},
{
    "id": 5,
    "name": "Who can benefit from ETQ",
    "desc": "Teachers &amp; professors, adult students &amp; school students, working professionals and entrepreneurs."
},
{
    "id": 6,
    "name": "Why should I take the assessment",
    "desc": `Being entrepreneurial is a form of intelligence which helps deal with the real professional) world better. The world is hungry for people with entrepreneurial mindset and ETQ® has brought an opportunity for people to explore their entrepreneurial potential. We at ETQ believe that the first step towards this is knowing yourself. Being entrepreneurial very soon will be in high demand in various jobs across industries. If you want to become an entrepreneur, this assessment is also for you. The key advantage is that you can take this test from the safety of your homes.`
},
{
    "id": 7,
    "name": "Does the assessment have anything to do with my performance in College or University?",
    "desc": " It has nothing to do with your current College or University curriculum or credit/mark system. It is an independent assessment which you can use in your future career growth."
},
{
    "id": 8,
    "name": "Do I have to share the report with my professors or teachers or institution?",
    "desc": "No. The report belongs to you and you should keep it as confidential data which is accessible only to you and your parents. Do not share your report with others."
},
{
    "id": 9,
    "name": "How to get started?",
    "desc": ` Follow the following registration process and take your assessment right now! You will receive a report after taking the assessment on your registered email ID.`
},
];

const ERROR_MESSAGES = {
    required: "This field is required",
    inValidName: "Only alphanumeric allowed",
    invalidEmail: "Enter a valid email : username@domain.com",
    passLength: "Password should be minimum of 8 characters",
    passverify: "Password doesn't match",
    numberVerify: "Phone number must be composed only with numbers",
    numberlength: "Phone number must be between 10 and 16 digits"
}

const OTPINPUTSDATA = [
    {
        id: 1,
        name: "otp1",
    },
    {
        id: 2,
        name: "otp2",
    },
    {
        id: 3,
        name: "otp3",
    },
    {
        id: 4,
        name: "otp4",
    },
    {
        id: 5,
        name: "otp5",
    },
    {
        id: 6,
        name: "otp6",
    },
];
const Constants = {
    API_END_POINT,
    APP_NAME,
    IMAGE_END_POINT,
    FORM_INPUTS,
    FAQ,
    ERROR_MESSAGES,
    OTPINPUTSDATA
};

export default Constants;