import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2px;
  font: normal normal 400 25px/23px RobotoMedium;
  letter-spacing: 0px
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  color: white;
`;

export default function Digit({ value, title }) {
    const leftDigit = value >= 10 ? value.toString()[0] : '0';
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
    return (
        <Container>
            <DigitContainer>
                <SingleDigit>
                    {leftDigit}
                </SingleDigit>
                <SingleDigit>
                    {rightDigit}
                </SingleDigit>
            </DigitContainer>
        </Container>
    );
}