/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import PrimaryButton from "../common/PrimaryButton";
import Copyright from "../common/Copyright";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import questorTitle from "./../../assets/images/questor-title.png";
import { useHistory } from "react-router-dom";
import { Types } from "../store/Types";
import { Dialog, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";

import InfoGraphicImage from "./../../assets/images/info-4-new.svg";
import homeBanner from "./../../assets/images/home-banner.png";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100vh - 60px)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    // padding: "4rem 3rem",
    ["@media (max-width:480px)"]: {
      padding: "2rem 3rem",
    },
  },
  brightImg: {
    maxWidth: "100px",
    ["@media (max-width:480px)"]: {
      maxWidth: "60px",
    },
  },
  text: {
    fontSize: "21px",
    lineHeight: "30px",
    fontFamily: "PoppinsRegular",
    fontWeight: 400,
    letterSpacing: "0px",
    // marginBottom: "1.2rem",
    ["@media (max-width:480px)"]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  questorTitle: {
    width: "90px",
    verticalAlign: "middle",
  },
  text2: {
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "PoppinsRegular",
    fontWeight: 300,
    letterSpacing: "0px",
    marginBottom: "2.6rem",
  },
  whiteColor: {
    color: "#fff",
    opacity: 1,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "PoppinsSemiBold",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "22%",
      width: 6,
    },
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#fff",
    backgroundColor: "#F6AC29",
    width: "14px",
    height: "14px",
    "&:hover, &:focus": {
      backgroundColor: "#F6AC29",
    },
  },
  modelTitle: {
    fontSize: "24px",
    lineHeight: "28px",
    fontFamily: "PoppinsBold",
    fontWeight: 700,
    letterSpacing: 0,
    color: "#1D1D1B",
  },
  subText: {
    fontSize: "20px",
    lineHeight: "28px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
  },
});

const Home = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const authReducer = useSelector((state) => state.authReducer);

  const onClickLogin = () => {
    dispatch({
      type: Types.OPEN_LOGIN_SCREEN,
    });
    history.push("/login");
  };

  // useEffect(() => {
  //   sessionStorage.removeItem("token");
  //   let activation_code = props.location.search.substring(17);
  //   if (activation_code) {
  //     dispatch({
  //       type: Types.EMAIL_CONFIRM_REQUEST,
  //       payload: { activation_code: activation_code },
  //     });
  //   }
  //   return () => {};
  // }, []);

  useEffect(() => {
    if (authReducer.isEmailVerified) {
      setOpen(true);
    } else {
    }
    return () => {
      console.log("Email verification password cleanup");
    };
  }, [authReducer.isEmailVerified]);

  const redirectToForgotPass = () => {
    history.push("/forgotPassword");
  };

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null || authReducer.isLoggedIn) {
      history.push("/welcome");
    } else {
      history.push("/");
    }
    return () => {
      console.log("clean up");
    };
  }, [authReducer.isLoggedIn]);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: Types.CLOSE_MODAL_POPUP,
    });
  };

  return (
    <React.Fragment>
      <Grid container component="main" className={`${classes.root} iphoneView`}>
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <div className="custom-infoGraphic-image">
              <img src={homeBanner} alt="" className="home-banner" />
            </div>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.rightSideContainer}>
                <Hidden only={["xs"]}>
                  <Typography className={classes.text}>
                    You are entering &nbsp;
                    <img
                      src={questorTitle}
                      alt=""
                      className={classes.questorTitle}
                    />
                    <br />
                    <br />
                  </Typography>
                </Hidden>
                <Hidden only={["lg", "md", "sm", "xl"]}>
                  <Typography className={classes.text}>
                    You are entering &nbsp;
                    <img
                      src={questorTitle}
                      alt=""
                      className={classes.questorTitle}
                    />
                    <br />
                    <br />
                  </Typography>
                </Hidden>
                <Hidden only={["xs"]}>
                  <Typography>
                    <span className={classes.text2}>Relax and enjoy your </span>
                    <span className={classes.whiteColor}>
                      {" "}
                      self learning{" "}
                    </span>
                    <span className={classes.text2}>journey </span>
                  </Typography>
                </Hidden>
                <Hidden only={["lg", "md", "sm", "xl"]}>
                  <Typography>
                    <span className={classes.text2}>Relax and enjoy your </span>
                    <span className={classes.whiteColor}>
                      {" "}
                      self learning{" "}
                    </span>
                    <span className={classes.text2}>journey </span>
                  </Typography>
                </Hidden>
                <br />
                <br />
                <PrimaryButton
                  buttonText="Login To Begin Learning"
                  handleClick={onClickLogin}
                ></PrimaryButton>
                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <Copyright></Copyright>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <img src={InfoGraphicImage} alt="" class="custom-infoGraphic-image" /> */}
            {/* <div className={`${classes.copyRight} hide-iphone`}>
              <Copyright></Copyright>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>

      {authReducer.isEmailVerified ? (
        <React.Fragment>
          <Dialog
            onClose={handleClose}
            aria-labelledby="email-verify-dialog"
            open={open}
            className={classes.modalContainer}
            maxWidth="xs"
          >
            <DialogTitle
              id="email-verify-dialog-title"
              onClose={handleClose}
            ></DialogTitle>
            <DialogContent dividers id="email-verify-dialog-content">
              <p>
                Your email has been verified. Please reset your password using
                <a href="javascript:void(0)" onClick={redirectToForgotPass}>
                  {" "}
                  Forget Password
                </a>
              </p>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default Home;
