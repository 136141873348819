import React from "react";

const SvgScoringSystemSM = (props) => {
  const getPolyganClass = (score, htIndex) => {
    if (score === htIndex) {
      return `opacity-${htIndex}`;
    } else {
      return "opacity-0";
    }
  };

  const getDualPolyganClass = (score1, score2) => {
    if (score1 === 5 && score2 === 5) {
      return `opacity-${score1}`;
    } else {
      return "opacity-0";
    }
  };

  const getTribalPolyganClass = (score1, score2, score3) => {
    if (score1 === 5 && score2 === 5 && score3 === 5) {
      return `opacity-${score1}`;
    } else {
      return "opacity-0";
    }
  };
  return (
    <React.Fragment>
      <svg
        version="1.1"
        id="Full"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 300 300"
        style={{ enableBackground: "new 0 0 300 300" }}
        xmlSpace="preserve"
      >
        <g
          id="HT_4-5_00000027573943504446400830000014810138805123497364_"
          className={getPolyganClass(Math.ceil(props.data.htAvgScore), 5)}
        >
          <polygon
            class="st0"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st0"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
          <polygon
            class="st0"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="HT_3-4_00000043448368343453118870000013222984036663359135_"
          className={getPolyganClass(Math.ceil(props.data.htAvgScore), 4)}
        >
          <polygon
            class="st0"
            points="90,191.72 120,176.72 150,191.72 120,206.72 	"
          />
          <polygon
            class="st0"
            points="90,191.72 90,229.22 120,244.22 120,206.72 	"
          />
          <polygon
            class="st0"
            points="150,191.72 150,229.22 120,244.22 120,206.72 	"
          />
        </g>
        <g
          id="HT_2-3_00000027568899052745214130000003764281094533039284_"
          className={getPolyganClass(Math.ceil(props.data.htAvgScore), 3)}
        >
          <polygon
            class="st0"
            points="60,206.72 90,191.72 120,206.72 90,221.72 	"
          />
          <polygon
            class="st0"
            points="60,206.72 60,244.22 90,259.22 90,221.72 	"
          />
          <polygon
            class="st0"
            points="120,206.72 120,244.22 90,259.22 90,221.72 	"
          />
        </g>
        <g
          id="HT_1-2_00000014619065644303092640000006214671814265069722_"
          className={getPolyganClass(Math.ceil(props.data.htAvgScore), 2)}
        >
          <polygon
            class="st0"
            points="30,221.72 60,206.72 90,221.72 60,236.72 	"
          />
          <polygon
            class="st0"
            points="30,221.72 30,259.22 60,274.22 60,236.72 	"
          />
          <polygon
            class="st0"
            points="90,221.72 90,259.22 60,274.22 60,236.72 	"
          />
        </g>
        <g
          id="HT_0-1_00000159429348196915205890000017547913782895298445_"
          className={getPolyganClass(Math.ceil(props.data.htAvgScore), 1)}
        >
          <polygon
            class="st0"
            points="0,236.72 30,221.72 60,236.72 30,251.72 	"
          />
          <polygon
            class="st0"
            points="0,236.72 0,274.22 30,289.22 30,251.72 	"
          />
          <polygon
            class="st0"
            points="60,236.72 60,274.22 30,289.22 30,251.72 	"
          />
        </g>
        <g
          id="IT_4-5_00000093867543381323997420000000222660431126046087_"
          className={getPolyganClass(Math.ceil(props.data.itAvgScore), 5)}
        >
          <polygon
            class="st5"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st5"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
          <polygon
            class="st5"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="IT_3-4_00000145757971142469956960000001743199054433147836_"
          className={getPolyganClass(Math.ceil(props.data.itAvgScore), 4)}
        >
          <polygon
            class="st5"
            points="150,191.72 180,176.72 210,191.72 180,206.72 	"
          />
          <polygon
            class="st5"
            points="150,191.72 150,229.22 180,244.22 180,206.72 	"
          />
          <polygon
            class="st5"
            points="210,191.72 210,229.22 180,244.22 180,206.72 	"
          />
        </g>
        <g
          id="IT_2-3_00000037668788861718281930000004297624022568213152_"
          className={getPolyganClass(Math.ceil(props.data.itAvgScore), 3)}
        >
          <polygon
            class="st5"
            points="180,206.72 210,191.72 240,206.72 210,221.72 	"
          />
          <polygon
            class="st5"
            points="180,206.72 180,244.22 210,259.22 210,221.72 	"
          />
          <polygon
            class="st5"
            points="240,206.72 240,244.22 210,259.22 210,221.72 	"
          />
        </g>
        <g
          id="IT_1-2_00000060025134415538918180000009597846144765560238_"
          className={getPolyganClass(Math.ceil(props.data.itAvgScore), 2)}
        >
          <polygon
            class="st5"
            points="210,221.72 240,206.72 270,221.72 240,236.72 	"
          />
          <polygon
            class="st5"
            points="210,221.72 210,259.22 240,274.22 240,236.72 	"
          />
          <polygon
            class="st5"
            points="270,221.72 270,259.22 240,274.22 240,236.72 	"
          />
        </g>
        <g
          id="IT_0-1_00000181772145903775608350000008980452545729506981_"
          className={getPolyganClass(Math.ceil(props.data.itAvgScore), 1)}
        >
          <polygon
            class="st5"
            points="240,236.72 270,221.72 300,236.72 270,251.72 	"
          />
          <polygon
            class="st5"
            points="240,236.72 240,274.22 270,289.22 270,251.72 	"
          />
          <polygon
            class="st5"
            points="300,236.72 300,274.22 270,289.22 270,251.72 	"
          />
        </g>
        <g
          id="RT_4-5_00000022562795723873966150000011666912130547358141_"
          className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 5)}
        >
          <polygon
            class="st6"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st6"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
          <polygon
            class="st6"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="RT_3-4_00000132781799886355463100000011823207448413737349_"
          className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 4)}
        >
          <polygon
            class="st6"
            points="120,139.22 150,124.22 180,139.22 150,154.22 	"
          />
          <polygon
            class="st6"
            points="120,139.22 120,176.72 150,191.72 150,154.22 	"
          />
          <polygon
            class="st6"
            points="180,139.22 180,176.72 150,191.72 150,154.22 	"
          />
        </g>
        <g
          id="RT_2-3_00000127033369112498863260000008248278047403675298_"
          className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 3)}
        >
          <polygon
            class="st6"
            points="120,101.72 150,86.72 180,101.72 150,116.72 	"
          />
          <polygon
            class="st6"
            points="120,101.72 120,139.22 150,154.22 150,116.72 	"
          />
          <polygon
            class="st6"
            points="180,101.72 180,139.22 150,154.22 150,116.72 	"
          />
        </g>
        <g
          id="RT_1-2_00000056410562826304519320000005917640242984138167_"
          className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 2)}
        >
          <polygon
            class="st6"
            points="120,64.22 150,49.22 180,64.22 150,79.22 	"
          />
          <polygon
            class="st6"
            points="120,64.22 120,101.72 150,116.72 150,79.22 	"
          />
          <polygon
            class="st6"
            points="180,64.22 180,101.72 150,116.72 150,79.22 	"
          />
        </g>
        <g
          id="RT_0-1_00000024714472990249639040000008119817697654091145_"
          className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 1)}
        >
          <polygon
            class="st6"
            points="120,26.72 150,11.72 180,26.72 150,41.72 	"
          />
          <polygon
            class="st6"
            points="120,26.72 120,64.22 150,79.22 150,41.72 	"
          />
          <polygon
            class="st6"
            points="180,26.72 180,64.22 150,79.22 150,41.72 	"
          />
        </g>
        <g
          id="IT__x2B__HT_4-5_00000105424005190598417470000014614985957759823802_"
          className={getDualPolyganClass(
            Math.ceil(props.data.htAvgScore),
            Math.ceil(props.data.itAvgScore)
          )}
        >
          <polygon
            class="st7"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
          <polygon
            class="st8"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="RT__x2B__HT_4-5_00000090277318956623707320000017017308387858364083_"
          className={getDualPolyganClass(
            Math.ceil(props.data.htAvgScore),
            Math.ceil(props.data.rtAvgScore)
          )}
        >
          <polygon
            class="st9"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st7"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="RT__x2B__IT_4-5_00000053537275190504340020000000372424282482279355_"
          className={getDualPolyganClass(
            Math.ceil(props.data.rtAvgScore),
            Math.ceil(props.data.itAvgScore)
          )}
        >
          <polygon
            class="st9"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st8"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g
          id="All_4-5_00000050652045579406447540000016505958417968774034_"
          className={getTribalPolyganClass(
            Math.ceil(props.data.htAvgScore),
            Math.ceil(props.data.itAvgScore),
            Math.ceil(props.data.rtAvgScore)
          )}
        >
          <polygon
            class="st9"
            points="120,176.72 150,161.72 180,176.72 150,191.72 	"
          />
          <polygon
            class="st7"
            points="120,176.72 120,214.22 150,229.22 150,191.72 	"
          />
          <polygon
            class="st8"
            points="180,176.72 180,214.22 150,229.22 150,191.72 	"
          />
        </g>
        <g id="All_4-5_Box">
          <g>
            <g>
              <path
                class="st10"
                d="M150,162.14l29.16,14.58L150,191.3l-29.16-14.58L150,162.14 M150,161.72l-30,15l30,15l30-15L150,161.72
				L150,161.72z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                class="st10"
                d="M120.38,177.33l29.25,14.62v36.66l-29.25-14.62V177.33 M120,176.72v37.5l30,15v-37.5L120,176.72L120,176.72
				z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                class="st10"
                d="M179.62,177.33v36.66l-29.25,14.62v-36.66L179.62,177.33 M180,176.72l-30,15v37.5l30-15V176.72L180,176.72z
				"
              />
            </g>
          </g>
        </g>
        <g id="RT_3-4_Box">
          <g>
            <path
              class="st10"
              d="M150,124.33l29.79,14.9L150,154.12l-29.79-14.9L150,124.33 M150,124.22l-30,15l30,15l30-15L150,124.22
			L150,124.22z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M120.09,139.38l29.81,14.91v37.29l-29.81-14.91V139.38 M120,139.22v37.5l30,15v-37.5L120,139.22L120,139.22z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M179.91,139.38v37.29l-29.81,14.91v-37.29L179.91,139.38 M180,139.22l-30,15v37.5l30-15V139.22L180,139.22z"
            />
          </g>
        </g>
        <g id="RT_2-3_Box">
          <g>
            <path
              class="st10"
              d="M150,86.83l29.79,14.9L150,116.62l-29.79-14.9L150,86.83 M150,86.72l-30,15l30,15l30-15L150,86.72L150,86.72
			z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M120.09,101.88l29.81,14.91v37.29l-29.81-14.91V101.88 M120,101.72v37.5l30,15v-37.5L120,101.72L120,101.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M179.91,101.88v37.29l-29.81,14.91v-37.29L179.91,101.88 M180,101.72l-30,15v37.5l30-15V101.72L180,101.72z"
            />
          </g>
        </g>
        <g id="RT_1-2_Box">
          <g>
            <path
              class="st10"
              d="M150,49.33l29.79,14.9L150,79.12l-29.79-14.9L150,49.33 M150,49.22l-30,15l30,15l30-15L150,49.22L150,49.22z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M120.09,64.38l29.81,14.91v37.29l-29.81-14.91V64.38 M120,64.22v37.5l30,15v-37.5L120,64.22L120,64.22z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M179.91,64.38v37.29l-29.81,14.91V79.28L179.91,64.38 M180,64.22l-30,15v37.5l30-15V64.22L180,64.22z"
            />
          </g>
        </g>
        <g id="RT_0-1_Box">
          <g>
            <path
              class="st10"
              d="M150,11.83l29.79,14.9L150,41.62l-29.79-14.9L150,11.83 M150,11.72l-30,15l30,15l30-15L150,11.72L150,11.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M120.09,26.88l29.81,14.91v37.29l-29.81-14.91V26.88 M120,26.72v37.5l30,15v-37.5L120,26.72L120,26.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M179.91,26.88v37.29l-29.81,14.91V41.78L179.91,26.88 M180,26.72l-30,15v37.5l30-15V26.72L180,26.72z"
            />
          </g>
        </g>
        <g id="HT_3-4_Box">
          <g>
            <path
              class="st10"
              d="M120,176.83l29.79,14.9L120,206.62l-29.79-14.9L120,176.83 M120,176.72l-30,15l30,15l30-15L120,176.72
			L120,176.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M90.09,191.88l29.81,14.91v37.29l-29.81-14.91V191.88 M90,191.72v37.5l30,15v-37.5L90,191.72L90,191.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M149.91,191.88v37.29l-29.81,14.91v-37.29L149.91,191.88 M150,191.72l-30,15v37.5l30-15V191.72L150,191.72z"
            />
          </g>
        </g>
        <g id="HT_2-3_Box">
          <g>
            <path
              class="st10"
              d="M90,191.83l29.79,14.9L90,221.62l-29.79-14.9L90,191.83 M90,191.72l-30,15l30,15l30-15L90,191.72L90,191.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M60.09,206.88l29.81,14.91v37.29l-29.81-14.91V206.88 M60,206.72v37.5l30,15v-37.5L60,206.72L60,206.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M119.91,206.88v37.29l-29.81,14.91v-37.29L119.91,206.88 M120,206.72l-30,15v37.5l30-15V206.72L120,206.72z"
            />
          </g>
        </g>
        <g id="HT_1-2_Box">
          <g>
            <path
              class="st10"
              d="M60,206.83l29.79,14.9L60,236.62l-29.79-14.9L60,206.83 M60,206.72l-30,15l30,15l30-15L60,206.72L60,206.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M30.09,221.88l29.81,14.91v37.29l-29.81-14.91V221.88 M30,221.72v37.5l30,15v-37.5L30,221.72L30,221.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M89.91,221.88v37.29l-29.81,14.91v-37.29L89.91,221.88 M90,221.72l-30,15v37.5l30-15V221.72L90,221.72z"
            />
          </g>
        </g>
        <g id="HT_0-1_Box_00000032626440070442752100000001631476765333128357_">
          <g>
            <path
              class="st10"
              d="M30,221.83l29.79,14.9L30,251.62l-29.79-14.9L30,221.83 M30,221.72l-30,15l30,15l30-15L30,221.72L30,221.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M0.09,236.88l29.81,14.91v37.29L0.09,274.17V236.88 M0,236.72v37.5l30,15v-37.5L0,236.72L0,236.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M59.91,236.88v37.29l-29.81,14.91v-37.29L59.91,236.88 M60,236.72l-30,15v37.5l30-15V236.72L60,236.72z"
            />
          </g>
        </g>
        <g id="IT_3-4_Box">
          <g>
            <path
              class="st10"
              d="M180,176.83l29.79,14.9L180,206.62l-29.79-14.9L180,176.83 M180,176.72l-30,15l30,15l30-15L180,176.72
			L180,176.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M150.09,191.88l29.81,14.91v37.29l-29.81-14.91V191.88 M150,191.72v37.5l30,15v-37.5L150,191.72L150,191.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M209.91,191.88v37.29l-29.81,14.91v-37.29L209.91,191.88 M210,191.72l-30,15v37.5l30-15V191.72L210,191.72z"
            />
          </g>
        </g>
        <g id="IT_2-3_Box">
          <g>
            <path
              class="st10"
              d="M210,191.83l29.79,14.9L210,221.62l-29.79-14.9L210,191.83 M210,191.72l-30,15l30,15l30-15L210,191.72
			L210,191.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M180.09,206.88l29.81,14.91v37.29l-29.81-14.91V206.88 M180,206.72v37.5l30,15v-37.5L180,206.72L180,206.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M239.91,206.88v37.29l-29.81,14.91v-37.29L239.91,206.88 M240,206.72l-30,15v37.5l30-15V206.72L240,206.72z"
            />
          </g>
        </g>
        <g id="IT_1-2_Box">
          <g>
            <path
              class="st10"
              d="M240,206.83l29.79,14.9L240,236.62l-29.79-14.9L240,206.83 M240,206.72l-30,15l30,15l30-15L240,206.72
			L240,206.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M210.09,221.88l29.81,14.91v37.29l-29.81-14.91V221.88 M210,221.72v37.5l30,15v-37.5L210,221.72L210,221.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M269.91,221.88v37.29l-29.81,14.91v-37.29L269.91,221.88 M270,221.72l-30,15v37.5l30-15V221.72L270,221.72z"
            />
          </g>
        </g>
        <g id="IT_0-1_Box">
          <g>
            <path
              class="st10"
              d="M270,221.83l29.79,14.9L270,251.62l-29.79-14.9L270,221.83 M270,221.72l-30,15l30,15l30-15L270,221.72
			L270,221.72z"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M240.09,236.88l29.81,14.91v37.29l-29.81-14.91V236.88 M240,236.72v37.5l30,15v-37.5L240,236.72L240,236.72z
			"
            />
          </g>
          <g>
            <path
              class="st10"
              d="M299.91,236.88v37.29l-29.81,14.91v-37.29L299.91,236.88 M300,236.72l-30,15v37.5l30-15V236.72L300,236.72z"
            />
          </g>
        </g>
        <g id="RT_0">
          <path
            class="st10"
            d="M146.75,22.16c0.65-1.02,1.74-1.54,3.25-1.54c1.52,0,2.6,0.51,3.25,1.54s0.98,2.44,0.98,4.25
		c0,1.82-0.33,3.25-0.98,4.28c-0.65,1.04-1.74,1.55-3.25,1.55c-1.52,0-2.6-0.52-3.25-1.55c-0.65-1.03-0.98-2.46-0.98-4.28
		C145.77,24.6,146.09,23.18,146.75,22.16z M151.92,24.45c-0.1-0.53-0.3-0.96-0.6-1.29c-0.3-0.33-0.74-0.5-1.32-0.5
		c-0.59,0-1.03,0.17-1.32,0.5c-0.3,0.33-0.5,0.77-0.6,1.29c-0.1,0.53-0.15,1.18-0.15,1.95c0,0.79,0.05,1.46,0.14,2
		s0.29,0.97,0.6,1.3c0.3,0.33,0.75,0.49,1.33,0.49c0.58,0,1.03-0.16,1.33-0.49c0.3-0.33,0.5-0.76,0.6-1.3
		c0.09-0.54,0.14-1.21,0.14-2C152.07,25.63,152.02,24.98,151.92,24.45z"
          />
        </g>
        <g id="HT_0">
          <path
            class="st10"
            d="M11.75,258.7c0.65-1.02,1.74-1.54,3.25-1.54c1.52,0,2.6,0.51,3.25,1.54c0.65,1.02,0.98,2.44,0.98,4.25
		c0,1.82-0.33,3.25-0.98,4.28c-0.65,1.04-1.74,1.55-3.25,1.55c-1.52,0-2.6-0.52-3.25-1.55c-0.65-1.03-0.98-2.46-0.98-4.28
		C10.77,261.14,11.09,259.73,11.75,258.7z M16.92,261c-0.1-0.53-0.3-0.96-0.6-1.29c-0.3-0.33-0.74-0.5-1.32-0.5
		c-0.59,0-1.03,0.17-1.32,0.5c-0.3,0.33-0.5,0.77-0.6,1.29c-0.1,0.53-0.15,1.18-0.15,1.95c0,0.79,0.05,1.46,0.14,2
		s0.29,0.97,0.6,1.3c0.3,0.33,0.75,0.49,1.33,0.49c0.58,0,1.03-0.16,1.33-0.49c0.3-0.33,0.5-0.76,0.6-1.3
		c0.09-0.54,0.14-1.21,0.14-2C17.07,262.18,17.02,261.53,16.92,261z"
          />
        </g>
        <g id="IT_0">
          <path
            class="st10"
            d="M282.35,257.94c0.65-1.02,1.74-1.54,3.25-1.54c1.52,0,2.6,0.51,3.25,1.54c0.65,1.02,0.98,2.44,0.98,4.25
		c0,1.82-0.33,3.25-0.98,4.28c-0.65,1.04-1.74,1.55-3.25,1.55c-1.52,0-2.6-0.52-3.25-1.55c-0.65-1.03-0.98-2.46-0.98-4.28
		C281.37,260.38,281.69,258.96,282.35,257.94z M287.52,260.24c-0.1-0.53-0.3-0.96-0.6-1.29c-0.3-0.33-0.74-0.5-1.32-0.5
		c-0.59,0-1.03,0.17-1.32,0.5c-0.3,0.33-0.5,0.77-0.6,1.29c-0.1,0.53-0.15,1.18-0.15,1.95c0,0.79,0.05,1.46,0.14,2
		s0.29,0.97,0.6,1.3c0.3,0.33,0.75,0.49,1.33,0.49c0.58,0,1.03-0.16,1.33-0.49c0.3-0.33,0.5-0.76,0.6-1.3
		c0.09-0.54,0.14-1.21,0.14-2C287.67,261.42,287.62,260.77,287.52,260.24z"
          />
        </g>
        <g id="_x35_">
          <path
            d="M156.35,186.7h-8.71v4.6c0.38-0.47,0.91-0.85,1.61-1.14c0.7-0.3,1.44-0.44,2.23-0.44c1.43,0,2.61,0.31,3.52,0.94
		s1.58,1.43,1.99,2.41c0.41,0.98,0.62,2.02,0.62,3.13c0,2.06-0.59,3.72-1.76,4.97c-1.17,1.25-2.85,1.88-5.01,1.88
		c-2.04,0-3.67-0.51-4.89-1.53c-1.22-1.02-1.91-2.36-2.07-4.01h3.66c0.16,0.72,0.52,1.29,1.09,1.72c0.56,0.43,1.29,0.64,2.16,0.64
		c1.06,0,1.86-0.33,2.39-0.99c0.54-0.66,0.81-1.54,0.81-2.63c0-1.11-0.27-1.96-0.82-2.54c-0.55-0.58-1.35-0.87-2.41-0.87
		c-0.75,0-1.38,0.19-1.88,0.56s-0.86,0.88-1.08,1.51h-3.6v-11.5h12.15V186.7z"
          />
        </g>
      </svg>
    </React.Fragment>
  );
};

export default SvgScoringSystemSM;
