/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Types } from "../store/Types";
import { useHistory } from "react-router";
import CustomizedTooltips from "./Tooltip";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import logo from "../../assets/images/report/etq-logo.png";
import logoWhite from "../../assets/images/report/etq-logo-white.png";
import { ReactComponent as FullScreenNormalIcon } from "./../../assets/images/fs-normal.svg";
import { ReactComponent as FullScreenDarkIcon } from "./../../assets/images/fs-dark.svg";
import { ReactComponent as FullScreenRestoreIcon } from "./../../assets/images/fs-restore.svg";
import { ReactComponent as FullScreenRestoreDarkIcon } from "./../../assets/images/fs-restore-dark.svg";
import lightThemeIcon from "../../assets/images/light-theme-icon.png";
import darkThemeIcon from "../../assets/images/dark-theme-icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: "79px",
    cursor: "pointer",
  },
  fullScreenIcon: {
    paddingRight: "1rem",
    marginRight: "1rem",
    cursor: "pointer",
    borderRight: "1px solid",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    height: "60px",
    boxShadow: "0px 5px 12px #00000017",
    ["@media (max-width:480px)"]: {
      height: "48px",
    },
  },
  lightHeader: {
    backgroundColor: "#fff",
  },
  darkHeader: {
    backgroundColor: "#2B2C2E",
    boxShadow: "0px 5px 12px #fffcfc17",
  },
  black: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    cursor: "pointer",
  },
  userName: {
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    marginRight: 30,
    display: "inline-grid",
    color: "#000",
    textAlign: "right",
  },
  darkUserName: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "bold",
    marginRight: 30,
    display: "inline-grid",
    textAlign: "right",
  },
  tooltip: {
    position: "relative",
    "&:hover .tooltiptext": {
      display: "flex",
    },
  },
  tooltiptext: {
    visibility: "hidden",
    width: "120px",
    // backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 0",
    position: "absolute",
    zIndex: 1,
    top: "150%",
    left: "50%",
    marginLeft: "-60px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "transparent transparent black transparent",
    },
  },
  switch: {
    margin: "0 1rem",
    cursor: "pointer",
    ["@media (max-width:480px)"]: {
      margin: "0 0.8rem",
    },
  },
  ml4: {
    marginLeft: "4px",
  },
  lightThemeProfileText: {
    color: "#626262",
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "PoppinsMedium",
    fontWeight: 400,
  },
  darkThemeProfileText: {
    color: "#f5f5f5",
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "PoppinsMedium",
    fontWeight: 400,
  },
  customFullScreenIcon: {
    paddingRight: "1rem",
    marginRight: "1rem",
    cursor: "pointer",
    borderRight: "2px solid #ffcd05",
  },
}));

export const light = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#fff",
      light: "#fff",
    },
    secondary: {
      main: "#F0F1F5",
    },
  },
});

export const dark = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1D1D1B",
      dark: "#1D1D1B",
    },
    secondary: {
      main: "#0F0F0F",
    },
  },
});

const Topbar = (props) => {
  const classes = useStyles();
  const [ButtonText, setButtonText] = useState("Login");
  const [isLoginScreenOpen, setIsLoginScreenOpen] = useState(false);
  const [fullScreen, setfullScreen] = useState(false);
  const AuthReducer = useSelector((state) => state.authReducer);
  const TestReducer = useSelector((state) => state.TestReducer);
  const history = useHistory();
  const appliedTheme = createMuiTheme(
    TestReducer.isDarkThemeSelected ? dark : light
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = AuthReducer.userInfo.data;

  const openFullscreen = () => {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      setfullScreen(true);
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
      setfullScreen(true);
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
      setfullScreen(true);
    }
  };

  const handleLogout = () => {
    dispatch({
      type: Types.USER_LOGOUT_REQUEST,
    });
    dispatch({
      type: Types.SET_THEME,
      payload: false,
    });
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("useremail");
    history.push("/login");
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      setfullScreen(false);
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      setfullScreen(false);
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
      setfullScreen(false);
    }
  };

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token) {
      dispatch({
        type: Types.USER_EXIST,
      });
    } else {
    }
    return () => {
      console.log("unmounting topbar component");
    };
  }, []);

  useEffect(() => {
    if (AuthReducer.isLoggedIn) {
      dispatch({
        type: Types.GET_USER_INFO,
      });
    }
    return () => {
      console.log("unmounted");
    };
  }, [AuthReducer.isLoggedIn]);

  useEffect(() => {
    if (AuthReducer.isOpenLoginScreen) {
      setIsLoginScreenOpen(true);
    }
    return () => {
      console.log("unmounted");
    };
  }, [AuthReducer.isOpenLoginScreen]);

  const handleLoginClick = () => {
    dispatch({
      type: Types.OPEN_LOGIN_SCREEN,
    });
    history.push("/login");
  };

  const goToHome = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("useremail");
    AuthReducer.isLoading = false; 
    history.push("/");
  };

  const onHandleThemeSelection = (event) => {
    let selectedTheme =
      TestReducer.selectedTheme && TestReducer.selectedTheme.split("_")[0];
    if (TestReducer.isBgImageSet) {
      dispatch({
        type: Types.SET_BG_IMAGE,
        payload: {
          selectedTheme: TestReducer.isDarkThemeSelected
            ? `${selectedTheme}_light_bg`
            : `${selectedTheme}_dark_bg`,
        },
      });
    }
    dispatch({
      type: Types.SET_THEME,
      payload: !TestReducer.isDarkThemeSelected,
    });
  };

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      {location.pathname === "/report" || location.pathname === "/new-report" ? null : (
        <div className={classes.root}>
          <div className="customHeader">
            <AppBar
              position="static"
              className={`${classes.header} ${
                TestReducer.isDarkThemeSelected
                  ? classes.darkHeader
                  : classes.header
              }`}
            >
              <Toolbar
                className={
                  TestReducer.selectedTheme === "default"
                    ? "defaultHeader"
                    : "headerShadow"
                }
              >
                {TestReducer.isDarkThemeSelected &&
                TestReducer.selectedTheme === "default" ? (
                  <img
                    src={logoWhite}
                    alt="logo"
                    className={classes.logo}
                    onClick={goToHome}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    className={classes.logo}
                    onClick={goToHome}
                  />
                )}
                <Typography variant="h6" className={classes.title}></Typography>
                {TestReducer.isTestStarted && AuthReducer.isLoggedIn ? (
                  <React.Fragment>
                    <img
                      onClick={onHandleThemeSelection}
                      src={
                        TestReducer.isDarkThemeSelected
                          ? lightThemeIcon
                          : darkThemeIcon
                      }
                      alt="themeSelection"
                      className={classes.switch}
                    />
                    {fullScreen ? (
                      <span
                        onClick={closeFullscreen}
                        className={classes.customFullScreenIcon}
                      >
                        {TestReducer.isDarkThemeSelected ? (
                          <FullScreenRestoreDarkIcon />
                        ) : (
                          <FullScreenRestoreIcon />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={openFullscreen}
                        className={classes.customFullScreenIcon}
                      >
                        {TestReducer.isDarkThemeSelected ? (
                          <FullScreenDarkIcon />
                        ) : (
                          <FullScreenNormalIcon />
                        )}
                      </span>
                    )}
                  </React.Fragment>
                ) : null}
                {AuthReducer.isLoggedIn ? (
                  <React.Fragment>
                    {userInfo ? (
                      <React.Fragment>
                        <span
                          className={`${
                            TestReducer.isDarkThemeSelected
                              ? classes.darkUserName
                              : classes.userName
                          }`}
                        >
                          {userInfo.firstName} {userInfo.lastName} <br />
                          <a
                            href="javascript:void(0)"
                            style={{ color: "#626262" }}
                            onClick={handleLogout}
                          >
                            <span
                              className={`${
                                TestReducer.isDarkThemeSelected
                                  ? classes.darkThemeProfileText
                                  : classes.lightThemeProfileText
                              }`}
                            >
                              Logout
                            </span>
                          </a>
                        </span>
                        <CustomizedTooltips userInfo={userInfo} />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
              </Toolbar>
            </AppBar>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default Topbar;
