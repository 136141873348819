/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import feedbackGood from "./../../assets/images/report/new-scoring-icons/feedback-good.png";
import feedbackGoodWhite from "./../../assets/images/report/new-scoring-icons/feedback-good-white.png";
import feedbackBad from "./../../assets/images/report/new-scoring-icons/feedback-bad.png";
import feedbackBadWhite from "./../../assets/images/report/new-scoring-icons/feedback-bad-white.png";
import feedbackOkay from "./../../assets/images/report/new-scoring-icons/feedback-okay.png";
import feedbackOkayWhite from "./../../assets/images/report/new-scoring-icons/feedback-okay-white.png";
import closePageIcon from "./../../assets/images/report/close-page-icon.svg";

const useStyles = makeStyles((theme) => ({
  AnimatedDrawerContainer: {
    padding: "0 140px",
    height: "calc(100vh - 120px)",
    display: "flex",
    alignItems: "center",
  },
  closeBtn: {
    top: "20px",
    right: "10%",
    color: "#707070",
    opacity: 1,
    position: "absolute",
    fontSize: "34px",
    cursor: "pointer",
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      top: "30px",
      fontSize: "24px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      top: "30px",
      fontSize: "64px",
    },
    ["@media (min-width:1443px)"]: {
      fontSize: "50px",
      top: "30px",
    },
  },
  closeBtnImage: {
    width: "30px",
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "30px",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      width: "50px",
    },
    ["@media (min-width:1443px)"]: {
      width: "50px",
    },
  },
  sectionTitle: {
    color: "rgb(111, 188, 68)",
    font: "normal normal 400 24px/36px PoppinsSemiBold",
    marginBottom: "22px",
    ["@media (max-width:1382px)"]: {
      font: "normal normal 400 24px/36px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 400 24px/36px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    font: "normal normal 600 18px/26px PoppinsMedium",
    color: "#000",
    margin: 0,
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 18px/26px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/26px PoppinsMedium",
    },
  },
  paragraph: {
    font: "normal normal 600 14px/18px PoppinsSemiBold",
    color: "#626262",
    marginBottom: "54px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 14px/18px PoppinsSemiBold",
    },
  },
}));

const Screen13 = (props) => {
  const classes = useStyles();

  const [feedbackBadIcon, setFeedbackBadIcon] = useState(false);
  const [feedbackGoodIcon, setFeedbackGoodIcon] = useState(false);
  const [feedbackOkayIcon, setFeedbackOkayIcon] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState("");

  const closeAnimatedDrawer = (event) => {
    document
      .getElementById("feedback-btn")
      .classList.remove("feedback-selected");
    props.animatedDrawerType(event);
  };

  const onMouseHoveringFeedbackIcon = (event, type) => {
    if (type === "sad") {
      if (!feedbackBadIcon) {
        event.currentTarget.src = feedbackBad;
      }
    }
    if (type === "neutral") {
      if (!feedbackOkayIcon) {
        event.currentTarget.src = feedbackOkay;
      }
    }
    if (type === "happy") {
      if (!feedbackGoodIcon) {
        event.currentTarget.src = feedbackGood;
      }
    }
  };
  const onMouseLeavingFeedbackIcon = (event, type) => {
    if (type === "sad") {
      if (!feedbackBadIcon) {
        event.currentTarget.src = feedbackBadWhite;
      }
    }
    if (type === "neutral") {
      if (!feedbackOkayIcon) {
        event.currentTarget.src = feedbackOkayWhite;
      }
    }
    if (type === "happy") {
      if (!feedbackGoodIcon) {
        event.currentTarget.src = feedbackGoodWhite;
      }
    }
  };

  const setFeedbackBadStatus = (event, feedback) => {
    event.currentTarget.src = feedbackBad;
    setSelectedFeedback(feedback);
    setFeedbackBadIcon(true);
    setFeedbackOkayIcon(false);
    setFeedbackGoodIcon(false);
    document
      .querySelector("#feedback-good")
      .setAttribute("src", feedbackGoodWhite);
    document
      .querySelector("#feedback-okay")
      .setAttribute("src", feedbackOkayWhite);
    document.getElementById("feedback-btn").classList.add("feedback-selected");
  };

  const setFeedbackOkayStatus = (event, feedback) => {
    event.currentTarget.src = feedbackOkay;
    setSelectedFeedback(feedback);
    setFeedbackOkayIcon(true);
    setFeedbackBadIcon(false);
    setFeedbackGoodIcon(false);
    document
      .querySelector("#feedback-bad")
      .setAttribute("src", feedbackBadWhite);
    document
      .querySelector("#feedback-good")
      .setAttribute("src", feedbackGoodWhite);
    document.getElementById("feedback-btn").classList.add("feedback-selected");
  };
  const setFeedbackGoodStatus = (event, feedback) => {
    event.currentTarget.src = feedbackGood;
    setSelectedFeedback(feedback);
    setFeedbackGoodIcon(true);
    setFeedbackBadIcon(false);
    setFeedbackOkayIcon(false);
    document
      .querySelector("#feedback-bad")
      .setAttribute("src", feedbackBadWhite);
    document
      .querySelector("#feedback-okay")
      .setAttribute("src", feedbackOkayWhite);
    document.getElementById("feedback-btn").classList.add("feedback-selected");
  };

  const handleFeedbackSubmit = () => {
    let feedback = {
      feedback: selectedFeedback,
      assessmentAttemptNo: props.data.assessmentAttemptNo,
    };
    document
      .getElementById("feedback-btn")
      .classList.remove("feedback-selected");
    props.feedbackSubmit(feedback);
  };

  const getImageURL = () => {
    if (props.data.reportFeedback === "neutral") {
      document
        .querySelector("#feedback-okay")
        .setAttribute("src", feedbackOkay);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackGoodWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackBadWhite);
      document.querySelector("#feedback-bad").style.pointerEvents = "none";
      document.querySelector("#feedback-okay").style.pointerEvents = "none";
      document.querySelector("#feedback-good").style.pointerEvents = "none";
    } else {
      document
        .querySelector("#feedback-okay")
        .setAttribute("src", feedbackOkayWhite);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackGoodWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackBadWhite);
    }

    if (props.data.reportFeedback === "happy") {
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackGood);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackOkayWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackBadWhite);
      document.querySelector("#feedback-bad").style.pointerEvents = "none";
      document.querySelector("#feedback-okay").style.pointerEvents = "none";
      document.querySelector("#feedback-good").style.pointerEvents = "none";
    } else {
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackGoodWhite);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackOkayWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackBadWhite);
    }

    if (props.data.reportFeedback === "sad") {
      document.querySelector("#feedback-bad").setAttribute("src", feedbackBad);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackOkayWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackGoodWhite);
      document.querySelector("#feedback-good").style.pointerEvents = "none";
      document.querySelector("#feedback-bad").style.pointerEvents = "none";
      document.querySelector("#feedback-okay").style.pointerEvents = "none";
    } else {
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackBadWhite);
      document
        .querySelector("#feedback-good")
        .setAttribute("src", feedbackOkayWhite);
      document
        .querySelector("#feedback-bad")
        .setAttribute("src", feedbackGoodWhite);
    }
  };

  const getBtnText = () => {
    if (props.data.reportFeedback === null) {
      document.querySelector("#feedback-btn").innerHTML = "Submit";
    } else {
      document.querySelector("#feedback-btn").innerHTML = "Think You!";
      document.querySelector("#feedback-btn").style.pointerEvents = "none";
    }
  };

  useEffect(() => {
    // code to run after render goes here
    getBtnText();
    getImageURL();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.AnimatedDrawerContainer}>
        <TransitionGroup>
          <CSSTransition
            classNames={{
              enterActive: "animate__animated animate__lightSpeedInLeft",
              exitActive: "animate__animated animate__lightSpeedOutLeft",
            }}
            timeout={5000}
          >
            <Grid container style={{ padding: "0" }}>
              <p
                onClick={() => closeAnimatedDrawer(false)}
                className={classes.closeBtn}
              >
                <img
                  className={classes.closeBtnImage}
                  src={closePageIcon}
                  alt="close-page-icon"
                />
              </p>
              <Grid container className={classes.reportCustomContainer}>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <h4 className={classes.sectionTitle}>
                    Before you go . . . we’d love to hear from you
                  </h4>
                  <p className={classes.sectionSubTitle}>
                    How has your experience been using <b> questor learning</b> ?
                  </p>
                  <p className={classes.paragraph}>(Choose One)</p>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
              </Grid>
              <Grid container className={classes.reportCustomContainer}>
                <Grid item xs={12} sm={3} md={3}></Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <img
                    // src={
                    //   require("./../../assets/images/report/new-scoring-icons/feedback-bad-white.png")
                    //     .default
                    // }
                    onClick={(event) => setFeedbackBadStatus(event, "sad")}
                    onMouseOver={(event) =>
                      onMouseHoveringFeedbackIcon(event, "sad")
                    }
                    onMouseOut={(event) =>
                      onMouseLeavingFeedbackIcon(event, "sad")
                    }
                    alt="feedback-bad"
                    id="feedback-bad"
                    // src={getImageURL()}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <img
                    // src={
                    //   require("./../../assets/images/report/new-scoring-icons/feedback-okay-white.png")
                    //     .default
                    // }
                    onClick={(event) => setFeedbackOkayStatus(event, "neutral")}
                    onMouseOver={(event) =>
                      onMouseHoveringFeedbackIcon(event, "neutral")
                    }
                    onMouseOut={(event) =>
                      onMouseLeavingFeedbackIcon(event, "neutral")
                    }
                    alt="feedback-okay"
                    id="feedback-okay"
                    // src={getImageURL()}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <img
                    // src={
                    //   require("./../../assets/images/report/new-scoring-icons/feedback-good-white.png")
                    //     .default
                    // }
                    onClick={(event) => setFeedbackGoodStatus(event, "happy")}
                    onMouseOver={(event) =>
                      onMouseHoveringFeedbackIcon(event, "happy")
                    }
                    onMouseOut={(event) =>
                      onMouseLeavingFeedbackIcon(event, "happy")
                    }
                    alt="feedback-good"
                    id="feedback-good"
                    // src={getImageURL()}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}></Grid>
              </Grid>
              <Grid container className={classes.reportCustomContainer}>
                <Grid item xs={12} sm={3} md={3}></Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <button
                    class="feedback-btn"
                    id="feedback-btn"
                    onClick={handleFeedbackSubmit}
                  >
                    {/* {getBtnText()} */}
                    {/* Submit */}
                  </button>
                </Grid>
                <Grid item xs={12} sm={3} md={3}></Grid>
              </Grid>
            </Grid>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </React.Fragment>
  );
};

export default Screen13;
