import React from "react";
import ReactAudioPlayer from "react-audio-player";
// import Music from "../../assets/music.wav";
import Music from "../../assets/music-new.mpeg";

function Audio() {
  return (
    <div>
      <ReactAudioPlayer src={Music} autoPlay={true} volume="1" />
    </div>
  );
}

export default Audio;
