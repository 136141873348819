import axios from 'axios';
import Constants from "../constants/constants";
// const ip = require('ip');

const userLogin = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/login`, data);
        return response.data;
    } catch (error) {
        console.log("Error", error);
    }
}

const userRegister = async (data) => {
    // let ipaddress = ip.address();
    // data.ip = ipaddress;
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/registration`, data);
        return response.data;
    } catch (e) {
        console.log("Error", e);
    }
}

const resetPassword = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/reset-password`, data);
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}


const changePassword = async (data) => {
    try {
        const response = await axios.put(`${Constants.API_END_POINT}/change-password`, data);
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const emailConfirm = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/verify-email`, data);
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const emailVerify = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/verify-authorized-email`, data);
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const leadsCapture = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/lead-capture`, data);
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const userInfo = () => {
    try {
        return axios.get(`${Constants.API_END_POINT}/userinfo`, {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
    } catch (error) {
        console.log("Error", error);
    }
}

const registerCodeVerify = (data) => {
    try {
        return axios.post(`${Constants.API_END_POINT}/authorize-user`, data);
    } catch (error) {
        console.log("Error", error);
    }
}

const forceResetPassword = async (data) => {
    try {
        const response = await axios.post(`${Constants.API_END_POINT}/force-reset-password`, data, {
            'headers': {
                'Authorization': sessionStorage.getItem('token')
            }
        });
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const userRegisterOTP = async (data) => {
    try {
        const response = await axios.post(`https://api.interakt.ai/v1/public/message/`, data, {
            'headers': {
                'Authorization': 'Basic aTUxX2N4Q3VfakhXRElNWW5KWWgzNkVkUU1ENy1fR0I4RVpxYXpzOC1UMDo='
            }
        });
        return response;
    } catch (e) {
        console.log("Error", e);
    }
}

const userLoginOTP = async (data) => {
    try {
        return await axios.post(`${Constants.API_END_POINT}/send-otp`, data);
    } catch (e) {
        console.log("Error", e);
    }
}

const userLoginResendOTP = async (data) => {
    try {
        return await axios.post(`${Constants.API_END_POINT}/resend-otp`, data);
    } catch (e) {
        console.log("Error", e);
    }
}

const userVerifyOTP = async (data) => {
    try {
        return await axios.post(`${Constants.API_END_POINT}/verify-otp`, data);
    } catch (e) {
        console.log("Error", e);
    }
}

export { userLogin, userRegister, resetPassword, changePassword, emailConfirm, leadsCapture, userInfo, registerCodeVerify, emailVerify, forceResetPassword, userRegisterOTP, userLoginOTP, userVerifyOTP, userLoginResendOTP };