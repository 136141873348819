/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "./../store/Types";
import mergeImages from "merge-images-v2";
import { makeStyles } from "@material-ui/core/styles";
import reportBannerXL from "./../../assets/images/report/report-bg-xl.png";
import reportBannerL from "./../../assets/images/report/report-bg-l.png";
import reportBannerM from "./../../assets/images/report/report-bg-m.png";
import reportSecondSecBg from "./../../assets/images/report/report-bg-2.png";
import etqLogo from "./../../assets/images/report/etq-logo.png";
import etqLogoWhite from "./../../assets/images/report/etq-logo-white.png";
import treePic from "./../../assets/images/report/tree-pic.png";
import trust from "./../../assets/images/report/pp-b-1.svg";
import smartNetworks from "./../../assets/images/report/pp-b-2.svg";
import workMeaning from "./../../assets/images/report/pp-b-3.svg";
import socialBehaviour from "./../../assets/images/report/pp-b-4.svg";
import innovationThinking from "./../../assets/images/report/pp-b-5.svg";
import innovationBias from "./../../assets/images/report/pp-b-6.svg";
import innovationDemand from "./../../assets/images/report/pp-b-7.svg";
import entreplastixMind from "./../../assets/images/report/pp-b-8.svg";
import riskAversion from "./../../assets/images/report/pp-b-9.svg";
import comfortDiscomfort from "./../../assets/images/report/pp-b-10.svg";
import riskMitigation from "./../../assets/images/report/pp-b-11.svg";
import riskTaking from "./../../assets/images/report/pp-b-12.svg";
import thumbsUp from "./../../assets/images/report/thumbsup.svg";
import improvement from "./../../assets/images/report/improvement.svg";
import notApplicable from "./../../assets/images/report/not-applicable.svg";
import ponderBGM from "./../../assets/images/report/pp-bg-m.svg";
import ponderBGL from "./../../assets/images/report/pp-bg-l.svg";
import ponderBGXL from "./../../assets/images/report/pp-bg-xl.svg";
import BackButton from "./../../assets/images/report/back-button.svg";
import { ReactComponent as QuestorWhiteLogo } from "./../../assets/images/report/questor-white-logo.svg";
import questorLogo from "./../../assets/images/report/questor-logo.png";
import questorLogoPlus from "./../../assets/images/report/questor-logo-plus.png";
import footerBgM from "./../../assets/images/report/footer-bg-m.png";
import footerBgL from "./../../assets/images/report/footer-bg-l.png";
import footerBgXL from "./../../assets/images/report/footer-bg-xl.png";
import resultSkeleton from "./../../assets/images/report/axes-skeleton.png";
import AnimatedDrawer from "../common/AnimationDrawer";

// Scoring images
import HT0 from "./../../assets/images/report/new-scoring-icons/HT-0.png";
import HT1 from "./../../assets/images/report/new-scoring-icons/HT-1.png";
import HT2 from "./../../assets/images/report/new-scoring-icons/HT-2.png";
import HT3 from "./../../assets/images/report/new-scoring-icons/HT-3.png";
import HT4 from "./../../assets/images/report/new-scoring-icons/HT-4.png";
import IT0 from "./../../assets/images/report/new-scoring-icons/IT-0.png";
import IT1 from "./../../assets/images/report/new-scoring-icons/IT-1.png";
import IT2 from "./../../assets/images/report/new-scoring-icons/IT-2.png";
import IT3 from "./../../assets/images/report/new-scoring-icons/IT-3.png";
import IT4 from "./../../assets/images/report/new-scoring-icons/IT-4.png";
import RT0 from "./../../assets/images/report/new-scoring-icons/RT-0.png";
import RT1 from "./../../assets/images/report/new-scoring-icons/RT-1.png";
import RT2 from "./../../assets/images/report/new-scoring-icons/RT-2.png";
import RT3 from "./../../assets/images/report/new-scoring-icons/RT-3.png";
import RT4 from "./../../assets/images/report/new-scoring-icons/RT-4.png";
import testResultImage from "./../../assets/images/report/new-scoring-icons/score-sys.png";
import feedbackGood from "./../../assets/images/report/new-scoring-icons/feedback-good.png";
import feedbackGoodWhite from "./../../assets/images/report/new-scoring-icons/feedback-good-white.png";
import feedbackBad from "./../../assets/images/report/new-scoring-icons/feedback-bad.png";
import feedbackBadWhite from "./../../assets/images/report/new-scoring-icons/feedback-bad-white.png";
import feedbackOkay from "./../../assets/images/report/new-scoring-icons/feedback-okay.png";
import feedbackOkayWhite from "./../../assets/images/report/new-scoring-icons/feedback-okay-white.png";
import pointsPonderImg from "./../../assets/images/report/new-scoring-icons/pp-bg-s-final.png";
import CircularProgressBar from "../common/CircularProgressBar";
import SvgScoringSystem from "../common/SvgScoringSystem";
import SvgScoringSystemSM from "../common/SvgScoringSystemSM";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "1566px",
    backgroundImage: `url(${reportBannerXL})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      height: "840px",
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      backgroundImage: `url(${reportBannerM})`,
      height: "1045px",
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      backgroundImage: `url(${reportBannerL})`,
      height: "1175px",
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      backgroundImage: `url(${reportBannerL})`,
      height: "1246px",
      padding: "0 104px",
    },
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  reportBannerLeftSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportBannerRightSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportBannerContent: {
    font: "normal normal 300 24px/30px PoppinsSemiBold",
    textAlign: "left",
    color: "#fff",
    ["@media (max-width:1050px)"]: {
      margin: "26px 0",
    },
  },
  reportBannerSideContent: {
    display: "block",
    padding: "3rem",
    ["@media (max-width:1050px)"]: {
      padding: "0 2remS",
    },
  },
  reportSecondContainer: {
    backgroundImage: `url(${reportSecondSecBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    zIndex: "-1",
    marginTop: "-830px",
    position: "relative",
    height: "1920px",
    padding: "0 140px",
    ["@media (max-width:1282px)"]: {
      height: "1280px",
      marginTop: "-555px",
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      height: "1450px",
      marginTop: "-630px",
      padding: "0 108px",
    },
  },
  treeLeftSide: {
    marginTop: "555px",
    padding: "0 0 0 60px",
    textAlign: "left",
    position: "relative",
  },
  treeLeftSidePara: {
    color: "#6FBC44",
    font: "normal normal 500 21px/31px PoppinsMedium",
    position: "absolute",
    top: "30%",
    left: "15%",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 18px/24px PoppinsMedium",
      top: "0%",
      left: "0%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "8%",
      left: "0",
      font: "normal normal 500 18px/24px PoppinsMedium",
    },
    ["@media (min-width:1850px) and (max-width:1950px)"]: {
      left: "0",
      top: "22%",
    },
  },
  treeLeftSidePoints: {
    color: "#214697",
    font: "normal normal 600 21px/31px PoppinsSemiBold",
    textAlign: "right",
    position: "absolute",
    bottom: "25%",
    left: "50%",
    ["@media (max-width:1050px)"]: {
      left: "16%",
      bottom: "24%",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      left: "16%",
      bottom: "31%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      left: "30%",
      bottom: "29%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      left: "38%",
    },
    ["@media (min-width:1850px) and (max-width:1950px)"]: {
      left: "30%",
    },
  },
  treeRightSidePoints: {
    color: "#214697",
    font: "normal normal 600 21px/31px PoppinsSemiBold",
    textAlign: "left",
  },
  treeRightSide: {
    position: "absolute",
    bottom: "18%",
    right: "16%",
    ["@media (max-width:1050px)"]: {
      // right: "5%",
      bottom: "13.5%",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      // right: "5%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      // right: "8%",
    },

    ["@media (min-width:1750px) and (max-width:3000px)"]: {
      right: "20%",
    },
  },
  sectionTitle: {
    color: "#BE2431",
    font: "normal normal 300 44px/120px PoppinsSemiBold",
    margin: "36px 0",
    ["@media (max-width:1382px)"]: {
      font: "normal normal 300 30px/48px PoppinsSemiBold",
    },

    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 300 36px/48px PoppinsSemiBold",
    },
  },
  scoringSectionTitle: {
    color: "#000",
    font: "normal normal 600 48px/60px PoppinsSemiBold",
    marginTop: "20px",
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 600 24px/30px PoppinsSemiBold",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 21px/31px PoppinsSemiBold",
    },
  },
  sectionThumbnails: {
    color: "#000",
    font: "normal normal 600 16px/25px PoppinsSemiBold",
    padding: "4px 0px 10px",
    margin: "10px 0",
    ["@media (max-width:1050px)"]: {
      padding: "4px 0 10px",
    },
  },
  socialBehaviourSection: {
    display: "grid",
    justifyItems: "center",
    opacity: "50%",
  },
  humanThinkingSection: {
    padding: "0 8px",
  },
  para: {
    font: "normal normal 500 21px/31px PoppinsMedium",
    textAlign: "left",
    padding: "0 14px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 16px/25px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
    },
  },
  sectionThumbnailsImg: {
    width: "50px",
    height: "50px",
  },
  ppSection: {
    height: "1000px",
    width: "100%",
    backgroundImage: `url(${ponderBGXL})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "relative",
    marginTop: "-100px",
    ["@media (max-width:1282px)"]: {
      height: "680px",
      backgroundImage: `url(${ponderBGM})`,
      marginTop: "-60px",
    },
    ["@media (min-width:1283px) and (max-width:1512px)"]: {
      height: "860px",
      backgroundImage: `url(${ponderBGL})`,
    },
  },
  pp1: {
    position: "absolute",
    left: "23%",
    top: "15%",
    cursor: "pointer",
    width: "170px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "25%",
      top: "16%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "100px",
      left: "25%",
      top: "16%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "16%",
      width: "140px",
      left: "23.5%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "17%",
      width: "140px",
      left: "22.5%",
    },
  },
  pp2: {
    position: "absolute",
    left: "37%",
    top: "19%",
    cursor: "pointer",
    width: "150px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "38%",
      top: "16%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "100px",
      left: "38.3%",
      top: "19%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "20%",
      width: "120px",
      left: "38.5%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "21%",
      width: "120px",
      left: "37%",
    },
  },
  pp3: {
    position: "absolute",
    left: "51%",
    top: "19%",
    cursor: "pointer",
    width: "150px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "53%",
      top: "16%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "100px",
      left: "52.5%",
      top: "21%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "22%",
      width: "120px",
      left: "53%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "22%",
      left: "50.6%",
      width: "120px",
    },
  },
  pp4: {
    position: "absolute",
    left: "63.7%",
    top: "18%",
    cursor: "pointer",
    width: "170px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "66%",
      top: "14%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "110px",
      left: "66%",
      top: "19%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "20%",
      width: "130px",
      left: "66%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "22%",
      width: "130px",
      left: "63%",
    },
  },
  pp5: {
    position: "absolute",
    left: "15.5%",
    top: "40%",
    cursor: "pointer",
    width: "182px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "17%",
      top: "34%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "110px",
      left: "18%",
      top: "41%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      width: "120px",
      top: "40%",
      left: "17%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "41%",
      width: "136px",
      left: "16%",
    },
  },
  pp6: {
    position: "absolute",
    left: "29.5%",
    top: "40%",
    cursor: "pointer",
    width: "180px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "32%",
      top: "30%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "110px",
      left: "31.5%",
      top: "41%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "40%",
      width: "120px",
      left: "31.5%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "41%",
      width: "136px",
      left: "29.5%",
    },
  },
  pp7: {
    position: "absolute",
    left: "57%",
    top: "41%",
    cursor: "pointer",
    width: "165px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "59%",
      top: "32%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "110px",
      left: "59%",
      top: "41%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "41%",
      width: "130px",
      left: "59.5%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "41%",
      width: "136px",
      left: "56.3%",
    },
  },
  pp8: {
    position: "absolute",
    left: "70.3%",
    top: "35%",
    cursor: "pointer",
    width: "180px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "72%",
      top: "32%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "116px",
      left: "72.5%",
      top: "36%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "35%",
      width: "140px",
      left: "73.3%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "38%",
      width: "136px",
      left: "70%",
    },
  },
  pp9: {
    position: "absolute",
    left: "23.5%",
    top: "63%",
    cursor: "pointer",
    width: "150px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "25%",
      top: "48%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "100px",
      left: "25.5%",
      top: "63%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "60%",
      width: "120px",
      left: "25%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "62%",
      width: "120px",
      left: "23.5%",
    },
  },
  pp10: {
    position: "absolute",
    left: "36.2%",
    top: "60%",
    cursor: "pointer",
    width: "180px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "38%",
      top: "50%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "116px",
      left: "38%",
      top: "59%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "57%",
      width: "130px",
      left: "38%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "59%",
      width: "136px",
      left: "36%",
    },
  },
  pp11: {
    position: "absolute",
    left: "50%",
    top: "60%",
    cursor: "pointer",
    width: "180px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "52%",
      top: "50%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "100px",
      left: "52.3%",
      top: "60%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "57%",
      width: "120px",
      left: "52.5%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "59%",
      width: "128px",
      left: "50%",
    },
  },
  pp12: {
    position: "absolute",
    left: "63%",
    top: "62%",
    cursor: "pointer",
    width: "180px",
    ["@media (max-width:1050px)"]: {
      width: "90px",
      left: "66%",
      top: "50%",
    },
    ["@media (min-width:1052px) and  (max-width:1282px)"]: {
      width: "120px",
      left: "65%",
      top: "60%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      top: "58%",
      width: "130px",
      left: "66%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "61%",
      width: "128px",
      left: "63%",
    },
  },
  footer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${footerBgXL})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "480px",
    width: "100%",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      height: "260px",
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      height: "316px",
      backgroundImage: `url(${footerBgM})`,
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      height: "360px",
      backgroundSize: "cover",
      backgroundImage: `url(${footerBgL})`,
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      height: "380px",
      padding: "0 104px",
    },
  },
  treeImage: {
    height: "640px",
    position: "absolute",
    bottom: "100px",
    left: "43%",
    ["@media (max-width:1200px)"]: {
      left: "42%",
      height: "400px",
    },
    ["@media (min-width:1202px) and (max-width:1282px)"]: {
      height: "400px",
      backgroundImage: `url(${footerBgM})`,
      left: "44%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      height: "486px",
    },

    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      left: "41.5%",
    },
  },
  treeImageText: {
    font: "normal normal 300 21px/25px PoppinsSemiBold",
    position: "absolute",
    margin: "0 auto",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
    },
  },
  treeImageFirstTextRotated: {
    font: "normal normal 300 24px/36px PoppinsSemiBold",
    position: "absolute",
    margin: "0 auto",
    top: "52.5%",
    right: "39%",
    transform: "rotate(270deg)",
    ["@media (max-width:1050px)"]: {
      right: "36%",
      font: "normal normal 300 16px/25px PoppinsSemiBold",
      top: "52%",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      font: "normal normal 300 16px/25px PoppinsSemiBold",
      right: "38%",
      top: "52.3%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
      right: "38%",
      top: "51.3%",
    },

    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "52.7%",
      right: "35%",
    },
  },
  treeImageSecondTextRotated: {
    font: "normal normal 300 24px/36px PoppinsSemiBold",
    position: "absolute",
    margin: "0 auto",
    top: "51%",
    right: "42.2%",
    transform: "rotate(270deg)",
    ["@media (max-width:1050px)"]: {
      right: "40%",
      top: "52%",
      font: "normal normal 300 16px/25px PoppinsSemiBold",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      font: "normal normal 300 16px/25px PoppinsSemiBold",
      right: "42%",
      top: "51%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
      right: "42%",
      top: "51%",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "51.5%",
      right: "40.2%",
    },
  },
  treeImageThirdTextRotated: {
    font: "normal normal 300 24px/36px PoppinsSemiBold",
    position: "absolute",
    margin: "0 auto",
    top: "52%",
    right: "48.8%",
    transform: "rotate(270deg)",
    ["@media (max-width:1050px)"]: {
      font: "normal normal 300 16px/25px PoppinsSemiBold",
      right: "48%",
      top: "52%",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      font: "normal normal 300 16px/25px PoppinsSemiBold",
      right: "48%",
      top: "52%",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      font: "normal normal 600 18px/24px PoppinsSemiBold",
      right: "48.5%",
      top: "50.7%",
    },

    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      top: "52.3%",
      right: "48%",
    },
  },

  bannerfooter: {
    display: "flex",
    alignItems: "flex-end",
    color: "#fff",
  },
  testResultContainer: {
    backgroundImage: `url(${resultSkeleton})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    height: "750px",
    marginTop: "-100px",
    position: "relative",
    ["@media (max-width:1282px)"]: {},
    ["@media (max-width:1442px)"]: {
      height: "650px",
    },
  },
  resultOutcomeIcon: {
    width: "75px",
  },
  innovationTitle: {
    font: "normal normal 600 48px/60px PoppinsSemiBold",
    color: "#214697",
    ["@media (max-width:1442px)"]: {
      font: "normal normal 600 34px/48px PoppinsSemiBold",
    },
    ["@media (max-width:1337px)"]: {
      font: "normal normal 600 24px/36px PoppinsSemiBold",
    },
  },
  humanTitle: {
    font: "normal normal 600 48px/60px PoppinsSemiBold",
    color: "#BE2431",
    textAlign: "left",
    ["@media (max-width:1442px)"]: {
      font: "normal normal 600 34px/48px PoppinsSemiBold",
    },
    ["@media (max-width:1337px)"]: {
      font: "normal normal 600 24px/36px PoppinsSemiBold",
    },
  },
  riskTitle: {
    font: "normal normal 600 48px/60px PoppinsSemiBold",
    color: "#FFCD05",
    ["@media (max-width:1442px)"]: {
      font: "normal normal 600 34px/48px PoppinsSemiBold",
    },
    ["@media (max-width:1337px)"]: {
      font: "normal normal 600 24px/36px PoppinsSemiBold",
    },
  },
  testResultContainerLeftSection: {
    // textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  testResultContainerRightSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  trainingTitle: {
    font: "normal normal 300 34px/48px PoppinsSemiBold",
    color: "#000",
  },
  footerLogo: {
    width: "260px",
    marginBottom: "24px",
    ["@media (max-width:1282px)"]: {
      width: "164px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      width: "192px",
    },
  },
  testResultImage: {
    maxWidth: "100%",
  },
  reportCustomContainer: {
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },

  backButton: {
    position: "absolute",
    top: "150px",
    cursor: "pointer",
    width: "122px",
    height: "122px",
    ["@media (max-width:1282px)"]: {
      width: "74px",
      height: "74px",
      top: "100px",
    },
    ["@media (min-width:1283px) and (max-width:1512px)"]: {
      width: "88px",
      height: "88px",
      top: "120px",
    },
  },
  posRelative: {
    position: "relative",
  },
}));

const Report = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ppNumber, setPpNumber] = useState(0);
  const [ppType, setPpType] = useState(0);
  const [staticContent, setStaticContent] = React.useState([]);
  const ReportDetailsContent = useSelector(
    (state) => state.TestReducer.reportDetails.data
  );
  const TestReducer = useSelector((state) => state.TestReducer);
  const [feedbackBadIcon, setFeedbackBadIcon] = useState(false);
  const [feedbackGoodIcon, setFeedbackGoodIcon] = useState(false);
  const [feedbackOkayIcon, setFeedbackOkayIcon] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [width, height] = useWindowSize();
  const history = useHistory();

  const openSelectedOutcomeDescription = (type, ppNumber) => (event) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "block";
    setPpNumber(ppNumber);
    setPpType(type);
  };

  const closeSelectedOutcomeDescription = (type) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
  };

  const getScoringImage = (htScore, itScore, rtScore) => {
    let htImage, itImage, rtImage;
    if (htScore === 1) {
      htImage = HT0;
    } else if (htScore === 2) {
      htImage = HT1;
    } else if (htScore === 3) {
      htImage = HT2;
    } else if (htScore === 4) {
      htImage = HT3;
    } else {
      htImage = HT4;
    }

    if (itScore === 1) {
      itImage = IT0;
    } else if (itScore === 2) {
      itImage = IT1;
    } else if (itScore === 3) {
      itImage = IT2;
    } else if (itScore === 4) {
      itImage = IT3;
    } else {
      itImage = IT4;
    }

    if (rtScore === 1) {
      rtImage = RT0;
    } else if (rtScore === 2) {
      rtImage = RT1;
    } else if (rtScore === 3) {
      rtImage = RT2;
    } else if (rtScore === 4) {
      rtImage = RT3;
    } else {
      rtImage = RT4;
    }

    return mergeImages([htImage, itImage, rtImage]).then(
      (b64) => (document.getElementById("scoringImage").src = b64)
    );
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.outerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    dispatch({
      type: Types.GET_REPORT_DETAILS,
    });
    return () => {
      console.log("returned");
    };
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("./points-ponder-content.json")
  //     .then((res) => setStaticContent(res.data))
  //     .catch((err) =>
  //       console.log("Error occured while fetching static data from json file")
  //     );
  //   return () => {
  //     console.log("returned");
  //   };
  // }, []);

  useEffect(() => {
    if (TestReducer.isFeedbackSubmitted) {
      document.querySelector("#feedback-btn").setAttribute("disabled", true);
      document.querySelector("#feedback-btn").innerHTML = "Think! You";
      document
        .querySelector("#feedback-btn")
        .classList.add("feedback-submitted");
      setFeedbackBadIcon(true);
      setFeedbackOkayIcon(true);
      setFeedbackGoodIcon(true);
      disbaleFeedbackImages();
    }
    return () => {
      console.log("clean up");
    };
  }, [TestReducer.isFeedbackSubmitted]);

  const onMouseHoveringFeedbackIcon = (event, type) => {
    if (type === "sad") {
      if (!feedbackBadIcon) {
        event.currentTarget.src = feedbackBad;
      }
    }
    if (type === "neutral") {
      if (!feedbackOkayIcon) {
        event.currentTarget.src = feedbackOkay;
      }
    }
    if (type === "happy") {
      if (!feedbackGoodIcon) {
        event.currentTarget.src = feedbackGood;
      }
    }
  };
  const onMouseLeavingFeedbackIcon = (event, type) => {
    if (type === "sad") {
      if (!feedbackBadIcon) {
        event.currentTarget.src = feedbackBadWhite;
      }
    }
    if (type === "neutral") {
      if (!feedbackOkayIcon) {
        event.currentTarget.src = feedbackOkayWhite;
      }
    }
    if (type === "happy") {
      if (!feedbackGoodIcon) {
        event.currentTarget.src = feedbackGoodWhite;
      }
    }
  };

  const setFeedbackBadStatus = (event, feedback) => {
    event.currentTarget.src = feedbackBad;
    setSelectedFeedback(feedback);
    setFeedbackBadIcon(true);
    setFeedbackOkayIcon(false);
    setFeedbackGoodIcon(false);
    document
      .querySelector("#feedback-good")
      .setAttribute("src", feedbackGoodWhite);
    document
      .querySelector("#feedback-okay")
      .setAttribute("src", feedbackOkayWhite);
  };

  const setFeedbackOkayStatus = (event, feedback) => {
    event.currentTarget.src = feedbackOkay;
    setSelectedFeedback(feedback);
    setFeedbackOkayIcon(true);
    setFeedbackBadIcon(false);
    setFeedbackGoodIcon(false);
    document
      .querySelector("#feedback-bad")
      .setAttribute("src", feedbackBadWhite);
    document
      .querySelector("#feedback-good")
      .setAttribute("src", feedbackGoodWhite);
  };
  const setFeedbackGoodStatus = (event, feedback) => {
    event.currentTarget.src = feedbackGood;
    setSelectedFeedback(feedback);
    setFeedbackGoodIcon(true);
    setFeedbackBadIcon(false);
    setFeedbackOkayIcon(false);
    document
      .querySelector("#feedback-bad")
      .setAttribute("src", feedbackBadWhite);
    document
      .querySelector("#feedback-okay")
      .setAttribute("src", feedbackOkayWhite);
  };

  const handleFeedbackSubmit = (event) => {
    let feedback = {
      feedback: selectedFeedback,
      userId: 171,
      assessmentAttemptNo: 2,
    };
    dispatch({
      type: Types.POST_FEEDBACK,
      payload: feedback,
    });
  };

  const disbaleFeedbackImages = () => {
    document.getElementById("feedback-bad").classList.add("disbaleImage");
    document.getElementById("feedback-okay").classList.add("disbaleImage");
    document.getElementById("feedback-good").classList.add("disbaleImage");
  };

  const backToWelcome = () => {
    dispatch({
      type: Types.GET_USER_INFO,
    });
    history.push("/welcome");
  };

  const HTReportDetailsContent = {
    ...ReportDetailsContent,
    itAvgScore: 0,
    rtAvgScore: 0,
  };
  const ITReportDetailsContent = {
    ...ReportDetailsContent,
    htAvgScore: 0,
    rtAvgScore: 0,
  };
  const RTReportDetailsContent = {
    ...ReportDetailsContent,
    htAvgScore: 0,
    itAvgScore: 0,
  };

  return (
    <div>
      {ReportDetailsContent ? (
        <div>
          <Grid container component="main" className={classes.root}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className={classes.reportBannerLeftSide}
            >
              <QuestorWhiteLogo />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className={classes.reportBannerRightSide}
            >
              <div className={classes.reportBannerSideContent}>
                <h5 className={classes.reportBannerContent}>
                  Hi {ReportDetailsContent && ReportDetailsContent.name},{" "}
                </h5>
                <h5 className={classes.reportBannerContent}>
                  Welcome to ETQ®{" "}
                </h5>
                <h5 className={classes.reportBannerContent}>
                  We are delighted that you took a few minutes of your precious
                  time to create awareness about your entreplastic™ potential.
                </h5>
                <h5 className={classes.reportBannerContent}>
                  Here is your personalised report.
                </h5>

                <div className={classes.bannerfooter}>
                  <p className="copyRight" style={{ margin: "0 40px 0 0" }}>
                    © 2023 ETQ Confidential. All Rights Reserved.
                  </p>
                  <div>
                    <img src={etqLogoWhite} alt="" width={60} />
                  </div>
                </div>
              </div>
            </Grid>
            <img
              src={BackButton}
              alt=""
              onClick={backToWelcome}
              className={classes.backButton}
            />
          </Grid>
          <Grid container className={classes.reportSecondContainer}>
            <Grid item xs={12} sm={4} md={4} className={classes.treeLeftSide}>
              <p className={classes.treeLeftSidePara}>
                The bamboo is a symbol of growth, strength, flexibility and
                resilience, representing your mind’s dynamic potential.
                Initially, the bamboo seeds only absorb nutrients from the soil,
                which enables it to grow rapidly, later on. This mirrors your
                mind’s entreplastic™ (entrepreneurial mindset +
                neuro-plasticity) potential.
              </p>
              <div className={classes.treeLeftSidePoints}>
                <p>
                  {" "}
                  Lifelong Benefit
                  <br />
                  <br />
                  {/* </p>
                <p class="treeLeftSidePointsPara"> */}
                  Be the best version of oneself
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <p className={classes.treeImageFirstTextRotated}>
                Right Business Ideas
              </p>
              <p className={classes.treeImageSecondTextRotated}>
                Right Employment Track
              </p>
              <p className={classes.treeImageThirdTextRotated}>
                Right Education Track
              </p>
              <img className={classes.treeImage} src={treePic} alt="" />
              <p style={{ bottom: "70px" }} className={classes.treeImageText}>
                Human Thinking
              </p>
              <p style={{ bottom: "40px" }} className={classes.treeImageText}>
                Innovation Thinking
              </p>
              <p style={{ bottom: "10px" }} className={classes.treeImageText}>
                Risk Thinking
              </p>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.treeRightSide}>
                <div className={classes.treeRightSidePoints}>
                  <p class="hide-lg">
                    Entreplasticity™
                    <br />
                    <br />
                    Nurture the rewiring of <br /> one’s brain
                  </p>
                  <p class="show-lg">
                    Entreplasticity™
                    <br />
                    <br />
                    Nurture the rewiring of one’s brain
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div class="vl" style={{ height: "200px" }}></div>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "36px 0",
            }}
            className={classes.reportCustomContainer}
          >
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.sectionTitle} style={{ color: "#000" }}>
                Entreplastic™ Equilibrium: Defined
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ alignItems: "center" }}
          >
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={2} md={2}>
              <h4 className={classes.sectionSubTitle} style={{ margin: "0" }}>
                <span class="yellow-color">
                  {" "}
                  Risk <br /> Thinking
                </span>
              </h4>
            </Grid>
            <Grid item xs={12} sm={7} md={7}></Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "36px" }}
          >
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <h4
                className={classes.sectionSubTitle}
                style={{ textAlign: "right" }}
              >
                <span class="red-color">
                  Human <br /> Thinking{" "}
                </span>
              </h4>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <img
                src={testResultImage}
                alt=""
                className={classes.testResultImage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              style={{ display: "flex", alignItems: "end" }}
            >
              <h4
                className={classes.sectionSubTitle}
                style={{ textAlign: "left" }}
              >
                <span class="blue-color">
                  {" "}
                  Innovation <br /> Thinking{" "}
                </span>
              </h4>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div class="potential-content">
                <p>
                  The better you perform on{" "}
                  <span class="red-color"> Human</span>,{" "}
                  <span class="blue-color">Innovation</span> &{" "}
                  <span class="yellow-color">Risk</span> Thinking, the closer
                  you will be to achieving Entreplastic™ Equilibrium. This, in
                  turn, can lead to your optimal growth, like the bamboo plant.
                </p>
                <p>
                  How to assess your proximity to Entreplastic™ Equilibrium:
                  <p> 0 to &lt;2 - Needs Attention </p>
                  <p> 2 to &lt;3 - Moderate </p>
                  <p> 3 to &lt;4 - Strong </p>
                  <p> 4 to &lt;5 - Nearing Entreplastic™ Equilibrium</p>
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div class="vl"></div>
          </Grid>

          {/* Test Result */}
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.scoringSectionTitle}>
                Your <img src={etqLogo} width="100" alt="" />
              </h4>
            </Grid>
          </Grid>

          <Grid container className={classes.reportCustomContainer}>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <div>
                <p className={classes.humanTitle}>
                  {ReportDetailsContent && ReportDetailsContent.htChartCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.posRelative}>
              <div>
                <p
                  className={classes.riskTitle}
                  style={{ textAlign: "center" }}
                >
                  {ReportDetailsContent && ReportDetailsContent.rtChartCaption}
                </p>
              </div>
              <SvgScoringSystem data={ReportDetailsContent}></SvgScoringSystem>
              {/* <svg
                id="Full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 800 740"
              >
                <g id="Axes_Scoring_All" data-name="Axes Scoring All">
                  <g
                    id="HT_4-5"
                    data-name="HT 4-5"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      5
                    )}
                  >
                    <polygon
                      class="cls-3"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-3"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                    <polygon
                      class="cls-3"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g
                    id="HT_3-4"
                    data-name="HT 3-4"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      4
                    )}
                  >
                    <polygon
                      class="cls-3"
                      points="240 480 320 440 400 480 320 520 240 480"
                    />
                    <polygon
                      class="cls-3"
                      points="240 480 240 580 320 620 320 520 240 480"
                    />
                    <polygon
                      class="cls-3"
                      points="400 480 400 580 320 620 320 520 400 480"
                    />
                  </g>
                  <g
                    id="HT_2-3"
                    data-name="HT 2-3"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      3
                    )}
                  >
                    <polygon
                      class="cls-3"
                      points="160 520 240 480 320 520 240 560 160 520"
                    />
                    <polygon
                      class="cls-3"
                      points="160 520 160 620 240 660 240 560 160 520"
                    />
                    <polygon
                      class="cls-3"
                      points="320 520 320 620 240 660 240 560 320 520"
                    />
                  </g>
                  <g
                    id="HT_1-2"
                    data-name="HT 1-2"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      2
                    )}
                  >
                    <polygon
                      class="cls-3"
                      points="80 560 160 520 240 560 160 600 80 560"
                    />
                    <polygon
                      class="cls-3"
                      points="80 560 80 660 160 700 160 600 80 560"
                    />
                    <polygon
                      class="cls-3"
                      points="240 560 240 660 160 700 160 600 240 560"
                    />
                  </g>
                  <g
                    id="HT_0-1"
                    data-name="HT 0-1"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      1
                    )}
                  >
                    <polygon
                      class="cls-3"
                      points="0 600 80 560 160 600 80 640 0 600"
                    />
                    <polygon
                      class="cls-3"
                      points="0 600 0 700 80 740 80 640 0 600"
                    />
                    <polygon
                      class="cls-3"
                      points="160 600 160 700 80 740 80 640 160 600"
                    />
                  </g>
                  <g
                    id="IT_4-5"
                    data-name="IT 4-5"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      5
                    )}
                  >
                    <polygon
                      class="cls-5"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-5"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                    <polygon
                      class="cls-5"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g
                    id="IT_3-4"
                    data-name="IT 3-4"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      4
                    )}
                  >
                    <polygon
                      class="cls-5"
                      points="400 480 480 440 560 480 480 520 400 480"
                    />
                    <polygon
                      class="cls-5"
                      points="400 480 400 580 480 620 480 520 400 480"
                    />
                    <polygon
                      class="cls-5"
                      points="560 480 560 580 480 620 480 520 560 480"
                    />
                  </g>
                  <g
                    id="IT_2-3"
                    data-name="IT 2-3"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      3
                    )}
                  >
                    <polygon
                      class="cls-5"
                      points="480 520 560 480 640 520 560 560 480 520"
                    />
                    <polygon
                      class="cls-5"
                      points="480 520 480 620 560 660 560 560 480 520"
                    />
                    <polygon
                      class="cls-5"
                      points="640 520 640 620 560 660 560 560 640 520"
                    />
                  </g>
                  <g
                    id="IT_1-2"
                    data-name="IT 1-2"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      2
                    )}
                  >
                    <polygon
                      class="cls-5"
                      points="560 560 640 520 720 560 640 600 560 560"
                    />
                    <polygon
                      class="cls-5"
                      points="560 560 560 660 640 700 640 600 560 560"
                    />
                    <polygon
                      class="cls-5"
                      points="720 560 720 660 640 700 640 600 720 560"
                    />
                  </g>
                  <g
                    id="IT_0-1"
                    data-name="IT 0-1"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      1
                    )}
                  >
                    <polygon
                      class="cls-5"
                      points="640 600 720 560 800 600 720 640 640 600"
                    />
                    <polygon
                      class="cls-5"
                      points="640 600 640 700 720 740 720 640 640 600"
                    />
                    <polygon
                      class="cls-5"
                      points="800 600 800 700 720 740 720 640 800 600"
                    />
                  </g>
                  <g
                    id="RT_4-5"
                    data-name="RT 4-5"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.rtAvgScore),
                      5
                    )}
                  >
                    <polygon
                      class="cls-1"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-1"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                    <polygon
                      class="cls-1"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g
                    id="RT_3-4"
                    data-name="RT 3-4"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.rtAvgScore),
                      4
                    )}
                  >
                    <polygon
                      class="cls-1"
                      points="320 340 400 300 480 340 400 380 320 340"
                    />
                    <polygon
                      class="cls-1"
                      points="320 340 320 440 400 480 400 380 320 340"
                    />
                    <polygon
                      class="cls-1"
                      points="480 340 480 440 400 480 400 380 480 340"
                    />
                  </g>
                  <g
                    id="RT_2-3"
                    data-name="RT 2-3"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.rtAvgScore),
                      3
                    )}
                  >
                    <polygon
                      class="cls-1"
                      points="320 240 400 200 480 240 400 280 320 240"
                    />
                    <polygon
                      class="cls-1"
                      points="320 240 320 340 400 380 400 280 320 240"
                    />
                    <polygon
                      class="cls-1"
                      points="480 240 480 340 400 380 400 280 480 240"
                    />
                  </g>
                  <g
                    id="RT_1-2"
                    data-name="RT 1-2"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.rtAvgScore),
                      2
                    )}
                  >
                    <polygon
                      class="cls-1"
                      points="320 140 400 100 480 140 400 180 320 140"
                    />
                    <polygon
                      class="cls-1"
                      points="320 140 320 240 400 280 400 180 320 140"
                    />
                    <polygon
                      class="cls-1"
                      points="480 140 480 240 400 280 400 180 480 140"
                    />
                  </g>
                  <g
                    id="RT_0-1"
                    data-name="RT 0-1"
                    className={getPolyganClass(
                      Math.ceil(ReportDetailsContent.rtAvgScore),
                      1
                    )}
                  >
                    <polygon
                      class="cls-1"
                      points="320 40 400 0 480 40 400 80 320 40"
                    />
                    <polygon
                      class="cls-1"
                      points="320 40 320 140 400 180 400 80 320 40"
                    />
                    <polygon
                      class="cls-1"
                      points="480 40 480 140 400 180 400 80 480 40"
                    />
                  </g>
                  <g
                    id="IT_HT_4-5"
                    data-name="IT + HT 4-5"
                    className={getDualPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      Math.ceil(ReportDetailsContent.itAvgScore)
                    )}
                  >
                    <polygon
                      class="cls-4"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                    <polygon
                      class="cls-6"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g
                    id="RT_HT_4-5"
                    data-name="RT + HT 4-5"
                    className={getDualPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      Math.ceil(ReportDetailsContent.rtAvgScore)
                    )}
                  >
                    <polygon
                      class="cls-2"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-4"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                  </g>
                  <g
                    id="RT_IT_4-5"
                    data-name="RT + IT 4-5"
                    className={getDualPolyganClass(
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      Math.ceil(ReportDetailsContent.itAvgScore)
                    )}
                  >
                    <polygon
                      class="cls-2"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-6"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g
                    id="All_4-5"
                    data-name="All 4-5"
                    className={getTribalPolyganClass(
                      Math.ceil(ReportDetailsContent.htAvgScore),
                      Math.ceil(ReportDetailsContent.itAvgScore),
                      Math.ceil(ReportDetailsContent.rtAvgScore)
                    )}
                  >
                    <polygon
                      class="cls-2"
                      points="320 440 400 400 480 440 400 480 320 440"
                    />
                    <polygon
                      class="cls-4"
                      points="320 440 320 540 400 580 400 480 320 440"
                    />
                    <polygon
                      class="cls-6"
                      points="480 440 480 540 400 580 400 480 480 440"
                    />
                  </g>
                  <g id="All_4-5_Box" data-name="All 4-5 Box">
                    <path
                      class="cls-7"
                      d="m400,401.12l77.76,38.88-77.76,38.88-77.76-38.88,77.76-38.88m0-1.12l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m321,441.62l78,39v97.76l-78-39v-97.76m-1-1.62v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m479,441.62v97.76l-78,39v-97.76l78-39m1-1.62l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="RT_3-4_Box" data-name="RT 3-4 Box">
                    <path
                      class="cls-7"
                      d="m400,300.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m320.25,340.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m479.75,340.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="RT_2-3_Box" data-name="RT 2-3 Box">
                    <path
                      class="cls-7"
                      d="m400,200.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m320.25,240.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m479.75,240.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="RT_1-2_Box" data-name="RT 1-2 Box">
                    <path
                      class="cls-7"
                      d="m400,100.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m320.25,140.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m479.75,140.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="RT_0-1_Box" data-name="RT 0-1 Box">
                    <path
                      class="cls-7"
                      d="m400,.28l79.44,39.72-79.44,39.72-79.44-39.72L400,.28m0-.28l-80,40,80,40,80-40L400,0h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m320.25,40.4l79.5,39.75v99.44l-79.5-39.75V40.4m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m479.75,40.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40V40h0Z"
                    />
                  </g>
                  <g id="HT_3-4_Box" data-name="HT 3-4 Box">
                    <path
                      class="cls-7"
                      d="m320,440.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m240.25,480.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m399.75,480.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="HT_2-3_Box" data-name="HT 2-3 Box">
                    <path
                      class="cls-7"
                      d="m240,480.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m160.25,520.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m319.75,520.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="HT_1-2_Box" data-name="HT 1-2 Box">
                    <path
                      class="cls-7"
                      d="m160,520.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m80.25,560.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m239.75,560.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="HT_0-1_Box" data-name="HT 0-1 Box">
                    <path
                      class="cls-7"
                      d="m80,560.28l79.44,39.72-79.44,39.72L.56,600l79.44-39.72m0-.28L0,600l80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m.25,600.4l79.5,39.75v99.44L.25,699.85v-99.44m-.25-.4v100l80,40v-100L0,600h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m159.75,600.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="IT_3-4_Box" data-name="IT 3-4 Box">
                    <path
                      class="cls-7"
                      d="m480,440.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m400.25,480.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m559.75,480.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="IT_2-3_Box" data-name="IT 2-3 Box">
                    <path
                      class="cls-7"
                      d="m560,480.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m480.25,520.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m639.75,520.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="IT_1-2_Box" data-name="IT 1-2 Box">
                    <path
                      class="cls-7"
                      d="m640,520.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m560.25,560.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m719.75,560.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="IT_0-1_Box" data-name="IT 0-1 Box">
                    <path
                      class="cls-7"
                      d="m720,560.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m640.25,600.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
                    />
                    <path
                      class="cls-7"
                      d="m799.75,600.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
                    />
                  </g>
                  <g id="RT_0" data-name="RT 0">
                    <path
                      class="cls-7"
                      d="m391.32,27.82c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1,2.61,6.51,2.61,11.33-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47s1.99,1.32,3.55,1.32,2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
                    />
                  </g>
                  <g id="HT_0" data-name="HT 0">
                    <path
                      class="cls-7"
                      d="m31.32,658.61c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1c1.74,2.73,2.61,6.51,2.61,11.33s-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47s1.99,1.32,3.55,1.32,2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
                    />
                  </g>
                  <g id="IT_0" data-name="IT 0">
                    <path
                      class="cls-7"
                      d="m752.92,656.58c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1c1.74,2.73,2.61,6.51,2.61,11.33s-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47.81.88,1.99,1.32,3.55,1.32s2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
                    />
                  </g>
                  <g id="_5" data-name="5">
                    <path d="m416.95,466.62h-23.23v12.26c1-1.24,2.44-2.26,4.3-3.05,1.86-.79,3.85-1.18,5.95-1.18,3.82,0,6.95.84,9.39,2.51,2.44,1.67,4.2,3.81,5.3,6.42,1.1,2.61,1.65,5.39,1.65,8.35,0,5.5-1.57,9.92-4.7,13.26-3.13,3.34-7.59,5.02-13.37,5.02-5.45,0-9.8-1.36-13.05-4.09-3.25-2.72-5.09-6.28-5.52-10.68h9.75c.43,1.91,1.4,3.44,2.9,4.59,1.51,1.15,3.43,1.72,5.77,1.72,2.82,0,4.95-.88,6.38-2.65,1.43-1.77,2.15-4.11,2.15-7.02s-.73-5.22-2.19-6.77c-1.46-1.55-3.6-2.33-6.42-2.33-2.01,0-3.68.5-5.02,1.51-1.34,1-2.29,2.34-2.87,4.01h-9.6v-30.68h32.4v8.82Z" />
                  </g>
                </g>
              </svg> */}
              <div class="resultLineContainer">
                <div class="vl"></div>
                <p
                  className={classes.sectionSubTitle}
                  style={{ margin: "0 0 0 -70px" }}
                >
                  Entreplastic™ <br /> Equilibrium
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <div>
                <p className={classes.innovationTitle}>
                  {ReportDetailsContent && ReportDetailsContent.itChartCaption}
                </p>
              </div>
            </Grid>
          </Grid>

          {/* <Grid container className={classes.reportCustomContainer}>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.testResultContainerLeftSection}
            >
              <div>
                <p className={classes.humanTitle}>
                  {ReportDetailsContent && ReportDetailsContent.htChartCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <p className={classes.riskTitle} style={{ marginTop: "0px" }}>
                {ReportDetailsContent && ReportDetailsContent.rtChartCaption}
              </p>
              <div class="resultImgContainer">
                <img
                  src={getScoringImage(
                    Math.ceil(ReportDetailsContent.htAvgScore),
                    Math.ceil(ReportDetailsContent.itAvgScore),
                    Math.ceil(ReportDetailsContent.rtAvgScore)
                  )}
                  alt=""
                  className={"resultImg"}
                  id="scoringImage"
                />
                <div class="resultLineContainer">
                  <div class="vl"></div>
                  <p
                    className={classes.sectionSubTitle}
                    style={{ margin: "0 0 0 -70px" }}
                  >
                    Entreplastic™ <br /> Equilibrium
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.testResultContainerRightSection}
            >
              <p className={classes.innovationTitle}>
                {ReportDetailsContent && ReportDetailsContent.itChartCaption}
              </p>
            </Grid>
          </Grid> */}
          <Grid
            container
            style={{ justifyContent: "center", marginTop: "4rem" }}
          >
            <div class="vl"></div>
          </Grid>
          {/* Human Thinking */}
          <Grid
            container
            className={classes.humanThinkingSecContainer}
            id="humanThinkingSection"
          >
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.sectionTitle}>Human Thinking</h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={2} md={2}></Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.humanThinkingSection}
            >
              <img src={trust} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>
                Trustful Professional Relationships
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.humanThinkingSection}
            >
              <img src={smartNetworks} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>
                Building Smart Networks
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.humanThinkingSection}
            >
              <img src={workMeaning} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Work & Meaning</h4>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.humanThinkingSection}
            >
              <img src={socialBehaviour} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Social Behaviour</h4>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.socialBehaviourSection}
              style={{ opacity: "50%" }}
            >
              <img
                src={socialBehaviour}
                alt=""
                class="ht-icon"
                style={{ visibility: "hidden" }}
              />
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#626262", marginTop: "-26px" }}
              >
                Key
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#BE2431" }}
              >
                Mindset
              </h4>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMindsetTrustfulProfessionalRelationships}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMindsetTrustfulProfessionalRelationships
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMindsetBuildingSmartNetworks}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMindsetBuildingSmartNetworks
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMindsetWorkAndMenaing}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMindsetWorkAndMenaing
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMindsetSocialBehaviour}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMindsetSocialBehaviour
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ opacity: "50%" }}>
              <img src={improvement} alt="" class="thumb-icon" />
              <h4 className={classes.sectionThumbnails}>
                Scope for Improvement
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={2} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#BE2431" }}
              >
                Biases
              </h4>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/not-applicable.svg`)
                    .default
                }
                alt=""
                className={"icon-notApplicable"}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingBiasesSmartNetworks}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingBiasesSmartNetworks
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingBiasesWorkandMeaning}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingBiasesWorkandMeaning
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/not-applicable.svg`)
                    .default
                }
                alt=""
                className={"icon-notApplicable"}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={thumbsUp}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>Well Done</h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#BE2431" }}
              >
                Motivation
              </h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMotivationProfessionalRelationships}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMotivationProfessionalRelationships
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/not-applicable.svg`)
                    .default
                }
                alt=""
                className={"icon-notApplicable"}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMotivationWorkMeaning}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMotivationWorkMeaning
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require(`./../../assets/images/report/${ReportDetailsContent.humanThinkingMotivationSocialBehaviour}.svg`)
                    .default
                }
                alt=""
                className={
                  "icon-" +
                  ReportDetailsContent.humanThinkingMotivationSocialBehaviour
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={notApplicable}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>
                Biases & Motivational factors were not tested for these thinking
                models
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginTop: "64px" }}
          >
            <Grid item xs={12} sm={5} md={5}>
              {/* <img
                src={
                  require(`./../../assets/images/report/new-scoring-icons/ht-score-${
                    Math.ceil(ReportDetailsContent.htAvgScore) - 1
                  }.png`).default
                }
                alt=""
                class="ht-score-img"
              /> */}
              <SvgScoringSystemSM
                data={HTReportDetailsContent}
              ></SvgScoringSystemSM>
            </Grid>

            <Grid item xs={12} sm={7} md={7}>
              <Grid container style={{ padding: "0 0 0 36px" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <h4
                    className={classes.sectionTitle}
                    style={{ textAlign: "left", textTransform: "capitalize" }}
                  >
                    <span style={{ color: "#000" }}> Why You Are </span>{" "}
                    {ReportDetailsContent.htLineCaption}
                  </h4>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className={classes.para}
                  style={{ paddingLeft: "0" }}
                >
                  {ReportDetailsContent.htRecommendation1
                    .slice(0, ReportDetailsContent.htRecommendation1.length / 2)
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  {ReportDetailsContent.htRecommendation1
                    .slice(
                      ReportDetailsContent.htRecommendation1.length / 2,
                      ReportDetailsContent.htRecommendation1.length
                    )
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  <p class="suggestion-desc ht-suggestion-title-color">
                    Specific Suggestions For You:{" "}
                  </p>
                  {ReportDetailsContent.htRecommendation.map((item, index) => (
                    <p key={index} class="suggestion-desc">
                      {item}
                    </p>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <div class="vl"></div>
          </Grid>

          {/* Innovation Thinking */}
          <Grid
            container
            className={classes.reportCustomContainer}
            id="innovationThinkingSection"
          >
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.sectionTitle} style={{ color: "#214697" }}>
                Innovation Thinking
              </h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={innovationThinking} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Innovation Thinking</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={innovationBias} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Innovation Bias</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={innovationDemand} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Innovation Demand</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={entreplastixMind} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Entreplastic™ Mind</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={innovationThinking}
                alt=""
                style={{ visibility: "hidden" }}
                class="ht-icon"
              />
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#626262" }}
              >
                Key
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ margin: "20px 0px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#214697" }}
              >
                Mindset
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMindsetInnovationThinking}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMindsetInnovationThinking
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMindsetInnovationBias}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMindsetInnovationBias
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMindsetInnovationDemand}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMindsetInnovationDemand
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMindsetEntrepreneurialMind}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMindsetEntrepreneurialMind
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img src={improvement} alt="" class="thumb-icon" />
              <h4 className={classes.sectionThumbnails}>
                Scope for Improvement
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#214697" }}
              >
                Biases
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingBiasesInnovationThinking}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingBiasesInnovationThinking
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingBiasesInnovationBias}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingBiasesInnovationBias
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/not-applicable.svg`)
                        .default
                    }
                    alt=""
                    className={"icon-notApplicable"}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/not-applicable.svg`)
                        .default
                    }
                    alt=""
                    className={"icon-notApplicable"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={thumbsUp}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>Well Done</h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#214697" }}
              >
                Motivation
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMotivationInnovationThinking}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMotivationInnovationThinking
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMotivationInnovationBias}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMotivationInnovationBias
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMotivationInnovationDemand}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMotivationInnovationDemand
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.innovationThinkingMotivationEntrepreneurialMind}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.innovationThinkingMotivationEntrepreneurialMind
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={notApplicable}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>
                Biases & Motivational factors were not tested for these thinking
                models
              </h4>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginTop: "64px" }}
          >
            <Grid item xs={12} sm={5} md={5}>
              {/* <img
                src={
                  require(`./../../assets/images/report/new-scoring-icons/it-score-${
                    Math.ceil(ReportDetailsContent.itAvgScore) - 1
                  }.png`).default
                }
                alt=""
                class="ht-score-img"
              /> */}

              <SvgScoringSystemSM
                data={ITReportDetailsContent}
              ></SvgScoringSystemSM>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Grid container style={{ padding: "0 0 0 36px" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <h4
                    className={classes.sectionTitle}
                    style={{
                      textAlign: "left",
                      color: "#214697",
                      textTransform: "capitalize",
                    }}
                  >
                    <span style={{ color: "#000" }}> Why You Are </span>{" "}
                    {ReportDetailsContent.itLineCaption}
                  </h4>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className={classes.para}
                  style={{ paddingLeft: "0" }}
                >
                  {ReportDetailsContent.itRecommendation1
                    .slice(0, ReportDetailsContent.itRecommendation1.length / 2)
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  {ReportDetailsContent.itRecommendation1
                    .slice(
                      ReportDetailsContent.itRecommendation1.length / 2,
                      ReportDetailsContent.itRecommendation1.length
                    )
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  <p class="suggestion-desc it-suggestion-title-color">
                    Specific Suggestions For You:{" "}
                  </p>
                  {ReportDetailsContent.itRecommendation.map((item, index) => (
                    <p key={index} class="suggestion-desc">
                      {item}
                    </p>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div class="vl"></div>
          </Grid>
          {/* Risk Thinking */}
          <Grid
            container
            className={classes.reportCustomContainer}
            id="riskThinkingSection"
          >
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.sectionTitle} style={{ color: "#FFCD05" }}>
                Risk Thinking
              </h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={riskAversion} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Risk Aversion</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={comfortDiscomfort} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Comfort & Discomfort</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={riskMitigation} alt="" class="ht-icon" />
              <h4 className={classes.sectionSubTitle}>Risk Mitigation</h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img src={riskTaking} alt="" class="ht-icon" />
              <h4 id="riskTaking" className={classes.sectionSubTitle}>
                Risk Taking & Decision Making
              </h4>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={riskTaking}
                alt=""
                class="ht-icon"
                style={{ visibility: "hidden" }}
              />
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#626262" }}
              >
                Key
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#FFCD05" }}
              >
                Mindset
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMindsetRiskAversion}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMindsetRiskAversion
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMindsetComfortDiscomfort}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMindsetComfortDiscomfort
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMindsetRiskMitigation}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMindsetRiskMitigation
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMindsetRiskTaking}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMindsetRiskTaking
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img src={improvement} alt="" class="thumb-icon" />
              <h4 className={classes.sectionThumbnails}>
                Scope for Improvement
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginBottom: "40px" }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#FFCD05" }}
              >
                Biases
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingBiasesRiskAversion}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingBiasesRiskAversion
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/not-applicable.svg`)
                        .default
                    }
                    alt=""
                    className={"icon-notApplicable"}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingBiasesRiskMitigation}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingBiasesRiskMitigation
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingBiasesRiskTaking}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingBiasesRiskTaking
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={thumbsUp}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>Well Done</h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={2} md={2}>
              <h4
                className={classes.sectionSubTitle}
                style={{ color: "#FFCD05" }}
              >
                Motivation
              </h4>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container style={{ padding: "0" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMotivationRiskAversion}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMotivationRiskAversion
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/${ReportDetailsContent.riskThinkingMotivationComfort}.svg`)
                        .default
                    }
                    alt=""
                    className={
                      "icon-" +
                      ReportDetailsContent.riskThinkingMotivationComfort
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/not-applicable.svg`)
                        .default
                    }
                    alt=""
                    className={"icon-notApplicable"}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    src={
                      require(`./../../assets/images/report/not-applicable.svg`)
                        .default
                    }
                    alt=""
                    className={"icon-notApplicable"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ opacity: "50%" }}>
              <img
                src={notApplicable}
                alt=""
                className={classes.sectionThumbnailsImg}
              />
              <h4 className={classes.sectionThumbnails}>
                Biases & Motivational factors were not tested for these thinking
                models
              </h4>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginTop: "64px" }}
          >
            <Grid item xs={12} sm={6} md={5}>
              <div>
                <SvgScoringSystemSM
                  data={RTReportDetailsContent}
                ></SvgScoringSystemSM>
              </div>
              {/* <img
                src={
                  require(`./../../assets/images/report/new-scoring-icons/rt-score-${
                    Math.ceil(ReportDetailsContent.rtAvgScore) - 1
                  }.png`).default
                }
                alt=""
                class="ht-score-img"
              /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Grid container style={{ padding: "0 0 0 36px" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <h4
                    className={classes.sectionTitle}
                    style={{
                      textAlign: "left",
                      color: "#FFCD05",
                      textTransform: "capitalize",
                    }}
                  >
                    <span style={{ color: "#000" }}> Why You Are </span>{" "}
                    {ReportDetailsContent.rtLineCaption}
                  </h4>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className={classes.para}
                  style={{ paddingLeft: "0" }}
                >
                  {ReportDetailsContent.rtRecommendation1
                    .slice(0, ReportDetailsContent.rtRecommendation1.length / 2)
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  {ReportDetailsContent.rtRecommendation1
                    .slice(
                      ReportDetailsContent.rtRecommendation1.length / 2,
                      ReportDetailsContent.rtRecommendation1.length
                    )
                    .map((item, index) => {
                      return (
                        <p key={index} class="suggestion-desc">
                          {item}
                        </p>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.para}>
                  <p class="suggestion-desc rt-suggestion-title-color">
                    Specific Suggestions For You:{" "}
                  </p>
                  {ReportDetailsContent.rtRecommendation.map((item, index) => (
                    <p key={index} class="suggestion-desc">
                      {item}
                    </p>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div class="vl"></div>
          </Grid>
          {/* Points Ponder */}
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.sectionTitle} style={{ color: "#000" }}>
                Points to Ponder
              </h4>
              <div class="pp-title" style={{ marginBottom: "0" }}>
                <a href="#riskThinkingSection" class="pp-title">
                  Risk Thinking
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ padding: "0", marginTop: "-50px" }}
          >
            {/* <Grid item xs={12} sm={1} md={1}></Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <div class="pp-section-container">
                <img
                  src={pointsPonderImg}
                  alt=""
                  useMap="#pointPonder"
                  style={{ maxWidth: "100%" }}
                />
                <map name="pointPonder">
                  <area
                    shape="poly"
                    coords="284,150,375,104,461,148,463,259,374,304,284,259"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("rt", 9)}
                  />
                  <area
                    shape="poly"
                    coords="640,150,551,107,461,148,463,259,462,260,553,305,639,262"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("rt", 10)}
                  />
                  <area
                    shape="poly"
                    coords="639,150,727,107,816,150,818,261,730,304,640,260"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("rt", 11)}
                  />
                  <area
                    shape="poly"
                    coords="816,151,904,106,992,150,994,259,905,307,817,259"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("rt", 12)}
                  />
                  <area
                    shape="poly"
                    coords="198,304,286,262,374,304,372,416,284,460,197,414"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("ht", 1)}
                  />
                  <area
                    shape="poly"
                    coords="376,304,463,261,549,305,550,414,462,461,372,416"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("ht", 2)}
                  />
                  <area
                    shape="poly"
                    coords="285,458,374,417,463,457,462,570,372,616,286,571"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("ht", 3)}
                  />
                  <area
                    shape="poly"
                    coords="463,458,550,415,639,456,640,569,551,614,462,569,462,569"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("ht", 4)}
                  />
                  <area
                    shape="poly"
                    coords="731,306,816,258,906,303,904,417,815,457,732,415"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("it", 5)}
                  />
                  <area
                    shape="poly"
                    coords="904,305,992,258,1078,301,1082,414,994,460,904,416"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("it", 6)}
                  />
                  <area
                    shape="poly"
                    coords="639,457,730,416,813,459,816,570,727,615,637,570"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("it", 7)}
                  />
                  <area
                    shape="poly"
                    coords="813,458,904,416,992,459,993,569,906,613,818,573"
                    href="javascript:void(0)"
                    alt=""
                    onClick={openSelectedOutcomeDescription("it", 8)}
                  />
                </map>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={1} md={1}></Grid> */}
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ marginTop: "-50px" }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <a href="#humanThinkingSection" class="pp-title">
                <h4
                  class="pp-title"
                  style={{
                    color: "#BE2431",
                    textAlign: "right",
                    marginRight: "20px",
                  }}
                >
                  Human Thinking
                </h4>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <a href="#innovationThinkingSection" class="pp-title">
                <h4
                  class="pp-title"
                  style={{
                    color: "#214697",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  Innovation Thinking
                </h4>
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div class="vl"></div>
          </Grid>
          <Grid
            container
            className={classes.reportCustomContainer}
            style={{ justifyContent: "center" }}
          >
            <div>
              <h4
                className={classes.sectionTitle}
                style={{ color: "#000", lineHeight: "60px !important" }}
              >
                The Next Step
              </h4>
            </div>
            <Grid container>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div class="questor-logo-left-side">
                  <img src={questorLogoPlus} alt="" />
                  <p class="questor-logo-para">
                    Self - Learning Platform <br /> + <br /> Tactile Assets:
                    PHYGITAL & Integrated
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                style={{ borderRight: "2px solid #626262" }}
              ></Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div class="questor-logo-left-side" style={{ border: "none" }}>
                  <img src={questorLogo} alt="" />
                  <div>
                    <img
                      src={questorLogoPlus}
                      alt=""
                      class="questor-logo-small"
                    />
                  </div>
                  <p class="questor-logo-para" style={{ marginTop: "0" }}>
                    + <br /> Simulator
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={12} md={12}>
              <h4 className={classes.trainingTitle}>
                (In-Person Training Available)
              </h4>
            </Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={1} md={1}></Grid>
            <Grid item xs={12} sm={10} md={10}>
              <h4
                className={classes.sectionTitle}
                style={{ color: "#6FBC44", marginBottom: "18px" }}
              >
                We’d love your feedback:
              </h4>
              <p className={classes.sectionSubTitle}>
                How has your experience been using this learning product?
              </p>
              <div style={{ marginBottom: "54px" }}>
                <p className={classes.sectionSubTitle}>(Choose One)</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={1} md={1}></Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require("./../../assets/images/report/new-scoring-icons/feedback-bad-white.png")
                    .default
                }
                onClick={(event) => setFeedbackBadStatus(event, "sad")}
                onMouseOver={(event) =>
                  onMouseHoveringFeedbackIcon(event, "sad")
                }
                onMouseOut={(event) => onMouseLeavingFeedbackIcon(event, "sad")}
                alt="feedback-bad"
                id="feedback-bad"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require("./../../assets/images/report/new-scoring-icons/feedback-okay-white.png")
                    .default
                }
                onClick={(event) => setFeedbackOkayStatus(event, "neutral")}
                onMouseOver={(event) =>
                  onMouseHoveringFeedbackIcon(event, "neutral")
                }
                onMouseOut={(event) =>
                  onMouseLeavingFeedbackIcon(event, "neutral")
                }
                alt="feedback-okay"
                id="feedback-okay"
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <img
                src={
                  require("./../../assets/images/report/new-scoring-icons/feedback-good-white.png")
                    .default
                }
                onClick={(event) => setFeedbackGoodStatus(event, "happy")}
                onMouseOver={(event) =>
                  onMouseHoveringFeedbackIcon(event, "happy")
                }
                onMouseOut={(event) =>
                  onMouseLeavingFeedbackIcon(event, "happy")
                }
                alt="feedback-good"
                id="feedback-good"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
          <Grid container className={classes.reportCustomContainer}>
            <Grid item xs={12} sm={3} md={3}></Grid>

            <Grid item xs={12} sm={6} md={6}>
              <button
                class="feedback-btn"
                id="feedback-btn"
                onClick={handleFeedbackSubmit}
              >
                Submit
              </button>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
          <Grid container className={classes.footer}>
            <div>
              <img src={etqLogo} alt="" className={classes.footerLogo} />
              <p className="copyRight" style={{ color: "#000" }}>
                © 2023 ETQ Confidential. All Rights Reserved.
              </p>
            </div>
          </Grid>
          <div class="animated-drawer-container">
            {ReportDetailsContent.pointsToPonder &&
              ReportDetailsContent.pointsToPonder.map((score) => {
                return ppNumber === score.id ? (
                  <AnimatedDrawer
                    animatedDrawerType={closeSelectedOutcomeDescription}
                    ppType={ppNumber}
                    data={score}
                    type={ppType}
                  ></AnimatedDrawer>
                ) : null;
              })}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default Report;
