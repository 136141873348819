import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import authReducer from "./components/store/reducers/AuthReducer";
import TestReducer from "./components/store/reducers/TestReducer";
import rootSaga from './components/store/sagas/RootSagas';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware();

// Create the root reducer
const rootReducer = combineReducers({
  authReducer,
  TestReducer
});

const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

ReactDOM.render(

  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
  ,
  document.getElementById('root')
);

reportWebVitals();
