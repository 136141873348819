// types of action
export const Types = {
    OPEN_LOGIN_SCREEN: "OPEN_LOGIN_SCREEN",
    OPEN_LOGIN_SCREEN_ASYNC: "OPEN_LOGIN_SCREEN_ASYNC",
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_REQUEST_ASYNC: "USER_LOGIN_REQUEST_ASYNC",
    USER_LOGIN_RESPONSE: "USER_LOGIN_RESPONSE",
    USER_LOGIN_ERROR: "USER_LOGIN_ERROR",
    REG_CODE_VERIFY_REQUEST: "REG_CODE_VERIFY_REQUEST",
    REG_CODE_VERIFY_REQUEST_ASYNC: "REG_CODE_VERIFY_REQUEST_ASYNC",
    REG_CODE_VERIFY_RESPONSE: "REG_CODE_VERIFY_RESPONSE",
    REG_CODE_VERIFY_ERROR: "REG_CODE_VERIFY_ERROR",
    USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
    USER_REGISTER_REQUEST_ASYNC: "USER_REGISTER_REQUEST_ASYNC",
    USER_REGISTER_RESPONSE: "USER_REGISTER_RESPONSE",
    USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
    USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
    USER_LOGOUT_REQUEST_ASYNC: "USER_LOGOUT_REQUEST_ASYNC",
    GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
    GET_ALL_QUESTIONS_ASYNC: "GET_ALL_QUESTIONS_ASYNC",
    GET_ALL_QUESTIONS_RESPONSE: "GET_ALL_QUESTIONS_RESPONSE",
    GET_ALL_QUESTIONS_ERROR: "GET_ALL_QUESTIONS_ERROR",
    POST_ANSWER: "POST_ANSWER",
    POST_ANSWER_ASYNC: "POST_ANSWER_ASYNC",
    POST_ANSWER_RESPONSE: "POST_ANSWER_RESPONSE",
    POST_ANSWER_ERROR: "POST_ANSWER_ERROR",
    OPEN_RESET_PASSWORD_SCREEN: "OPEN_RESET_PASSWORD_SCREEN",
    OPEN_RESET_PASSWORD_SCREEN_ASYNC: "OPEN_RESET_PASSWORD_SCREEN_ASYNC",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_REQUEST_ASYNC: "RESET_PASSWORD_REQUEST_ASYNC",
    RESET_PASSWORD_RESPONSE: "RESET_PASSWORD_RESPONSE",
    RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
    GET_USER_INFO: "GET_USER_INFO",
    GET_USER_INFO_ASYNC: "GET_USER_INFO_ASYNC",
    GET_USER_INFO_RESPONSE: "GET_USER_INFO_RESPONSE",
    GET_USER_INFO_ERROR: "GET_USER_INFO_ERROR",
    OPEN_DRAWER: "OPEN_DRAWER",
    OPEN_DRAWER_ASYNC: "OPEN_DRAWER_ASYNC",
    OPEN_DRAWER_RESPONSE: "OPEN_DRAWER_RESPONSE",
    OPEN_DRAWER_ERROR: "OPEN_DRAWER_ERROR",
    GO_TO_LOGIN_SCREEN: "GO_TO_LOGIN_SCREEN",
    GO_TO_LOGIN_SCREEN_ASYNC: "GO_TO_LOGIN_SCREEN_ASYNC",
    CLOSE_MODAL_POPUP: "CLOSE_MODAL_POPUP",
    CLOSE_MODAL_POPUP_ASYNC: "CLOSE_MODAL_POPUP_ASYNC",
    SET_TEST_PROCEED: "SET_TEST_PROCEED",
    SET_TEST_PROCEED_ASYNC: "SET_TEST_PROCEED_ASYNC",
    CLOSE_SET_TEST_PROCEED: "CLOSE_SET_TEST_PROCEED",
    CLOSE_SET_TEST_PROCEED_ASYNC: "CLOSE_SET_TEST_PROCEED_ASYNC",
    RESET_QUESTION_PAGE: "RESET_QUESTION_PAGE",
    RESET_QUESTION_PAGE_ASYNC: "RESET_QUESTION_PAGE_ASYNC",
    USER_EXIST: "USER_EXIST",
    USER_EXIST_ASYNC: "USER_EXIST_ASYNC",
    MOUSE_ENTER_EVENT: "MOUSE_ENTER_EVENT",
    MOUSE_ENTER_EVENT_ASYNC: "MOUSE_ENTER_EVENT_ASYNC",
    MOUSE_LEAVE_EVENT: "MOUSE_LEAVE_EVENT",
    MOUSE_LEAVE_EVENT_ASYNC: "MOUSE_LEAVE_EVENT_ASYNC",
    THEME_SELECTION: "THEME_SELECTION",
    THEME_SELECTION_ASYNC: "THEME_SELECTION_ASYNC",
    IS_TEST_STARTED: "IS_TEST_STARTED",
    IS_TEST_STARTED_ASYNC: "IS_TEST_STARTED_ASYNC",
    SET_THEME: "SET_THEME",
    SET_THEME_ASYNC: "SET_THEME_ASYNC",
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_REQUEST_ASYNC: "CHANGE_PASSWORD_REQUEST_ASYNC",
    CHANGE_PASSWORD_RESPONSE: "CHANGE_PASSWORD_RESPONSE",
    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
    FORCE_RESET_PASSWORD_REQUEST: "FORCE_RESET_PASSWORD_REQUEST",
    FORCE_RESET_PASSWORD_REQUEST_ASYNC: "FORCE_RESET_PASSWORD_REQUEST_ASYNC",
    FORCE_RESET_PASSWORD_RESPONSE: "FORCE_RESET_PASSWORD_RESPONSE",
    FORCE_RESET_PASSWORD_ERROR: "FORCE_RESET_PASSWORD_ERROR",
    EMAIL_CONFIRM_REQUEST: "EMAIL_CONFIRM_REQUEST",
    EMAIL_CONFIRM_REQUEST_ASYNC: "EMAIL_CONFIRM_REQUEST_ASYNC",
    EMAIL_CONFIRM_RESPONSE: "EMAIL_CONFIRM_RESPONSE",
    EMAIL_CONFIRM_ERROR: "EMAIL_CONFIRM_ERROR",
    EMAIL_VERIFY_REQUEST: "EMAIL_VERIFY_REQUEST",
    EMAIL_VERIFY_REQUEST_ASYNC: "EMAIL_VERIFY_REQUEST_ASYNC",
    EMAIL_VERIFY_RESPONSE: "EMAIL_VERIFY_RESPONSE",
    EMAIL_VERIFY_ERROR: "EMAIL_VERIFY_ERROR",
    SET_BG_IMAGE: "SET_BG_IMAGE",
    SET_BG_IMAGE_ASYNC: "SET_BG_IMAGE_ASYNC",
    GET_REPORT_DETAILS: "GET_REPORT_DETAILS",
    GET_REPORT_DETAILS_ASYNC: "GET_REPORT_DETAILS_ASYNC",
    GET_REPORT_DETAILS_RESPONSE: "GET_REPORT_DETAILS_RESPONSE",
    GET_REPORT_DETAILS_ERROR: "GET_REPORT_DETAILS_ERROR",
    POST_FEEDBACK: "POST_FEEDBACK",
    POST_FEEDBACK_ASYNC: "POST_FEEDBACK_ASYNC",
    POST_FEEDBACK_RESPONSE: "POST_FEEDBACK_RESPONSE",
    POST_FEEDBACK_ERROR: "POST_FEEDBACK_ERROR",
    USER_REGISTER_OTP_REQUEST: "USER_REGISTER_OTP_REQUEST",
    USER_REGISTER_OTP_REQUEST_ASYNC: "USER_REGISTER_OTP_REQUEST_ASYNC",
    USER_REGISTER_OTP_RESPONSE: "USER_REGISTER_OTP_RESPONSE",
    USER_REGISTER_OTP_ERROR: "USER_REGISTER_OTP_ERROR",
    USER_LOGIN_OTP_REQUEST: "USER_LOGIN_OTP_REQUEST",
    USER_LOGIN_OTP_REQUEST_ASYNC: "USER_LOGIN_OTP_REQUEST_ASYNC",
    USER_LOGIN_OTP_RESPONSE: "USER_LOGIN_OTP_RESPONSE",
    USER_LOGIN_OTP_ERROR: "USER_LOGIN_OTP_ERROR",
    VERIFY_LOGIN_OTP_REQUEST: "VERIFY_LOGIN_OTP_REQUEST",
    VERIFY_LOGIN_OTP_REQUEST_ASYNC: "VERIFY_LOGIN_OTP_REQUEST_ASYNC",
    VERIFY_LOGIN_OTP_RESPONSE: "VERIFY_LOGIN_OTP_RESPONSE",
    VERIFY_LOGIN_OTP_ERROR: "VERIFY_LOGIN_OTP_ERROR",
};