/* eslint-disable default-case */
import { Types } from "./../Types";

const defaultState = {
    questions: [],
    isLoading: false,
    errorMessage: "",
    isTestPage: false,
    isAnswerPosted: false,
    openDrawer: false,
    isTestProceed: false,
    isPopupOpened: false,
    isTestStarted: false,
    isDarkThemeSelected: false,
    isBgImageSet: false,
    selectedTheme: "default",
    reportDetails: {},
    isReportDetailsFetched: false,
    isFeedbackSubmitted: false
};

const TestReducer = (state = defaultState, action) => {
    const newState = { ...state };

    switch (action.type) {

        case Types.GET_ALL_QUESTIONS_ASYNC: {
            return {
                ...newState,
                isLoading: true,
            };
        }

        case Types.GET_ALL_QUESTIONS_RESPONSE: {
            return {
                ...newState,
                questions: action.response,
                isTestPage: true,
                isLoading: false
            };
        }

        case Types.GET_ALL_QUESTIONS_ERROR: {
            return {
                ...newState,
                errorMessage: action.response.message,
                isLoading: false
            };
        }

        case Types.POST_ANSWER_ASYNC: {
            return {
                ...newState,
                isLoading: true,
                isAnswerPosted: false,
            };
        }

        case Types.POST_ANSWER_RESPONSE: {
            let data;
            if (action.response.data.data.questions == null) {
                data = true;
            } else {
                data = false;
            }
            return {
                ...newState,
                questions: action.response.data,
                isTestPage: true,
                isAnswerPosted: true,
                isPopupOpened: data,
                isLoading: false
            };
        }

        case Types.POST_ANSWER_ERROR: {
            return {
                ...newState,
                errorMessage: action.response.message,
                isLoading: false
            };
        }

        case Types.OPEN_DRAWER_ASYNC: {
            return {
                ...newState,
                openDrawer: true,
            };
        }

        case Types.CLOSE_MODAL_POPUP_ASYNC: {
            return {
                ...newState,
                isTestProceed: false,
                isPopupOpened: false,
            };
        }

        case Types.SET_TEST_PROCEED_ASYNC: {
            return {
                ...newState,
                isTestProceed: true,
            };
        }

        case Types.CLOSE_SET_TEST_PROCEED_ASYNC: {
            return {
                ...newState,
                isTestProceed: false,
            };
        }

        case Types.RESET_QUESTION_PAGE_ASYNC: {
            return {
                ...newState,
                questions: null,
            };
        }

        case Types.IS_TEST_STARTED_ASYNC: {
            return {
                ...newState,
                isTestStarted: true,
            };
        }

        case Types.SET_THEME_ASYNC: {
            return {
                ...newState,
                isDarkThemeSelected: action.selectedTheme,
            }
        }

        case Types.SET_BG_IMAGE_ASYNC: {
            return {
                ...newState,
                isBgImageSet: true,
                selectedTheme: action.selectedTheme
            };
        }

        case Types.GET_REPORT_DETAILS_ASYNC: {
            return {
                ...newState,
                isLoading: true,
                isReportDetailsFetched: false,
            };
        }

        case Types.GET_REPORT_DETAILS_RESPONSE: {
            return {
                ...newState,
                reportDetails: action.response,
                isReportDetailsFetched: true,
            };
        }

        case Types.GET_REPORT_DETAILS_ERROR: {
            return {
                ...newState,
                errorMessage: action.response,
                isLoading: false
            };
        }

        case Types.POST_FEEDBACK_ASYNC: {
            return {
                ...newState,
                isLoading: true,
                isFeedbackSubmitted: false,
            };
        }

        case Types.POST_FEEDBACK_RESPONSE: {
            return {
                ...newState,
                isFeedbackSubmitted: true,
            };
        }

        case Types.POST_FEEDBACK_ERROR: {
            return {
                ...newState,
                errorMessage: action.response.message,
                isLoading: false
            };
        }

        default:
            return newState;
    }
};

export default TestReducer;