/* eslint-disable jsx-a11y/no-access-key */
/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Questions from "./Questions";
import CopyRight from "../common/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "./../store/Types";
import CircleProgressBar from "../common/CircleProgressBar";
import Audio from "../common/Audio";
import skyTheme from "../../assets/images/theme-jungle.png";
import jungleTheme from "../../assets/images/theme-science.png";
import classRoomTheme from "../../assets/images/theme-classroom.png";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/test-screen-bg.svg";
import { ReactComponent as InfoGraphicImageXS } from "./../../assets/images/info-4-new.svg";
import { useHistory } from "react-router";
import information from "../../assets/images/info.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      position: "relative",
    },
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    padding: "2rem 4rem 3rem 4rem",
    position: "relative",
    ["@media (max-width:480px)"]: {
      padding: "2rem",
    },
  },
  brightImg: {
    maxWidth: "100px",
  },
  text: {
    fontSize: "24px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0px",
    marginBottom: 20,
  },
  text2: {
    fontSize: "18px",
    lineHeight: "30px",
    fontFamily: "PoppinsRegular",
    fontWeight: 300,
    letterSpacing: "0px",
    opacity: 0.8,
    marginBottom: "2.6rem",
  },
  whiteColor: {
    opacity: 1,
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "PoppinsBold",
    fontWeight: "normal",
    letterSpacing: "0px",
  },
  blueText: {
    color: "#508DFF",
  },
  yellowColor: {
    color: "#F6AC29",
    fontSize: "24px",
    lineHeight: "30px",
    fontFamily: "PoppinsBold",
    fontWeight: "bold",
  },
  lightText: {
    color: "#fff",
    fontSize: "24px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "600",
    letterSpacing: "0px",
    margin: "1.3rem 0",
  },
  instructionList: {
    listStyle: "none",
    fontSize: "22px",
    color: "#FFF",
    marginTop: 0,
    padding: 0,
  },
  list: {
    "&::before": {
      display: "none",
      content: '"•"',
      color: "#508DFC",
      fontWeight: "bold",
      width: "1em",
    },
  },
  subList: {
    listStyleType: "none",
    padding: 0,
    marginLeft: "1rem",
    fontSize: "24px",
  },
  listText: {
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "PoppinsRegular",
    fontWeight: "normal",
    letterSpacing: "0px",
    color: "rgba(255, 255, 255, 0.7)",
  },
  themeSelectionText: {
    fontSize: "12px",
    lineHeight: "30px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: "600",
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "22%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      width: 6,
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    position: "relative",
    // display: "flex",
    alignItems: "center",
  },
  copyRight: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "0 3rem",
    ["@media (max-width:480px)"]: {
      padding: "0 1rem 1rem",
    },
  },
  primaryButton: {
    background: "#ffcd05 0% 0% no-repeat",
    borderRadius: 23,
    opacity: 1,
    color: "#1D1D1B",
    "&:hover, &:focus": {
      background: "#ffcd05 0% 0% no-repeat padding-box",
    },
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "12px 50px",
  },
  themeSelectionImg: {
    width: "116px",
    height: "66px",
    cursor: "pointer",
    opacity: 0.6,
    marginRight: "10px",
  },
  themeSelectionTitle: {
    textAlign: "center",
    opacity: "0.6",
    fontSize: "14px",
    lineHeight: "30px",
    fontFamily: "PoppinsBold",
    fontWeight: 700,
  },
  defaultTheme: {
    // display: "inline-block",
    width: "116px",
    flex: 1,
    backgroundColor: "darkgray",
    height: "66px",
    borderRadius: "8px",
    opacity: 0.6,
    cursor: "pointer",
    marginRight: "10px",
  },
  listingBulletContainer: {
    maxWidth: "3%",
  },
  listingBulletTextContainer: {
    maxWidth: "97%",
  },
}));

function Test() {
  const classes = useStyles();
  const history = useHistory();
  const [DarkTheme, setDarkTheme] = useState(false);
  const [theme, setTheme] = useState("none");
  const [isPlayAudio, setIsPlayAudio] = useState(false);
  const [themeSelectionText, setThemeSelectionText] = useState("Light Mode");
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [isTestProceed, setIsTestProceed] = useState(false);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const dispatch = useDispatch();
  const TestReducer = useSelector((state) => state.TestReducer);
  const questions = useSelector((state) => state.TestReducer.questions.data);
  const [answerData, setAnswerData] = useState({});
  const [themeSelected, setThemeSelected] = useState("1");
  const [defaultTheme, setDefaultTheme] = useState("none");
  const [selectDarkTheme, setSelectDarkTheme] = useState(""); // Variables used to store selected dark theme
  const [selectLightTheme, setSelectLightTheme] = useState(""); // Variables used to store selected light theme

  useEffect(() => {
    dispatch({
      type: Types.GET_ALL_QUESTIONS,
    });
    // if (questions.questions) {
    //   document.getElementById("1").classList.add("selectedTheme"); // selecting sky theme by default
    // }
    // themeSelectionText === "Dark Mode"
    //   ? setTheme("science_dark_bg")
    //   : setTheme("science_light_bg");
    return () => {
      console.log("returned");
    };
  }, []);

  const handleThemeChange = (event) => {
    setDarkTheme(event.target.checked);
    if (event.target.checked) {
      setThemeSelectionText("Dark Mode");
    } else {
      setThemeSelectionText("Light Mode");
    }
  };

  const selectedOptions = (selectedOption) => {
    let answerData = {
      question_id: questions.questions.id,
      assessment_category_id: parseInt(selectedOption.title, 10),
      assessment_sub_category_id: parseInt(selectedOption.align, 10),
      question_number: parseInt(questions.questions.questionNumber, 10),
      answer: selectedOption.value,
      answered_state: selectedOption.alt,
      answered_probability: parseFloat(selectedOption.pattern),
      complete_assessment: questions.endOfAssessment
        ? questions.endOfAssessment
        : 0,
      current_state: questions.questions.currentState,
      report_state: selectedOption.placeholder,
      question_choice_mark: selectedOption.size,
    };
    setAnswerData({ ...answerData });
  };

  const handleNextClick = (answerTime) => {
    answerData["answer_time"] = answerTime;
    dispatch({
      type: Types.POST_ANSWER,
      payload: answerData,
    });
  };

  const isSchoolModule = () => {
    return (questions &&
      questions.questions &&
      questions.questions.categoryId) === 5 ||
      6 ||
      7
      ? true
      : false;
  };

  const showSchoolModule = (questions) => {
    if (questions.questions) {
      if (questions.questions.categoryId === 5) {
        return true;
      } else if (questions.questions.categoryId === 6) {
        return true;
      } else if (questions.questions.categoryId === 7) {
        return true;
      } else {
        return false;
      }
    }
  };

  const closePopup = () => {
    setIsTestProceed(false);
    setShowProgressBar(false);
    setIsPopupOpened(false);
    dispatch({
      type: Types.CLOSE_MODAL_POPUP,
    });
    dispatch({
      type: Types.USER_LOGOUT_REQUEST,
    });
    dispatch({
      type: Types.SET_THEME,
      payload: false,
    });
    sessionStorage.removeItem("token");
    history.push("/report");
  };

  const onProceed = () => {
    setIsPlayAudio(true);
    dispatch({
      type: Types.GET_ALL_QUESTIONS,
    });

    // Check whether dark theme selected or not
    themeSelectionText === "Dark Mode"
      ? setTheme(selectDarkTheme)
      : setTheme(selectLightTheme);
    console.log("theme", theme);
    setShowProgressBar(true);
  };

  const handleCloseProgressBar = () => {
    if (isSchoolModule() && defaultTheme !== "none") {
      dispatch({
        type: Types.SET_BG_IMAGE,
        payload: { selectedTheme: theme },
      });
    }
    dispatch({
      type: Types.IS_TEST_STARTED,
    });
    setShowProgressBar(false);
    setIsTestProceed(true);
    dispatch({
      type: Types.SET_THEME,
      payload: DarkTheme,
    });
  };

  const selectedTheme = (event) => {
    setSelectDarkTheme(event.target.slot);
    setSelectLightTheme(event.target.alt);
    document.getElementById(themeSelected).classList.remove("selectedTheme");
    document
      .getElementById(`${themeSelected}1`)
      .classList.remove("selectedThemeTitle");
    setThemeSelected(event.target.id);
    document.getElementById(event.target.id).classList.add("selectedTheme");
    document
      .getElementById(`${event.target.id}1`)
      .classList.add("selectedThemeTitle");
    setDefaultTheme(event.target.alt);
  };

  return (
    <div className={classes.ss}>
      {isTestProceed && TestReducer.isPopupOpened ? (
        <Questions closeModalPopup={closePopup}></Questions>
      ) : null}
      {isTestProceed && questions && questions.questions ? (
        <React.Fragment>
          <Questions
            questions={questions}
            selectedOptions={selectedOptions}
            nextClick={handleNextClick}
            closeModalPopup={closePopup}
          ></Questions>
        </React.Fragment>
      ) : (
        <Grid
          container
          component="main"
          className={`${classes.root} iphoneView`}
        >
          <Hidden only={["xs"]}>
            <Grid
              item
              xs={false}
              sm={6}
              md={6}
              className={classes.infoGraphicImageContainer}
            >
              <InfoGraphicImage className={classes.infoGraphicImage} />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            className={classes.rightSideContentContainer}
          >
            <div className={classes.rightSideContainer}>
              <Grid container>
                <Grid item md="6" sm="6" xs="6">
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.text}
                  >
                    General Instructions
                  </Typography>
                </Grid>
                <Grid item md="6" sm="6" xs="6" style={{ textAlign: "right" }}>
                  <img
                    src={information}
                    alt=""
                    style={{ width: "auto", height: "auto" }}
                  />
                </Grid>
              </Grid>

              <ul className={classes.instructionList}>
                <li className={`${classes.list} ${classes.listText}`}>
                  Questor Learning is about diagnosing, measuring and learning
                  about your
                  <span className={classes.whiteColor}>
                    {" "}
                    Entrepreneurial Thinking{" "}
                  </span>
                  (mindset).
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  It will typically take
                  <span className={classes.whiteColor}> 20-30 </span>
                  minutes.
                </li>
                <li className={classes.list} style={{ margin: "10px 0 10px" }}>
                  <span className={classes.whiteColor}>
                    BE PATIENT. READ AND UNDERSTAND THE REPORT CAREFULLY.
                  </span>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Everything is well explained in the report. Take the
                        time to read it thoroughly and focus on understanding
                        it.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        This will provide a much better understanding of your
                        score and list of actions.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li
                  className={`${classes.list} ${classes.whiteColor}`}
                  style={{ margin: "10px 0 10px" }}
                >
                  Please read the Questor Learning briefing document (available at the
                  report landing page) before reading the report.
                </li>

                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        We recommend you not to use any electronic devices
                        (including mobile phones) during the entire process.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Use this product at a quiet time and place when your
                        mind is free.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        There are no
                        <span className={classes.whiteColor}> right </span>
                        or
                        <span className={classes.whiteColor}> wrong </span>
                        answers.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Few questions will have time limits. You focus on making
                        the selections.
                      </p>
                    </Grid>
                  </Grid>
                </li>
              </ul>
              {questions && questions.questions != null ? (
                <React.Fragment>
                  <p className={classes.lightText}>Set Screen Mode</p>
                  <input
                    type="checkbox"
                    id="switch"
                    onChange={handleThemeChange}
                  />
                  <label htmlFor="switch" className="labelSwitch">
                    Toggle
                  </label>
                  <span
                    style={{
                      font: "normal normal 600 14px/30px PoppinsSemiBold",
                    }}
                  >
                    {themeSelectionText}
                  </span>
                  <br />
                  <br />
                  {showSchoolModule(questions) ? (
                    <React.Fragment>
                      <p className={classes.lightText}>Select Theme</p>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <span>
                          <div
                            id="0"
                            className={classes.defaultTheme}
                            alt="none"
                            onClick={selectedTheme}
                          ></div>
                          <div
                            id="01"
                            className={`${classes.themeSelectionTitle}`}
                          >
                            None
                          </div>
                        </span>
                        <span>
                          <img
                            id="1"
                            alt="jungle_light_bg"
                            src={skyTheme}
                            className={`${classes.themeSelectionImg}`}
                            slot="jungle_dark_bg"
                            onClick={selectedTheme}
                          />
                          <div
                            id="11"
                            className={`${classes.themeSelectionTitle}`}
                          >
                            Jungle
                          </div>
                        </span>
                        <span>
                          <img
                            id="2"
                            src={jungleTheme}
                            alt="science_light_bg"
                            className={`${classes.themeSelectionImg}`}
                            onClick={selectedTheme}
                            slot="science_dark_bg"
                          />
                          <div
                            id="21"
                            className={`${classes.themeSelectionTitle}`}
                          >
                            Science
                          </div>
                        </span>
                        <span>
                          <img
                            id="3"
                            src={classRoomTheme}
                            alt="class_light_bg"
                            className={`${classes.themeSelectionImg}`}
                            onClick={selectedTheme}
                            slot="class_dark_bg"
                          />
                          <div
                            id="31"
                            className={`${classes.themeSelectionTitle}`}
                          >
                            Classroom
                          </div>
                        </span>
                      </div>
                    </React.Fragment>
                  ) : null}
                  <br />
                  <Grid container style={{ marginTop: "2em" }}>
                    <Grid item md="6" sm="6" lg="6">
                      <Button
                        color="inherit"
                        className={classes.primaryButton}
                        onClick={onProceed}
                      >
                        Proceed
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md="6"
                      sm="6"
                      lg="6"
                      style={{ textAlign: "right" }}
                    >
                      <CopyRight></CopyRight>
                    </Grid>
                  </Grid>
                  <span className="copyright-statement">
                    We encourage collaboration and discourage infringement,
                    given that our team has put in enormous passion and effort
                    to bring you this learning product. We thank you for your
                    cooperation.
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p style={{ color: "red", fontSize: "18px" }}>
                    Please contact admin or write an email to
                    support@etq-global.com
                  </p>
                </React.Fragment>
              )}
            </div>
            {showProgressBar ? (
              <CircleProgressBar
                closeProgressBar={handleCloseProgressBar}
                count="10"
              ></CircleProgressBar>
            ) : null}
          </Grid>
          <Hidden only={["sm", "lg", "md", "xl"]}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className={classes.infoGraphicImageContainer}
            >
              <InfoGraphicImage className={classes.infoGraphicImage} />
            </Grid>
          </Hidden>
        </Grid>
      )}
      {isPlayAudio ? <Audio></Audio> : null}
    </div>
  );
}

export default Test;
