/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import PrimaryButton from "../common/PrimaryButton";
import CopyRight from "../common/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { Types } from "../store/Types";
import { Paper } from "@material-ui/core";
import { ReactComponent as InfoGraphicImage } from "./../../assets/images/info-4-new.svg";
import CircularProgressBar from "../common/CircularProgressBar";
import validator from "validator";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    ["@media (max-width:480px)"]: {
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    ["@media (max-width:480px)"]: {
      font: "normal normal bold 18px/28px PoppinsBold",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  inputField: {
    color: "#fff",
    background: "#1D1D1B 0% 0% no-repeat",
    border: "1px solid #343639",
    borderRadius: "6px",
    opacity: 1,
    height: 40,
  },
  textField: {
    background: "#626262 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    opacity: 1,
    height: "40px",
    width: "96%",
    color: "#fff",
    margin: "0 0 1rem",
    paddingLeft: 12,
    fontSize: "1rem",
  },
  title: {
    font: "normal normal 600 21px/30px PoppinsSemiBold",
    letterSpacing: "0",
    ["@media (max-width:480px)"]: {
      font: "normal normal 600 21px/30px PoppinsSemiBold",
    },
  },
  loginForm: {
    // margin: "1rem 0",
    // width: "70%",
    // ["@media (max-width:480px)"]: {
    //   width: "90%",
    // },
  },
  link: {
    color: "#ffcd05",
    textDecoration: "underline",
    font: "normal normal bold 14px/28px PoppinsSemiBold",
    cursor: "pointer",
  },
  caption: {
    font: "normal normal 500 12px/24px PoppinsMedium",
    letterSpacing: "0px",
    color: "#FFFFFF",
    // opacity: 0.5,
    marginBottom: "1rem",
    ["@media (max-width:480px)"]: {
      font: "normal normal 500 12px/24px PoppinsMedium",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
    margin: "10px 0",
  },
  infoGraphicImage: {
    maxWidth: "100%",
  },
  infoGraphicImageContainer: {
    flex: 1,
    backgroundColor: "#F4F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:480px)"]: {
      padding: "0",
      position: "relative",
    },
  },
  thickRightBorder: {
    background: "#508DFC 0% 0% no-repeat padding-box",
    position: "absolute",
    left: 0,
    top: "35%",
    width: 10,
    height: 180,
    ["@media (max-width:480px)"]: {
      top: "24%",
      width: 6,
    },
  },
  rightSideContentContainer: {
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
  },
  rightSideContainer: {
    backgroundColor: "#1D1D1B",
    color: "#fff",
    textAlign: "left",
    // padding: "3rem 3rem 1rem",
    width: "100%",
    ["@media (max-width:480px)"]: {
      padding: "4rem 2rem 2rem",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyRight: {
    position: "absolute",
    bottom: "10px",
    left: "auto",
    ["@media (max-width:480px)"]: {
      // padding: "0 1rem 1rem",
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [userEmailAddress, setUserEmailAddress] = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const history = useHistory();

  const handleUserInput = (e) => {
    if (e.target.name === "email") {
      if (e.target.value === "" || e.target.value == null) {
        setEmailErrorMessage("Email can not be empty.");
        setUserEmailAddress("");
      } else {
        setUserEmailAddress(e.target.value);
        setEmailErrorMessage("");
      }
    }
  };

  const handleForgotPassword = () => {
    history.push("/login");
  };

  useEffect(() => {
    if (
      authReducer.isPasswordResetted &&
      authReducer.resetPasswordSuccessMsg !== ""
    ) {
      setSuccessMsg(authReducer.resetPasswordSuccessMsg);
      setUserEmailAddress(() => "");
      setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
    } else if (
      authReducer.isPasswordResetted &&
      authReducer.errorMessage !== ""
    ) {
      setUserEmailAddress(() => "");
      setErrorMsg(authReducer.errorMessage);
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
    return () => {
      console.log("reset password cleanup");
    };
  }, [authReducer.isPasswordResetted]);

  const onClickAssesment = () => {
    if (userEmailAddress === "" || userEmailAddress === null) {
      setEmailErrorMessage("Email field can not be empty.");
    }

    if (userEmailAddress !== "") {
      if (validator.isEmail(userEmailAddress)) {
        setEmailErrorMessage("");
        let formData = { email: userEmailAddress };
        dispatch({
          type: Types.RESET_PASSWORD_REQUEST,
          payload: formData,
        });
      } else {
        setEmailErrorMessage("Enter a valid email!");
      }
    }
  };

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <Hidden only={["xs"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.rightSideContainer}>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  Forgot Password
                </Typography>
                <p className={classes.caption}>
                  Reset with your registered email id
                </p>
                <form className={classes.loginForm}>
                  <input
                    type="email"
                    name="email"
                    value={userEmailAddress}
                    placeholder="Enter your email id"
                    className={`${classes.textField} customTextField`}
                    onChange={(event) => handleUserInput(event)}
                    required
                  />
                  <div className={classes.errorMessage}>
                    {" "}
                    {emailErrorMessage}
                  </div>
                  <Grid container className={classes.flexContainer}>
                    <Grid
                      item
                      md="8"
                      sm="8"
                      xs="8"
                      style={{ display: "inline-flex" }}
                    >
                      <PrimaryButton
                        buttonText="Reset Password"
                        handleClick={onClickAssesment}
                      ></PrimaryButton>
                      <span style={{ marginLeft: "0.6rem" }}>
                        {authReducer.isLoading ? <CircularProgressBar /> : null}
                      </span>
                    </Grid>
                    <Grid item md="4" sm="4" xs="4">
                      <Link
                        to="/login"
                        className={classes.link}
                        variant="body2"
                        onClick={handleForgotPassword}
                      >
                        Back to Login
                      </Link>
                    </Grid>
                  </Grid>
                </form>
                <p style={{ color: "red" }}>{errorMsg}</p>
                <div>{successMsg ? <p>{successMsg}</p> : null}</div>

                <Hidden only={["xs"]}>
                  <div className={classes.copyRight}>
                    <CopyRight></CopyRight>
                  </div>
                </Hidden>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}></Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm", "lg", "md", "xl"]}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.infoGraphicImageContainer}
          >
            <InfoGraphicImage />
            {/* <div className={classes.copyRight}>
              <CopyRight></CopyRight>
            </div> */}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
