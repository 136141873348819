/* eslint-disable no-useless-computed-key */
import { Button, makeStyles, createMuiTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import SelectOptions from "./SelectOptions";
import LeftDrawer from "../common/Navigation";
import Modal from "../common/Modal";
import { CountDownTimer } from "./countdownTimer";
import { useSelector } from "react-redux";
import Qicon from "../../assets/images/test-bg-icon.png";
import QDarkicon from "../../assets/images/test-bg-dark-icon.png";
import arrowNextBlack from "../../assets/images/arrow-next-black-small.png";
import arrowNextWhite from "../../assets/images/arrow-next-white.png";
import arrowNextBlackBlur from "../../assets/images/arrow-next-black-blur.png";
import arrowNextBlackBlur2 from "../../assets/images/arrow-next-black-blur-2.svg";
import nextBtn from "../../assets/images/next_btn.png";

import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import Constants from "../../utils/constants/constants";
import ExpandLight from "../../assets/images/expand-light.png";
import ExpandDark from "../../assets/images/expand-dark.png";
import Parser from "html-react-parser";
import CircularProgressBar from "../common/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "calc(100vh - 100px)",
  },
  questionsContainer: {
    display: "flex",
    width: "100%",
    padding: "1rem 0 0.5rem",
    height: "auto",
  },
  leftSideContainer: {
    // width: "8%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  rightSideContainer: {
    width: "100%",
    textAlign: "left",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    marginLeft: "1rem",
    ["@media (max-width:780px)"]: {
      marginLeft: "0.5rem",
      width: "75%",
    },
  },
  title: {
    fontSize: "26px",
    lineHeight: "32px",
    fontFamily: "PoppinsSemiBold",
    fontWeight: 600,
    letterSpacing: "0px",
    marginBottom: 0,
    marginTop: 0,
  },
  nextQuestionBtn: {
    background: `transparent url(${nextBtn}) no-repeat padding-box`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "10%",
    height: "60px",
    backgroundSize: "contain",
    opacity: 1,
    margin: "0 2.5rem",
    ["@media (max-width:780px)"]: {
      width: "50%",
      height: "50px",
      padding: "18px !important",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "60px",
    backgroundColor: "transparent",
  },
  footerShadow: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
  darkFooter: {
    background: "#252525 0% 0% no-repeat padding-box",
  },

  informationIcon: {
    width: "26px",
  },
  informationText: {
    fontSize: "14px",
    marginLeft: "8px",
    textAlign: "left",
  },
  darkThemeDesc: {
    color: "#fff",
  },
  lightThemeDesc: {
    color: "#1d1d1b",
  },
  drawer: {
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
    ["@media (max-width:780px)"]: {
      width: "50%",
      height: "50px",
      justifyContent: "flex-start",
      padding: "0.4rem",
    },
  },
  yellowColor: {
    color: "#ffcd05",
    fontWeight: 600,
    marginLeft: "6px",
  },
  primaryButton: {
    fontSize: "18px",
    lineHeight: "19px",
    fontFamily: "PoppinsRegular",
    fontWeight: 700,
    letterSpacing: 0,
    color: "#1D1D1B",
    "&:hover": {
      backgroundColor: "#ffcd05 !important",
    },
    background: "#ffcd05 0% 0% no-repeat padding-box",
    width: "10%",
    height: "60px",
    borderRadius: 0,
    ["@media (max-width:780px)"]: {
      width: "50%",
      height: "50px",
    },
  },
  Qicon: {
    marginLeft: "3rem",
    ["@media (max-width:780px)"]: {
      fontSize: "12px",
      lineHeight: "30px",
      fontFamily: "PoppinsBold",
      fontWeight: 700,
      marginLeft: 0,
      width: 29,
      height: 22,
    },
  },
  noBoxShadow: {
    boxShadow: "none",
  },
  timerContainer: {
    display: "block",
    float: "left",
    marginTop: "2rem",
  },
  nextIcon: {
    fontSize: "14px",
    position: "absolute",
    right: "4%",
    top: "10%",
  },
  ht100: {
    height: "100%",
    ["@media (max-width:780px)"]: {
      // height: "auto",
    },
  },
  heightContainer: {
    height: "calc(100vh - 120px)",
    overflowY: "auto",
    padding: "0 140px",
    ["@media (max-width:780px)"]: {
      marginTop: "calc(10% - 30px)",
    },
  },
  darkThemeContainer: {
    backgroundColor: "#1D1D1B",
  },
  lightThemeContainer: {},
  imageContainer: {
    width: "85%",
    maxWidth: "85%",
    margin: "0rem 0 0 3rem",
    position: "relative",
    zIndex: 999,
    ["@media (max-width:780px)"]: {
      width: "85%",
      maxWidth: "85%",
      margin: "0 0 2rem 0",
    },
  },
  darkBorder: {
    border: "1px dashed #4C4F53",
  },
  lightBorder: {
    border: "1px dashed #C6C9D6",
  },
  expandIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    zIndex: "-1",
  },
  situationText: {
    font: "normal normal 600 24px/35px PoppinsSemiBold",
    letterSpacing: "0px",
    color: "#6BAC42",
    margin: "0 0 10px 0",
  },
}));

const light = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#fff",
      light: "#fff",
    },
    secondary: {
      main: "#F0F1F5",
    },
    background: {
      default: "#fff",
    },
  },
});

const dark = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1D1D1B",
      light: "#1D1D1B",
    },
    secondary: {
      main: "#0F0F0F",
    },
    background: {
      default: "#1D1D1B",
    },
  },
});

const Questions = (props) => {
  const [enableNextButton, setEnableNextButton] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [loader, showLoader] = useState(false);

  const classes = useStyles();
  const question = props.questions;
  const questionsFromStore = useSelector(
    (state) => state.TestReducer.questions.data
  );
  const AuthReducer = useSelector((state) => state.authReducer);
  const TestReducer = useSelector((state) => state.TestReducer);
  const appliedTheme = AuthReducer.darkTheme ? dark : light;

  if (question) {
    question.questions["uniqueId1"] = 1;
    question.questions["uniqueId2"] = 2;
    question.questions["uniqueId3"] = 3;
    question.questions["uniqueId4"] = 4;
    question.questions["uniqueId5"] = 5;
  }

  const handleSelectedAnswer = (event) => {
    setEnableNextButton(false);
    document.getElementById("nextButtonCss").style.opacity = 1;
    props.selectedOptions(event);
    // props.closeModalPopup(event);
  };

  const handleClick = () => {
    let answer_time = parseInt(
      ((performance.now() - startTime) / 1000).toFixed(0)
    );
    props.nextClick(answer_time);
    setShowTimer(false);
    setEnableNextButton(true);
    document.getElementById("nextButtonCss").style.opacity = 0.5;
  };

  const closeModelPopup = () => {
    props.closeModalPopup();
  };

  useEffect(() => {
    setShowTimer(true);
    setStartTime(performance.now());
    return () => {};
  }, [props.questions]);

  useEffect(() => {
    showLoader(TestReducer.isLoading);
    return () => {
      console.log("clean up");
    };
  }, [TestReducer.isLoading]);

  const getDescClass = () => {
    switch (TestReducer.selectedTheme) {
      case "science_light_bg":
        return "science_light_bg";

      case "jungle_light_bg":
        return "jungle_light_bg";

      case "class_light_bg":
        return "class_light_bg";

      default:
        return "description";
    }
  };

  const getNextBtnClass = () => {
    return TestReducer.selectedTheme === "default"
      ? "primaryQuestionBtn"
      : "nextQuestionBtn";
  };

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      <React.Fragment>
        {question && question.questions ? (
          <div className={classes.container}>
            <div className={classes.ht100}>
              <div
                className={`${classes.heightContainer} ${
                  TestReducer.isDarkThemeSelected && !TestReducer.selectedTheme
                    ? classes.darkThemeContainer
                    : classes.lightThemeContainer
                }`}
              >
                <div className={classes.timerContainer}>
                  {showTimer ? (
                    <React.Fragment>
                      {question.questions.time &&
                      Number(question.questions.time) > 0 ? (
                        <CountDownTimer
                          seconds={0}
                          time={Number(question.questions.time)}
                        />
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>
                <div className={classes.questionsContainer}>
                  <div className={classes.leftSideContainer}></div>
                  <div
                    className={classes.rightSideContainer}
                    style={{ display: "block" }}
                  >
                    {/* <div className="mb-16"> */}
                    <p className={classes.situationText}>Situation:</p>
                    <h1 className={classes.title}>
                      {Parser(question.questions.title)}
                    </h1>
                    {question.questions.description ? (
                      <p
                        className={`informationText ${getDescClass()} ${
                          TestReducer.isDarkThemeSelected
                            ? classes.darkThemeDesc
                            : classes.lightThemeDesc
                        }`}
                      >
                        {question.questions.description}
                      </p>
                    ) : null}
                    {/* </div> */}
                  </div>
                </div>

                <div className={classes.questionsContainer}>
                  <div className={classes.leftSideContainer}>
                    {/* <img
                      src={TestReducer.isDarkThemeSelected ? Qicon : Qicon}
                      alt=""
                      className={classes.Qicon}
                    /> */}
                  </div>
                  <div className={classes.rightSideContainer}>
                    <Grid container component="main" className={classes.root}>
                      <Grid
                        item
                        xs={false}
                        sm={question.questions.answerType === "image" ? 7 : 12}
                        md={question.questions.answerType === "image" ? 7 : 12}
                        className={classes.image}
                      >
                        <SelectOptions
                          selectOptions={question.questions}
                          selectedAnswer={handleSelectedAnswer}
                          loader={loader}
                        ></SelectOptions>
                      </Grid>
                      {question.questions.answerType === "image" ? (
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={5}
                          elevation={6}
                          className={classes.noBoxShadow}
                        >
                          {question.questions.answerType === "image" ? (
                            <div
                              className={`${classes.imageContainer} ${
                                TestReducer.isDarkThemeSelected
                                  ? classes.darkBorder
                                  : classes.lightBorder
                              }`}
                            >
                              <ReactFancyBox
                                thumbnail={`${Constants.IMAGE_END_POINT}/${question.questions.imageName}`}
                                image={`${Constants.IMAGE_END_POINT}/${question.questions.imageName}`}
                              />

                              <span className={classes.expandIcon}>
                                <img
                                  src={
                                    TestReducer.isDarkThemeSelected
                                      ? ExpandLight
                                      : ExpandDark
                                  }
                                  alt=""
                                />
                              </span>
                            </div>
                          ) : null}
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>
                </div>
                <span
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    color: "#f6ac29",
                  }}
                >
                  {loader ? <CircularProgressBar /> : null}
                </span>
              </div>
              <div
                className={`${classes.footer} ${
                  TestReducer.isDarkThemeSelected
                    ? classes.darkFooter
                    : classes.footer
                }
                ${
                  TestReducer.selectedTheme === "default"
                    ? classes.footerShadow
                    : null
                }`}
                component={Paper}
              >
                <div className={classes.drawer}>
                  <LeftDrawer></LeftDrawer>
                </div>
                <div className={classes.nextButton}>
                  <Button
                    id="nextButtonCss"
                    className={getNextBtnClass()}
                    onClick={handleClick}
                    disabled={enableNextButton}
                  >
                    Next{" "}
                    <span style={{ display: "flex" }}>
                      {enableNextButton ? (
                        <img
                          style={{
                            marginLeft: "5px",
                            height: "12px",
                            opacity: "0.4",
                          }}
                          src={arrowNextBlackBlur2}
                          alt="arrowNextBlack"
                        />
                      ) : (
                        <img
                          style={{ marginLeft: "5px", height: "12px" }}
                          src={arrowNextBlackBlur}
                          alt="arrowNextBlackBlur"
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {questionsFromStore.questions == null ? (
          <React.Fragment>
            <Modal closePopup={closeModelPopup}></Modal>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Questions;
