/* eslint-disable no-useless-computed-key */
import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import information from "../../assets/images/info.png";
import closeIcon from "../../assets/images/close-icon.png";
import Grid from "@material-ui/core/Grid";
import questorTitle from "./../../assets/images/questor-title.png";
import questorDarkTitle from "./../../assets/images/questor-title-dark.png";

const useStyles = makeStyles({
  drawerContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  drawerList: {
    background: "rgb(127 127 127) 0% 0% no-repeat padding-box",
    width: "50vw",
  },
  fullList: {
    width: "auto",
  },
  instructionList: {
    listStyle: "none",
    fontSize: "24px",
    marginTop: 0,
    padding: 0,
  },
  whiteColor: {
    opacity: 1,
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "PoppinsBold",
    fontWeight: "normal",
    letterSpacing: "0px",
  },

  list: {
    "&::before": {
      content: '"•"',
      color: "#F6AC29",
      fontWeight: "bold",
      width: "1em",
      marginLeft: "-1rem",
      display: "none",
    },
  },
  subList: {
    listStyleType: "none",
    padding: 0,
    marginLeft: "1rem",
    fontSize: "24px",
  },
  listText: {
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "PoppinsRegular",
    fontWeight: "normal",
    letterSpacing: "0px",
    color: "rgba(255, 255, 255, 0.7)",
  },
  instructionContainer: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  darkThemeInstructionContainer: {
    color: "#fff",
  },
  lightThemeInstructionContainer: {
    color: "#fff",
  },
  instructionContainerTitle: {
    font: "normal normal 600 24px/30px PoppinsBold",
    letterSpacing: "0px",
    margin: "1rem 0",
  },
  informationLightText: {
    font: "normal normal bold 24px/30px PoppinsBold",
    letterSpacing: "0px",
    color: "#000",
    opacity: 1,
    textAlign: "left",
    paddingLeft: "0.6rem",
    ["@media (max-width:480px)"]: {
      font: "normal normal 600 11px/14px PoppinsBold",
      paddingLeft: "0.2rem",
    },
  },
  informationDarkText: {
    font: "normal normal bold 24px/30px PoppinsBold",
    letterSpacing: "0px",
    color: "#fff",
    opacity: 1,
    textAlign: "left",
    paddingLeft: "0.6rem",
    ["@media (max-width:480px)"]: {
      font: "normal normal 600 11px/14px PoppinsBold",
      paddingLeft: "0.2rem",
    },
  },
  informationIcon: {
    height: "33px",
    width: "30px",
    ["@media (max-width:480px)"]: {
      height: "24px",
      width: "24px",
    },
  },
  closeIcon: {
    float: "right",
    clear: "right",
    marginBottom: "1rem",
    marginTop: "2rem",
    cursor: "pointer",
  },
  arrowIcon: {
    color: "#F6AC29",
    fontWeight: 700,
    marginLeft: "6px",
    cursor: "pointer",
    maxWidth: 7,
    maxHeight: 11,
  },
  arrowPrevIcon: {
    color: "#F6AC29",
    fontWeight: 700,
    marginLeft: "6px",
    position: "absolute",
    bottom: "2%",
    right: "10%",
    cursor: "pointer",
    width: "10px",
    ["@media (max-width:480px)"]: {
      right: "15%",
    },
  },
  questorTitle: {
    width: "100px",
    cursor: "pointer",
    marginLeft: "16px",
  },
});

export default function LeftDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const TestReducer = useSelector((state) => state.TestReducer);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(
    (event) => {
      let testAnchor = "left";
      if (TestReducer.openDrawer) {
        setState({ ...state, [testAnchor]: true });
      } else {
        setState({ ...state, [testAnchor]: false });
      }
      return () => {
        console.log("unmounting on closing drawer");
      };
    },
    [TestReducer.openDrawer]
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.drawerList, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container>
        <Grid item md="1" sm="1" xs="1"></Grid>
        <Grid item md="10" sm="10" xs="10">
          <div
            className={`${classes.instructionContainer} ${
              TestReducer.isDarkThemeSelected
                ? classes.darkThemeInstructionContainer
                : classes.lightThemeInstructionContainer
            }`}
          >
            <div>
              <img src={closeIcon} alt="" className={classes.closeIcon} />
            </div>
            <div>
              <img
                src={information}
                alt=""
                className={classes.informationIcon}
              />
              <Typography
                component="h5"
                variant="h5"
                className={classes.instructionContainerTitle}
              >
                General Instructions
              </Typography>
            </div>
            <div>
              {/* <ul className={classes.instructionList}>
                <li className={classes.list}>
                  <span className={classes.listText}>
                    The diagnostic product will typically take{" "}
                  </span>
                  <span className={classes.whiteColor}> 20-30 </span>
                  <span className={classes.listText} style={{ marginLeft: 0 }}>
                    minutes.
                  </span>
                </li>
                <li className={classes.list}>
                  <span className={classes.listText}>
                    This diagnostic has no{" "}
                  </span>
                  <span className={classes.whiteColor}>right </span>
                  <span className={classes.listText} style={{ marginLeft: 0 }}>
                    or
                  </span>
                  <span className={classes.whiteColor}> wrong </span>
                  <span className={classes.listText} style={{ marginLeft: 0 }}>
                    answer.
                  </span>
                </li>
                <li className={classes.list}>
                  <span className={classes.listText}>- There are no</span>
                  <span className={classes.whiteColor}> time limits </span>
                </li>
                <li className={classes.list}>
                  <span className={classes.listText}>
                    - We recommend you start when your
                  </span>
                  <span className={classes.whiteColor}> mind is free </span>
                </li>
                <li className={classes.list}>
                  <span className={classes.listText}>
                    {" "}
                    We strongly recommend you to not use any mobile phones
                    during the diagnostics. Mobile phones are best kept at
                    silent.{" "}
                  </span>
                </li>
                <li className={classes.list}>
                  <span className={classes.listText}>
                    This is a self - diagnostic product.
                  </span>
                </li>
              </ul> */}
              <ul className={classes.instructionList}>
                <li className={`${classes.list} ${classes.listText}`}>
                  Questor Learning is about diagnosing, measuring and learning
                  about your
                  <span className={classes.whiteColor}>
                    {" "}
                    Entrepreneurial Thinking{" "}
                  </span>
                  (mindset).
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  It will typically take
                  <span className={classes.whiteColor}> 20-30 </span>
                  minutes.
                </li>
                <li className={classes.list} style={{ margin: "10px 0 10px" }}>
                  <span className={classes.whiteColor}>
                    BE PATIENT. READ AND UNDERSTAND THE REPORT CAREFULLY.
                  </span>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Everything is well explained in the report. Take the
                        time to read it thoroughly and focus on understanding
                        it.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        This will provide a much better understanding of your
                        score and list of actions.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li
                  className={`${classes.list} ${classes.whiteColor}`}
                  style={{ margin: "10px 0 10px" }}
                >
                  Please read the Questor Learning briefing document (available at the
                  report landing page) before reading the report.
                </li>

                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        We recommend you not to use any electronic devices
                        (including mobile phones) during the entire process.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Use this product at a quiet time and place when your
                        mind is free.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        There are no
                        <span className={classes.whiteColor}> right </span>
                        or
                        <span className={classes.whiteColor}> wrong </span>
                        answers.
                      </p>
                    </Grid>
                  </Grid>
                </li>
                <li className={`${classes.list} ${classes.listText}`}>
                  <Grid container>
                    <Grid item xs={1} className="listingBulletContainer">
                      <p>-</p>
                    </Grid>
                    <Grid item xs={11} className="listingBulletTextContainer">
                      <p>
                        Few questions will have time limits. You focus on making
                        the selections.
                      </p>
                    </Grid>
                  </Grid>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item md="1" sm="1" xs="1"></Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className={classes.drawerContainer}>
            <img src={information} alt="" className={classes.informationIcon} />
            {TestReducer.isDarkThemeSelected ? (
              <img
                src={questorTitle}
                alt=""
                onClick={toggleDrawer(anchor, true)}
                className={classes.questorTitle}
              />
            ) : (
              <img
                src={questorDarkTitle}
                alt=""
                onClick={toggleDrawer(anchor, true)}
                className={classes.questorTitle}
              />
            )}
            {/* <span
              className={`${
                TestReducer.isDarkThemeSelected
                  ? classes.informationDarkText
                  : classes.informationLightText
              } `}
            >
              questor
            </span> */}
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              hideBackdrop={true}
            >
              {list(anchor)}
            </Drawer>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
