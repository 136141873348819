import React, { useEffect, useState } from "react";

const CircleProgressBar = (props) => {
  let [count, setCount] = useState(props.count);

  useEffect(() => {
    let ele = document.getElementById("overlay");
    if (ele) {
      ele.style.display = "block";
    }
    let interval = setInterval(() => {
      let ele = document.getElementById("overlay");
      if (count <= 0) {
        clearInterval(interval);
        props.closeProgressBar();
        if (ele) {
          ele.style.display = "none";
        }
      } else {
        if (ele) {
          ele.style.display = "block";
        }
        let decrementedCount = count--;
        setCount(decrementedCount);
      }
    }, 1000);
    return () => {
      console.log("unmount progress bar component");
    };
  }, [props.count]);

  return (
    <div className="circleContainer">
      <div className="circle center">
        <div className="count">{count}</div>
        <div className="l-half"></div>
        <div className="r-half"></div>
      </div>
    </div>
  );
};

export default CircleProgressBar;
