import "./App.css";
import "./animate.css";
import Topbar from "./components/common/Topbar.jsx";
import Home from "./components/home/Home.jsx";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import SimpleReactLightbox from "simple-react-lightbox";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Test from "./components/test/Test";
import Login from "./components/home/Login";
import ForgotPassword from "./components/home/ForgotPassword";
import ChangePassword from "./components/home/ChangePassword";
import ForceResetPassword from "./components/home/ForceResetPassword";
import QuestorReport from "./components/home/Report";
import React, { useRef, useState, useLayoutEffect } from "react";
import Register from "./components/home/Register";
import EmailVerification from "./components/home/EmailVerification";
import { useSelector } from "react-redux";
import Welcome from "./components/home/Welcome";
import PageNotFound from "./components/home/PageNotFound";
import RegisterOTP from "./components/home/registerOTP.jsx";
import LoginOTP from "./components/home/LoginOTP.jsx";
import VerifyOTP from "./components/home/VerifyOTP.jsx";
import Report from "./components/report/Report.jsx";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["RalewayRegular"].join(","),
  },
  palette: {
    type: "dark",
  },
});

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.outerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function App(props) {
  const containerRef = useRef(null);
  const TestReducer = useSelector((state) => state.TestReducer);
  const AuthReducer = useSelector((state) => state.authReducer);
  const [width, height] = useWindowSize();

  const overlayOff = () => {
    containerRef.current.style.display = "none";
  };

  return (
    <Router>
      <div className="App" style={{ position: "relative" }}>
        <React.Fragment>
          {" "}
          {TestReducer.isBgImageSet && AuthReducer.isLoggedIn ? (
            <React.Fragment>
              <div
                className="questionBg"
                style={{
                  backgroundImage:
                    width > 780
                      ? `url(https://etqglobal.solutions/images/${TestReducer.selectedTheme}.png)`
                      : `url(https://etqglobal.solutions/images/${TestReducer.selectedTheme}_xs.png)`,
                }}
              ></div>{" "}
              <div
                className="footerBg"
                style={{
                  backgroundImage:
                    width > 780
                      ? `url(https://etqglobal.solutions/images/${TestReducer.selectedTheme}_footer.png)`
                      : `url(https://etqglobal.solutions/images/${TestReducer.selectedTheme}_xs_footer.png)`,
                }}
              ></div>{" "}
            </React.Fragment>
          ) : null}{" "}
        </React.Fragment>
        <ThemeProvider theme={theme}>
          <div id="overlay" ref={containerRef} onClick={overlayOff}>
            {" "}
          </div>{" "}
          <SimpleReactLightbox>
            <Topbar> </Topbar>
            <Switch>
              <Route exact path="/" component={Home} />{" "}
              <Route exact path="/login" component={LoginOTP} />{" "}
              <Route exact path="/verify-otp" component={VerifyOTP} />{" "}
              {/* <Route exact path="/login" component={Login} />{" "} */}
              {/* <Route exact path="/register" component={Register} />{" "} */}
              <Route exact path="/register" component={RegisterOTP} />{" "}
              
              <Route
                exact
                path="/verify-authorized-email"
                component={EmailVerification}
              />{" "}
              <Route exact path="/test" component={Test} />{" "}
              <Route exact path="/forgotPassword" component={ForgotPassword} />{" "}
              <Route exact path="/changePassword" component={ChangePassword} />{" "}
              {/* <Route exact path="/report" component={QuestorReport} />{" "} */}
              <Route exact path="/report" component={Report} />{" "}
              <Route exact path="/welcome" component={Welcome} />{" "}
              <Route exact path="/forceResetPassword" component={ForceResetPassword} />{" "}
              <Route exact path="/new-report" component={Report} />{" "}
              <Route path="*" component={PageNotFound} />

            </Switch>{" "}
          </SimpleReactLightbox>{" "}
        </ThemeProvider>{" "}
      </div>{" "}
    </Router>
  );
}

export default App;