import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "90vh",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    primaryButton: {
        background: "#ffcd05 0% 0% no-repeat",
        borderRadius: 23,
        opacity: 1,
        color: "#1D1D1B",
        "&:hover, &:focus": {
            background: "#ffcd05 0% 0% no-repeat padding-box",
        },
        fontSize: "16px",
        lineHeight: "19px",
        fontFamily: "PoppinsMedium",
        fontWeight: 500,
        textTransform: 'capitalize',
        padding: '12px 23px',
    },
    arrowIcon: {
        marginLeft: 5,
    }
}));


export default function PrimaryButton(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Button color="inherit"
                className={classes.primaryButton}
                onClick={props.handleClick} > {props.buttonText}
            </Button>
        </React.Fragment>
    );

}