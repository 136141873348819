import React from "react";

const SvgScoringSystem = (props) => {
  const getPolyganClass = (score, htIndex) => {
    if (score === htIndex) {
      return `opacity-${htIndex}`;
    } else {
      return "opacity-0";
    }
  };

  const getDualPolyganClass = (score1, score2) => {
    if (score1 === 5 && score2 === 5) {
      return `opacity-${score1}`;
    } else {
      return "opacity-0";
    }
  };

  const getTribalPolyganClass = (score1, score2, score3) => {
    if (score1 === 5 && score2 === 5 && score3 === 5) {
      return `opacity-${score1}`;
    } else {
      return "opacity-0";
    }
  };
  return (
    <React.Fragment>
      <svg
        id="Full"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 740"
      >
        <g id="Axes_Scoring_All" data-name="Axes Scoring All">
          <g
            id="HT_4-5"
            data-name="HT 4-5"
            className={getPolyganClass(Math.ceil(props.data.htAvgScore), 5)}
          >
            <polygon
              class="cls-3"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-3"
              points="320 440 320 540 400 580 400 480 320 440"
            />
            <polygon
              class="cls-3"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g
            id="HT_3-4"
            data-name="HT 3-4"
            className={getPolyganClass(Math.ceil(props.data.htAvgScore), 4)}
          >
            {/* <g id="HT_3-4" data-name="HT 3-4" class="cls-11"> */}
            <polygon
              class="cls-3"
              points="240 480 320 440 400 480 320 520 240 480"
            />
            <polygon
              class="cls-3"
              points="240 480 240 580 320 620 320 520 240 480"
            />
            <polygon
              class="cls-3"
              points="400 480 400 580 320 620 320 520 400 480"
            />
          </g>
          <g
            id="HT_2-3"
            data-name="HT 2-3"
            className={getPolyganClass(Math.ceil(props.data.htAvgScore), 3)}
          >
            <polygon
              class="cls-3"
              points="160 520 240 480 320 520 240 560 160 520"
            />
            <polygon
              class="cls-3"
              points="160 520 160 620 240 660 240 560 160 520"
            />
            <polygon
              class="cls-3"
              points="320 520 320 620 240 660 240 560 320 520"
            />
          </g>
          <g
            id="HT_1-2"
            data-name="HT 1-2"
            className={getPolyganClass(Math.ceil(props.data.htAvgScore), 2)}
          >
            <polygon
              class="cls-3"
              points="80 560 160 520 240 560 160 600 80 560"
            />
            <polygon
              class="cls-3"
              points="80 560 80 660 160 700 160 600 80 560"
            />
            <polygon
              class="cls-3"
              points="240 560 240 660 160 700 160 600 240 560"
            />
          </g>
          <g
            id="HT_0-1"
            data-name="HT 0-1"
            className={getPolyganClass(Math.ceil(props.data.htAvgScore), 1)}
          >
            <polygon class="cls-3" points="0 600 80 560 160 600 80 640 0 600" />
            <polygon class="cls-3" points="0 600 0 700 80 740 80 640 0 600" />
            <polygon
              class="cls-3"
              points="160 600 160 700 80 740 80 640 160 600"
            />
          </g>
          <g
            id="RT_4-5"
            data-name="RT 4-5"
            className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 5)}
          >
            <polygon
              class="cls-1"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-1"
              points="320 440 320 540 400 580 400 480 320 440"
            />
            <polygon
              class="cls-1"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g
            id="RT_3-4"
            data-name="RT 3-4"
            className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 4)}
          >
            <polygon
              class="cls-1"
              points="320 340 400 300 480 340 400 380 320 340"
            />
            <polygon
              class="cls-1"
              points="320 340 320 440 400 480 400 380 320 340"
            />
            <polygon
              class="cls-1"
              points="480 340 480 440 400 480 400 380 480 340"
            />
          </g>
          <g
            id="RT_2-3"
            data-name="RT 2-3"
            className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 3)}
          >
            <polygon
              class="cls-1"
              points="320 240 400 200 480 240 400 280 320 240"
            />
            <polygon
              class="cls-1"
              points="320 240 320 340 400 380 400 280 320 240"
            />
            <polygon
              class="cls-1"
              points="480 240 480 340 400 380 400 280 480 240"
            />
          </g>
          <g
            id="RT_1-2"
            data-name="RT 1-2"
            className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 2)}
          >
            <polygon
              class="cls-1"
              points="320 140 400 100 480 140 400 180 320 140"
            />
            <polygon
              class="cls-1"
              points="320 140 320 240 400 280 400 180 320 140"
            />
            <polygon
              class="cls-1"
              points="480 140 480 240 400 280 400 180 480 140"
            />
          </g>
          <g
            id="RT_0-1"
            data-name="RT 0-1"
            className={getPolyganClass(Math.ceil(props.data.rtAvgScore), 1)}
          >
            <polygon class="cls-1" points="320 40 400 0 480 40 400 80 320 40" />
            <polygon
              class="cls-1"
              points="320 40 320 140 400 180 400 80 320 40"
            />
            <polygon
              class="cls-1"
              points="480 40 480 140 400 180 400 80 480 40"
            />
          </g>

          <g
            id="IT_4-5"
            data-name="IT 4-5"
            className={getPolyganClass(Math.ceil(props.data.itAvgScore), 5)}
          >
            <polygon
              class="cls-5"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-5"
              points="320 440 320 540 400 580 400 480 320 440"
            />
            <polygon
              class="cls-5"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g
            id="IT_3-4"
            data-name="IT 3-4"
            className={getPolyganClass(Math.ceil(props.data.itAvgScore), 4)}
          >
            <polygon
              class="cls-5"
              points="400 480 480 440 560 480 480 520 400 480"
            />
            <polygon
              class="cls-5"
              points="400 480 400 580 480 620 480 520 400 480"
            />
            <polygon
              class="cls-5"
              points="560 480 560 580 480 620 480 520 560 480"
            />
          </g>
          <g
            id="IT_2-3"
            data-name="IT 2-3"
            className={getPolyganClass(Math.ceil(props.data.itAvgScore), 3)}
          >
            <polygon
              class="cls-5"
              points="480 520 560 480 640 520 560 560 480 520"
            />
            <polygon
              class="cls-5"
              points="480 520 480 620 560 660 560 560 480 520"
            />
            <polygon
              class="cls-5"
              points="640 520 640 620 560 660 560 560 640 520"
            />
          </g>
          <g
            id="IT_1-2"
            data-name="IT 1-2"
            className={getPolyganClass(Math.ceil(props.data.itAvgScore), 2)}
          >
            <polygon
              class="cls-5"
              points="560 560 640 520 720 560 640 600 560 560"
            />
            <polygon
              class="cls-5"
              points="560 560 560 660 640 700 640 600 560 560"
            />
            <polygon
              class="cls-5"
              points="720 560 720 660 640 700 640 600 720 560"
            />
          </g>
          <g
            id="IT_0-1"
            data-name="IT 0-1"
            className={getPolyganClass(Math.ceil(props.data.itAvgScore), 1)}
          >
            <polygon
              class="cls-5"
              points="640 600 720 560 800 600 720 640 640 600"
            />
            <polygon
              class="cls-5"
              points="640 600 640 700 720 740 720 640 640 600"
            />
            <polygon
              class="cls-5"
              points="800 600 800 700 720 740 720 640 800 600"
            />
          </g>
          <g
            id="IT_HT_4-5"
            data-name="IT + HT 4-5"
            className={getDualPolyganClass(
              Math.ceil(props.data.htAvgScore),
              Math.ceil(props.data.itAvgScore)
            )}
          >
            <polygon
              class="cls-4"
              points="320 440 320 540 400 580 400 480 320 440"
            />
            <polygon
              class="cls-6"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g
            id="RT_HT_4-5"
            data-name="RT + HT 4-5"
            className={getDualPolyganClass(
              Math.ceil(props.data.htAvgScore),
              Math.ceil(props.data.rtAvgScore)
            )}
          >
            <polygon
              class="cls-2"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-4"
              points="320 440 320 540 400 580 400 480 320 440"
            />
          </g>
          <g
            id="RT_IT_4-5"
            data-name="RT + IT 4-5"
            className={getDualPolyganClass(
              Math.ceil(props.data.itAvgScore),
              Math.ceil(props.data.itAvgScore)
            )}
          >
            <polygon
              class="cls-2"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-6"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g
            id="All_4-5"
            data-name="All 4-5"
            className={getTribalPolyganClass(
              Math.ceil(props.data.htAvgScore),
              Math.ceil(props.data.itAvgScore),
              Math.ceil(props.data.rtAvgScore)
            )}
          >
            <polygon
              class="cls-2"
              points="320 440 400 400 480 440 400 480 320 440"
            />
            <polygon
              class="cls-4"
              points="320 440 320 540 400 580 400 480 320 440"
            />
            <polygon
              class="cls-6"
              points="480 440 480 540 400 580 400 480 480 440"
            />
          </g>
          <g id="All_4-5_Box" data-name="All 4-5 Box">
            <path
              class="cls-7"
              d="m400,401.12l77.76,38.88-77.76,38.88-77.76-38.88,77.76-38.88m0-1.12l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m321,441.62l78,39v97.76l-78-39v-97.76m-1-1.62v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m479,441.62v97.76l-78,39v-97.76l78-39m1-1.62l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="RT_3-4_Box" data-name="RT 3-4 Box">
            <path
              class="cls-7"
              d="m400,300.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m320.25,340.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m479.75,340.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="RT_2-3_Box" data-name="RT 2-3 Box">
            <path
              class="cls-7"
              d="m400,200.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m320.25,240.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m479.75,240.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="RT_1-2_Box" data-name="RT 1-2 Box">
            <path
              class="cls-7"
              d="m400,100.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m320.25,140.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m479.75,140.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="RT_0-1_Box" data-name="RT 0-1 Box">
            <path
              class="cls-7"
              d="m400,.28l79.44,39.72-79.44,39.72-79.44-39.72L400,.28m0-.28l-80,40,80,40,80-40L400,0h0Z"
            />
            <path
              class="cls-7"
              d="m320.25,40.4l79.5,39.75v99.44l-79.5-39.75V40.4m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m479.75,40.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40V40h0Z"
            />
          </g>
          <g id="HT_3-4_Box" data-name="HT 3-4 Box">
            <path
              class="cls-7"
              d="m320,440.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m240.25,480.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m399.75,480.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="HT_2-3_Box" data-name="HT 2-3 Box">
            <path
              class="cls-7"
              d="m240,480.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m160.25,520.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m319.75,520.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="HT_1-2_Box" data-name="HT 1-2 Box">
            <path
              class="cls-7"
              d="m160,520.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m80.25,560.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m239.75,560.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="HT_0-1_Box" data-name="HT 0-1 Box">
            <path
              class="cls-7"
              d="m80,560.28l79.44,39.72-79.44,39.72L.56,600l79.44-39.72m0-.28L0,600l80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m.25,600.4l79.5,39.75v99.44L.25,699.85v-99.44m-.25-.4v100l80,40v-100L0,600h0Z"
            />
            <path
              class="cls-7"
              d="m159.75,600.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="IT_3-4_Box" data-name="IT 3-4 Box">
            <path
              class="cls-7"
              d="m480,440.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m400.25,480.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m559.75,480.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="IT_2-3_Box" data-name="IT 2-3 Box">
            <path
              class="cls-7"
              d="m560,480.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m480.25,520.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m639.75,520.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="IT_1-2_Box" data-name="IT 1-2 Box">
            <path
              class="cls-7"
              d="m640,520.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m560.25,560.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m719.75,560.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="IT_0-1_Box" data-name="IT 0-1 Box">
            <path
              class="cls-7"
              d="m720,560.28l79.44,39.72-79.44,39.72-79.44-39.72,79.44-39.72m0-.28l-80,40,80,40,80-40-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m640.25,600.4l79.5,39.75v99.44l-79.5-39.75v-99.44m-.25-.4v100l80,40v-100l-80-40h0Z"
            />
            <path
              class="cls-7"
              d="m799.75,600.4v99.44l-79.5,39.75v-99.44l79.5-39.75m.25-.4l-80,40v100l80-40v-100h0Z"
            />
          </g>
          <g id="RT_0" data-name="RT 0">
            <path
              class="cls-7"
              d="m391.32,27.82c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1,2.61,6.51,2.61,11.33-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47s1.99,1.32,3.55,1.32,2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
            />
          </g>
          <g id="HT_0" data-name="HT 0">
            <path
              class="cls-7"
              d="m31.32,658.61c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1c1.74,2.73,2.61,6.51,2.61,11.33s-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47s1.99,1.32,3.55,1.32,2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
            />
          </g>
          <g id="IT_0" data-name="IT 0">
            <path
              class="cls-7"
              d="m752.92,656.58c1.74-2.73,4.63-4.1,8.68-4.1s6.93,1.37,8.68,4.1c1.74,2.73,2.61,6.51,2.61,11.33s-.87,8.66-2.61,11.42c-1.74,2.76-4.64,4.14-8.68,4.14s-6.94-1.38-8.68-4.14c-1.74-2.76-2.61-6.56-2.61-11.42s.87-8.6,2.61-11.33Zm13.8,6.13c-.26-1.41-.8-2.56-1.59-3.45-.8-.89-1.97-1.34-3.53-1.34s-2.74.45-3.53,1.34c-.8.89-1.32,2.04-1.59,3.45-.27,1.41-.4,3.14-.4,5.21s.13,3.9.38,5.33c.25,1.44.78,2.59,1.59,3.47.81.88,1.99,1.32,3.55,1.32s2.75-.44,3.55-1.32c.81-.88,1.34-2.03,1.59-3.47.25-1.44.38-3.21.38-5.33s-.13-3.8-.4-5.21Z"
            />
          </g>
          <g id="_5" data-name="5">
            <path d="m416.95,466.62h-23.23v12.26c1-1.24,2.44-2.26,4.3-3.05,1.86-.79,3.85-1.18,5.95-1.18,3.82,0,6.95.84,9.39,2.51,2.44,1.67,4.2,3.81,5.3,6.42,1.1,2.61,1.65,5.39,1.65,8.35,0,5.5-1.57,9.92-4.7,13.26-3.13,3.34-7.59,5.02-13.37,5.02-5.45,0-9.8-1.36-13.05-4.09-3.25-2.72-5.09-6.28-5.52-10.68h9.75c.43,1.91,1.4,3.44,2.9,4.59,1.51,1.15,3.43,1.72,5.77,1.72,2.82,0,4.95-.88,6.38-2.65,1.43-1.77,2.15-4.11,2.15-7.02s-.73-5.22-2.19-6.77c-1.46-1.55-3.6-2.33-6.42-2.33-2.01,0-3.68.5-5.02,1.51-1.34,1-2.29,2.34-2.87,4.01h-9.6v-30.68h32.4v8.82Z" />
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};

export default SvgScoringSystem;
