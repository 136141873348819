import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Types } from '../store/Types';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    color: "#fff",
    cursor: "pointer",
  },
  darkTheme: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    color: "#151515"
  },
  lightTheme: {
    background: "#626262 0% 0% no-repeat padding-box",
  },
}));

export default function CustomizedTooltips(props) {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const TestReducer = useSelector((state) => state.TestReducer);

  const handleLogout = () => {
    dispatch({
      type: Types.USER_LOGOUT_REQUEST,
    });
    dispatch({
      type: Types.SET_THEME,
      payload: false,
    });
    sessionStorage.removeItem("token");
    history.push("/login");
  };

  const handleMouseEnter = () => {
    dispatch({
      type: Types.MOUSE_ENTER_EVENT,
    });
  };

  const handleMouseLeave = () => {
    dispatch({
      type: Types.MOUSE_LEAVE_EVENT,
    });
  };

  return (
    <nav style={{ paddingRight: 16, zIndex: 1 }}>
      <ul>
        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}><a href="#">
          <span className="tooltip">
            <Avatar
              className={`${classes.tooltipContainer} ${TestReducer.isDarkThemeSelected
                ? classes.darkTheme
                : classes.lightTheme
                }`}
            >
              {`${props.userInfo.firstName.charAt(0)}`}
            </Avatar>
          </span>
        </a>
          <ul>
            <li><a href="#">My Profile</a></li>
            <li onClick={handleLogout}><a>Logout</a></li>
          </ul>
        </li>
      </ul>
    </nav>

  );
}
