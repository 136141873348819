/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  radioButton: {
    border: 0,
    width: "1.5rem",
    height: "2rem",
    cursor: "pointer",
  },
  radioButtonLabel: {
    cursor: "pointer",
    textAlign: "left",
    fontSize: "22px",
    lineHeight: "30px",
    fontFamily: "PoppinsMedium",
    fontWeight: 500,
    letterSpacing: "0px",
    opacity: 0.7,
    margin: "0.35rem 0",
    ["@media (max-width:480px)"]: {
      font: "normal normal 500 24px/30px PoppinsMedium",
    },
  },
  flexContainer: {
    display: "inline-block",
    alignItems: "center",
    marginBottom: 10,
  },
  selectedAnswerContainer: {
    backgroundColor: "red",
  },
  decisionText: {
    font: "normal normal 600 24px/35px PoppinsSemiBold",
    letterSpacing: "0px",
    color: "#6BAC42",
    margin: "0 0 10px 0",
  },
});

const SelectOptions = (props) => {
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [previousState, setPreviousState] = useState(null);
  const TestReducer = useSelector((state) => state.TestReducer);
  const {
    questionChoice1,
    questionChoice2,
    questionChoice3,
    questionChoice4,
    questionChoice5,
    probability1,
    probability2,
    probability3,
    probability4,
    probability5,
    assessmentCategoryId,
    assessmentSubCategoryId,
    state1,
    state2,
    state3,
    state4,
    state5,
    reportState1,
    reportState2,
    reportState3,
    reportState4,
    reportState5,
    questionChoiceMark1,
    questionChoiceMark2,
    questionChoiceMark3,
    questionChoiceMark4,
    questionChoiceMark5,
    uniqueId1,
    uniqueId2,
    uniqueId3,
    uniqueId4,
    uniqueId5,
  } = props.selectOptions;

  const handleChange = (event) => {
    setSelectedRadio(event.target.value);
    props.selectedAnswer(event.target);
  };

  const getSelectedAnswerBgColor = () => {
    switch (TestReducer.selectedTheme) {
      case "science_light_bg":
        return "selectedSkyAnswer";

      case "jungle_light_bg":
        return "selectedJungleAnswer";

      case "class_light_bg":
        return "selectedClassAnswer";

      case "science_dark_bg":
        return "selectedSkyDarkAnswer";

      case "jungle_dark_bg":
        return "selectedJungleDarkAnswer";

      case "class_dark_bg":
        return "selectedClassDarkAnswer";

      default:
        return "selectedAnswer";
    }
  };

  useEffect(() => {
    // if (TestReducer.isAnswerPosted) {
    if (previousState) {
      if (TestReducer.isDarkThemeSelected) {
        let aa = document.getElementsByClassName("selectedDarkAnswer");
        for (let index = 0; index < aa.length; index++) {
          aa[index].classList.remove("selectedDarkAnswer");
        }
        let ab = document.getElementsByClassName("selectedAnswer");
        for (let index = 0; index < ab.length; index++) {
          ab[index].classList.add("selectedDarkAnswer");
          ab[index].classList.remove("selectedAnswer");
        }
      } else {
        let aa = document.getElementsByClassName("selectedAnswer");
        for (let index = 0; index < aa.length; index++) {
          aa[index].classList.remove("selectedAnswer");
        }
        let ab = document.getElementsByClassName("selectedDarkAnswer");
        for (let index = 0; index < ab.length; index++) {
          ab[index].classList.add("selectedAnswer");
          ab[index].classList.remove("selectedDarkAnswer");
        }
      }

      let ab = document.getElementsByClassName("selectedSkyAnswer");
      for (let index = 0; index < ab.length; index++) {
        ab[index].classList.remove("selectedSkyAnswer");
      }
      let ac = document.getElementsByClassName("selectedJungleAnswer");
      for (let index = 0; index < ac.length; index++) {
        ac[index].classList.remove("selectedJungleAnswer");
      }
      let ad = document.getElementsByClassName("selectedClassAnswer");
      for (let index = 0; index < ad.length; index++) {
        ad[index].classList.remove("selectedClassAnswer");
      }

      let ae = document.getElementsByClassName("selectedSkyDarkAnswer");
      for (let index = 0; index < ae.length; index++) {
        ae[index].classList.remove("selectedSkyDarkAnswer");
      }
      let af = document.getElementsByClassName("selectedJungleDarkAnswer");
      for (let index = 0; index < af.length; index++) {
        af[index].classList.remove("selectedJungleDarkAnswer");
      }
      let ag = document.getElementsByClassName("selectedClassDarkAnswer");
      for (let index = 0; index < ag.length; index++) {
        ag[index].classList.remove("selectedClassDarkAnswer");
      }
    }
    // }
    return () => {};
  }, [TestReducer.isAnswerPosted, TestReducer.isDarkThemeSelected]);

  const handleClick = (event) => {
    if (event.target.checked) {
      setPreviousState(event.target.step);
      let element = document.getElementById(`${event.target.step}`);
      if (TestReducer.isDarkThemeSelected) {
        element.classList.add("selectedDarkAnswer");
      } else {
        element.classList.add(getSelectedAnswerBgColor());
      }
      if (previousState && previousState !== event.target.step) {
        let ele = document.getElementById(previousState);
        if (ele) {
          ele.classList.remove("selectedSkyAnswer");
          ele.classList.remove("selectedJungleAnswer");
          ele.classList.remove("selectedClassAnswer");
          ele.classList.remove("selectedSkyDarkAnswer");
          ele.classList.remove("selectedJungleDarkAnswer");
          ele.classList.remove("selectedClassDarkAnswer");
          ele.classList.remove("selectedAnswer");
          ele.classList.remove("selectedDarkAnswer");
        }
      }
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <p className={classes.decisionText}>My decision will be:</p>
      <form>
        {questionChoice1 ? (
          <span
            className={classes.flexContainer}
            onClick={handleClick}
            id={uniqueId1}
          >
            <label
              className={`${classes.radioButtonLabel} customRadioButtonLabel `}
              htmlFor={questionChoice1}
            >
              <input
                className={classes.radioButton}
                type="radio"
                name="questionRadio"
                id={questionChoice1}
                pattern={probability1}
                label={questionChoice1}
                value={questionChoice1}
                title={assessmentCategoryId}
                align={assessmentSubCategoryId}
                alt={state1}
                placeholder={reportState1}
                step={uniqueId1}
                size={questionChoiceMark1}
                onChange={handleChange}
                checked={selectedRadio === questionChoice1}
                disabled={props.loader}
              />
              <span class="flex-center"> {questionChoice1}</span>
            </label>
          </span>
        ) : null}
        <br />
        {questionChoice2 ? (
          <span
            className={classes.flexContainer}
            onClick={handleClick}
            id={uniqueId2}
          >
            <label
              className={`${classes.radioButtonLabel} customRadioButtonLabel `}
              htmlFor={questionChoice2}
            >
              <input
                className={classes.radioButton}
                type="radio"
                name="questionRadio"
                id={questionChoice2}
                pattern={probability2}
                label={questionChoice2}
                value={questionChoice2}
                title={assessmentCategoryId}
                align={assessmentSubCategoryId}
                alt={state2}
                placeholder={reportState2}
                step={uniqueId2}
                size={questionChoiceMark2}
                onChange={handleChange}
                checked={selectedRadio === questionChoice2}
              />
              <span class="flex-center"> {questionChoice2}</span>
            </label>
          </span>
        ) : null}
        <br />
        {questionChoice3 ? (
          <span
            className={classes.flexContainer}
            onClick={handleClick}
            id={uniqueId3}
          >
            <label
              className={`${classes.radioButtonLabel} customRadioButtonLabel `}
              htmlFor={questionChoice3}
            >
              <input
                className={classes.radioButton}
                type="radio"
                name="questionRadio"
                id={questionChoice3}
                pattern={probability3}
                label={questionChoice3}
                value={questionChoice3}
                title={assessmentCategoryId}
                align={assessmentSubCategoryId}
                alt={state3}
                placeholder={reportState3}
                step={uniqueId3}
                size={questionChoiceMark3}
                onChange={handleChange}
                checked={selectedRadio === questionChoice3}
              />
              <span class="flex-center"> {questionChoice3}</span>
            </label>
          </span>
        ) : null}
        <br />
        {questionChoice4 ? (
          <span
            className={classes.flexContainer}
            onClick={handleClick}
            id={uniqueId4}
          >
            <label
              className={`${classes.radioButtonLabel} customRadioButtonLabel `}
              htmlFor={questionChoice4}
            >
              <input
                className={classes.radioButton}
                type="radio"
                name="questionRadio"
                id={questionChoice4}
                pattern={probability4}
                label={questionChoice4}
                value={questionChoice4}
                title={assessmentCategoryId}
                align={assessmentSubCategoryId}
                alt={state4}
                placeholder={reportState4}
                step={uniqueId4}
                size={questionChoiceMark4}
                onChange={handleChange}
                checked={selectedRadio === questionChoice4}
              />
              <span class="flex-center"> {questionChoice4}</span>
            </label>
          </span>
        ) : null}
        <br />
        {questionChoice5 ? (
          <span
            className={classes.flexContainer}
            onClick={handleClick}
            id={uniqueId5}
          >
            <label
              className={`${classes.radioButtonLabel} customRadioButtonLabel `}
              htmlFor={questionChoice5}
            >
              <input
                className={classes.radioButton}
                type="radio"
                name="questionRadio"
                id={questionChoice5}
                pattern={probability5}
                label={questionChoice5}
                value={questionChoice5}
                title={assessmentCategoryId}
                align={assessmentSubCategoryId}
                alt={state5}
                placeholder={reportState5}
                step={uniqueId5}
                size={questionChoiceMark5}
                onChange={handleChange}
                checked={selectedRadio === questionChoice5}
              />
              <span class="flex-center"> {questionChoice5}</span>
            </label>
          </span>
        ) : null}
        <br />
      </form>
    </React.Fragment>
  );
};

export default SelectOptions;
