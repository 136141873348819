/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStopwatch, useTimer } from "react-timer-hook";
import Digit from "./Digit";

const useStyles = makeStyles({
  timer: {
    display: "inline",
    margin: "0 0 0 6px",
    font: "normal normal 400 25px/23px RobotoMedium",
    letterSpacing: "0px",
    color: "#FFFFFF",
    ["@media (max-width:480px)"]: {
      font: "normal normal 400 12px/13px RobotoMedium",
    },
  },
  timerContainer: {
    background: "#214697 0% 0% no-repeat padding-box",
    borderRadius: "20px",
    display: "inline-block",
    // alignItems: "center",
    padding: "6px 12px",
    color: "#fff",
    marginLeft: "1rem",
    width: "150px",
  },
  warningMessage: {
    color: "#CD2A28",
    marginBottom: 0,
    display: "inline",
    marginTop: "0px",
    marginLeft: "0.6rem",
    font: "normal normal bold 24px/30px PoppinsSemiBold",
    letterSpacing: 0,
    ["@media (max-width:480px)"]: {
      font: "normal normal bold 24px/30px PoppinsSemiBold",
    },
  },
  timeUpMessage: {
    color: "#71BC40",
    display: "block",
    font: "normal normal bold 16px/20px PoppinsSemiBold",
    letterSpacing: 0,
    textAlign: "left",
    margin: "14px 0 0px 14px",
  },
  warningMessageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
  },
  countDownTimerContainer: {
    display: "inline-flex",
  },
  stopwatchContainer: {
    display: "flex",
    backgroundColor: "#214697",
    color: "#fff",
    borderRadius: "20px",
    padding: "8px 20px",
    marginLeft: "1rem",
    font: "normal normal 400 25px/23px RobotoMedium",
  },
});
const CountDownTimer = (props) => {
  const [second, setSecond] = useState(props.seconds);
  const classes = useStyles();

  useEffect(() => {
    setSecond(0);
    if (props.time !== second) {
    }
    setInterval(() => {
      setSecond((prevSecond) => prevSecond + 1);
    }, 1000);
    return () => {
      console.log("cleaning");
      setSecond(0);
    };
  }, []);

  const { seconds, minutes, hours } = useStopwatch({ autoStart: true });

  return (
    <React.Fragment>
      <div className={classes.countDownTimerContainer}>
        <div className={classes.stopwatchContainer}>
          <Digit value={hours} addSeparator /> :
          <Digit value={minutes} addSeparator /> :
          <Digit value={seconds} />
        </div>
        {second > props.time ? (
          <span className={classes.warningMessageContainer}>
            <p className={classes.warningMessage}> No Response !</p>
          </span>
        ) : null}
      </div>
      {second > props.time ? (
        <p className={classes.timeUpMessage}>
          {" "}
          You can still answer. Try again.
        </p>
      ) : null}
    </React.Fragment>
  );
};

const MyTimer = ({ expiryTimestamp, OTPExpired }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      OTPExpired(1);
    },
  });

  return (
    <span>
      <span>0{minutes}</span>:<span>{seconds}</span>
    </span>
  );
};

export { CountDownTimer, MyTimer };
