/* eslint-disable no-useless-computed-key */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import questorTitle from "./../../assets/images/questor-title.png";
import reportScreen1Bg from "./../../assets/images/report/report-screen1-bg.svg";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundImage: `url(${reportScreen1Bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "relative",
    padding: "0 140px",
    alignItems: "end",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (max-width:1050px) and (min-height: 700px) and (max-height: 800px)"]:
      {
        alignItems: "center",
      },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      alignItems: "center",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1513px)"]: {
      alignItems: "center",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    marginBottom: "0.5rem",
    ["@media (min-width:1052px) and (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      marginTop: "16rem",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      marginTop: "16rem",
    },
    ["@media (min-width:1443px)"]: {
      marginTop: "16rem",
    },
  },
  sectionTitle: {
    color: "#BE2431",
    font: "normal normal 500 44px/120px PoppinsSemiBold",
    margin: "36px 0",
    ["@media (max-width:1382px)"]: {
      font: "normal normal 500 30px/48px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 500 36px/48px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 500 14px/20px PoppinsSemiBold",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 14px/20px PoppinsSemiBold",
    },
  },
  paragraph: {
    font: "normal normal 500 18px/24px PoppinsMedium",
    color: "#fff",
    padding: "0 14px 0 0px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 12px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 16px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 500 20px/26px PoppinsSemiBold",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 500 24px/36px PoppinsMedium",
    },
  },
  paraLinks: {
    font: "normal normal 500 18px/24px PoppinsMedium",
    color: "#fff",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 12px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 16px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
    ["@media (min-width:1443px) and (max-width:1600px)"]: {
      font: "normal normal 500 20px/26px PoppinsSemiBold",
    },
    ["@media (min-width:1601px)"]: {
      font: "normal normal 500 24px/36px PoppinsSemiBold",
    },
  },
  leftSideContainer: {
    marginBottom: "1rem",
    ["@media (min-width:1052px) and (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      marginTop: "16rem",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      marginTop: "16rem",
    },
    ["@media (min-width:1443px)"]: {
      marginTop: "15rem",
      textAlign: "center",
    },
  },

  copyRight: {
    font: "normal normal 400 12px/18px PoppinsMedium",
    color: "#fff",
    textAlign: "center",
    ["@media (max-width:1283px)"]: {
      font: "normal normal 400 10px/16px PoppinsMedium",
    },
  },
}));

export default function Screen11(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      component="main"
      className={`${classes.reportContentContainer}`}
    >
      <Grid item xs={12} sm={6} md={6} className={classes.leftSideContainer}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={8}>
            <img src={questorTitle} alt="questor-title" />
            <p className={classes.copyRight}>
              © 2024 ETQ Confidential. All Rights Reserved.
            </p>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        component={Paper}
        elevation={9}
        className={classes.rightSideContentContainer}
      >
        <div>
          <Grid container>
            <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <p className={classes.paragraph}>Congratulations!</p>
              <p className={classes.paragraph}>
                You have now begun your entrepreneurial journey with ETQ Global.
              </p>
              <p className={classes.paragraph}>
                Retake the learning in 3 months to track your progress or try
                our personalised training program. For any queries, reach out to
                us at:
              </p>
              <p className={classes.paragraph}>
                <a
                  className={classes.paraLinks}
                  href="mailto:support@etq-global.com"
                >
                  support@etq-global.com
                </a>{" "}
                or{" "}
                <a
                  className={classes.paraLinks}
                  href="https://www.etqglobal.com/"
                >
                  visit our website
                </a>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
