import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import closeCustomIcon from "../../assets/images/close-icon.png";
import { useHistory } from "react-router-dom";

import Audio from "./Audio";
import { useDispatch } from "react-redux";
import { Types } from "../store/Types";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modelTitle: {
    font: "normal normal bold 24px/30px PoppinsBold",
    letterSpacing: 0,
    color: "#fff",
  },
  subText: {
    font: "normal normal medium 16px/24px PoppinsRegular",
    color: "#fff",
  },
});

const useStyles = makeStyles({
  modalContainer: {
    textAlign: "center",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <img
          onClick={onClose}
          class="modal-close-button"
          alt="close-button"
          src={closeCustomIcon}
        />
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Modal(props) {
  const [open, setOpen] = React.useState(false);
  const [isPlayAudio, setIsPlayAudio] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    props.closePopup();
    dispatch({
      type: Types.CLOSE_MODAL_POPUP,
    });
  };

  useEffect(() => {
    setOpen(true);
    setIsPlayAudio(true);
    setTimeout(() => {
      history.push("/report");
    }, 6000);

    return () => {
      setIsPlayAudio(true);
      console.log("unmounting dialog component");
    };
  }, []);

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        class="custom-modal-container"
        maxWidth="xs"
      >
        <DialogTitle
          id="customized-dialog-title"
        class="custom-modal-container d-none"
        ></DialogTitle>
        <DialogContent dividers id="customized-dialog-content">
          <h3 class="customized-dialog-title">
            Thanks for taking the self-learning!
          </h3>
          <Typography className={classes.subText} component="p" variant="p">
            You will be redirected automatically to view your report. It will
            take a few seconds, please be patient.
          </Typography>
        </DialogContent>
      </Dialog>
      {/* {isPlayAudio ? <Audio></Audio> : null} */}
    </div>
  );
}
